import { useQuery } from 'react-query';
import { client } from 'utils/api-client';
import { useNotification } from 'components/NotificationBanner';

export default function useFetchDashboardContractTypeTimeSpent(params: any) {
  const notification = useNotification();

  return useQuery(
    ['dashboard-contract-type-avg-time', params],
    () =>
      client(`dashboard/contract-type/average-time-spent/`, { params }).then(
        (res) => res.data
      ),
    {
      onError: (error) => {
        notification.error({
          message: 'Error occurred',
          description: error.errors,
        });
      },
    }
  );
}
