import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Paper, Box, Typography, makeStyles, Grid } from '@material-ui/core';

const EmploymentStatus = ({ data }) => {
  const classes = useStyles();
  const indicatorColors = ['#93056F', '#FF8F00', '#4D8CF6', '#53627C', '#f00'];

  const options = {
    title: {
      text: undefined,
    },
    chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      donut: {
        size: '100%',
      },
    },
    colors: data?.map((_item, index) => indicatorColors[index]),
    labels: data?.map((item) => item.name),
    legend: {
      show: false,
    },
  };

  const series = data?.map((item) => item.value);

  const renderHeader = () => {
    return (
      <Box display="flex" justifyContent="space-between" className={classes.header}>
        <Typography color="textPrimary">Employment Status</Typography>
      </Box>
    );
  };

  const renderIndicators = () => {
    return data.map((indicator, index) => (
      <Grid container display="flex" justify="center" className={classes.indicator}>
        <Grid item xs={9}>
          <div className="ball" style={{ background: indicator.color }} />
          <Typography color="textPrimary" variant="caption" component="span">
            {indicator.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            className="value"
            variant="caption"
            style={{ color: indicatorColors[index] }}
          >
            {indicator.value}%
          </Typography>
        </Grid>
      </Grid>
    ));
  };

  return (
    <Paper>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <div>
          {renderHeader()}
          <Box display="flex" justifyContent="center" alignItems="center">
            <Chart options={options} series={series} type="donut" height={300} />
          </Box>
        </div>
        <Box className={classes.indicatorContainer}>{renderIndicators()}</Box>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles({
  header: {
    padding: `10px 15px`,
  },
  indicatorContainer: {
    marginBottom: 30,
  },
  indicator: {
    paddingLeft: 20,
    '& .ball': {
      borderRadius: '50%',
      width: 10,
      height: 10,
      marginRight: 5,
      display: 'inline-block',
    },

    '& .value': {
      fontWeight: 700,
    },
  },
});

EmploymentStatus.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

export default React.memo(EmploymentStatus);
