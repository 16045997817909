import * as React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import useVerifyUserToken from 'hooks/useVerifyUserToken';
import useCreatePassword from 'hooks/useCreatePassword';
import { useAuth } from 'context/auth-context';
import { VisibilityOff, Visibility } from '@material-ui/icons';

// components
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { InputLabel, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

// hooks
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  welcomeTextSecondary: {
    color: '#A6ABB8',
  },
  welcomeTextMain: {
    fontWeight: 'bold',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  inputField: {
    background: '#fff',
  },
}));

export const VerifyUser: React.FC<{}> = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const [hidePassword, setHidePassword] = React.useState(false);
  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {},
  });
  const { user } = useAuth();
  const [
    createPassword,
    { isLoading: creatingPassword, isError, error },
  ] = useCreatePassword();
  const [
    verifyToken,
    { isLoading: verifyingToken, error: tokenValidationError, isSuccess, data },
  ] = useVerifyUserToken();
  const token = new URLSearchParams(location.search).get('token');

  React.useEffect(() => {
    if (token) {
      verifyToken({ token });
    }
  }, [token, verifyToken]);

  if (user?.isAuthenticated) return <Redirect to="/admin/attendance" />;

  if (verifyingToken) {
    return (
      <Box width={1} mt={4} display="flex" justifyContent="center">
        Loading...
        <CircularProgress size={24} color="secondary" />
      </Box>
    );
  }

  if (tokenValidationError || !data?.valid) {
    return (
      <div className={classes.paper}>
        <Typography component="h1" variant="h6" className={classes.welcomeTextMain}>
          Link has expired or invalid
        </Typography>
      </div>
    );
  }

  if (isSuccess) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h6"
            className={classes.welcomeTextMain}
          >
            Profile Setup
          </Typography>
          <Typography
            component="h2"
            variant="subtitle1"
            className={classes.welcomeTextSecondary}
          >
            Provide the required fields to verify your account
          </Typography>
          {isError && (
            <Box m={2}>
              <Alert severity="error" variant="standard">
                {error?.errors === 'Invalid token specified'
                  ? 'Link has expired or invalid'
                  : error?.errors}
              </Alert>
            </Box>
          )}
          <form
            className={classes.form}
            onSubmit={handleSubmit((values) => createPassword({ ...values, token }))}
            autoComplete="off"
          >
            <Box mt={1}>
              <InputLabel>First Name</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                className={classes.inputField}
                name="firstname"
                id="firstname"
                placeholder="First Name"
                inputRef={register({
                  required: true,
                })}
                defaultValue={data?.firstname}
              />
            </Box>
            <Box mt={1}>
              <InputLabel>Last Name</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                className={classes.inputField}
                name="lastname"
                id="lastname"
                placeholder="Last Name"
                defaultValue={data?.lastname}
                inputRef={register({
                  required: true,
                })}
              />
            </Box>
            <Box mt={1}>
              <InputLabel>Email</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                className={classes.inputField}
                name="email"
                id="email"
                placeholder="Email"
                defaultValue={data?.email}
                inputRef={register({
                  required: true,
                })}
                disabled
              />
            </Box>
            <Box mt={1}>
              <InputLabel>Password</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                className={classes.inputField}
                name="password"
                type="password"
                id="password"
                placeholder="Password"
                InputProps={{
                  autoComplete: 'off',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ padding: 5, fontSize: 20 }}
                        onClick={() => setHidePassword(!hidePassword)}
                      >
                        {hidePassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputRef={register({
                  required: true,
                  minLength: { value: 8, message: 'Too short' },
                })}
              />
              <span>Password must be minimum of 8 characters</span>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              {creatingPassword ? <CircularProgress size={24} /> : 'Complete Setup'}
            </Button>
          </form>
        </div>
      </Container>
    );
  }

  return null;
};
