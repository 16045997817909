import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export default function useFetchEmployeeData(id) {
  return useQuery(
    ['employee-data', id],
    () => client(`employees/${id}/`).then((res) => res),
    { enabled: id }
  );
}
