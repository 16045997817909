/* eslint-disable react/prop-types */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import {
  DateRangePicker,
  DateRangeDelimiter,
  DatePicker,
  LocalizationProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { Controller } from 'react-hook-form';

const BasicDateRangePicker = React.forwardRef(
  (
    {
      range,
      placeholder,
      control,
      name,
      handleDateChange,
      value: date,
      views,
      format,
    },
    ref
  ) => {
    return (
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        {range ? (
          <>
            {control ? (
              <Controller
                name={name}
                control={control}
                render={({ onChange, ...rest }) => (
                  <DateRangePicker
                    onChange={(value) => {
                      onChange(value);
                      handleDateChange(value);
                    }}
                    views={views}
                    format={format}
                    {...rest}
                    ref={ref}
                    value={rest.value}
                    renderInput={(startProps, endProps) => (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          border: '1px solid rgb(207 207 207)',
                          borderRadius: '4px',
                          padding: '4px 0',
                        }}
                      >
                        <TextField
                          {...startProps}
                          label=""
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                            ...startProps.inputProps,
                          }}
                          style={{ paddingLeft: '10px' }}
                          // size="small"
                        />
                        <Box>-</Box>
                        <TextField
                          {...endProps}
                          label=""
                          style={{ paddingLeft: '10px' }}
                          size="small"
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                            ...endProps.inputProps,
                          }}
                        />
                      </div>
                    )}
                  />
                )}
              />
            ) : (
              <DateRangePicker
                onChange={(value) => handleDateChange(value)}
                views={views}
                format={format}
                value={date}
                name={name}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} label="Start Date" size="small" />
                    <DateRangeDelimiter> to </DateRangeDelimiter>
                    <TextField {...endProps} label="End Date" size="small" />
                  </>
                )}
              />
            )}
          </>
        ) : (
          <>
            {control ? (
              <Controller
                name={name}
                control={control}
                render={({ onChange, onBlur, ...rest }) => (
                  <DatePicker
                    color="primary"
                    helperText="Current Date"
                    onError={() => null}
                    onBlur={onBlur}
                    onChange={(value) => {
                      onChange(value);
                      handleDateChange(value);
                    }}
                    {...rest}
                    selected={rest.value}
                    format={format}
                    views={views}
                    disableMaskedInput
                    renderInput={(props) => (
                      <TextField
                        {...{
                          ...props,
                          // eslint-disable-next-line react/prop-types
                          inputProps: {
                            ...props.inputProps,
                            placeholder,
                          },
                        }}
                        error={false}
                        variant="outlined"
                        style={{ width: '100%' }}
                        placeholder="PlaceHolders"
                        size="small"
                      />
                    )}
                  />
                )}
              />
            ) : (
              <DatePicker
                color="primary"
                helperText="Current Date"
                onError={() => null}
                onChange={(value) => {
                  handleDateChange(value);
                }}
                format={format}
                views={views}
                renderInput={(props) => (
                  <TextField
                    {...{
                      ...props,
                      // eslint-disable-next-line react/prop-types
                      inputProps: {
                        ...props.inputProps,
                        placeholder,
                      },
                    }}
                    error={false}
                    variant="outlined"
                    style={{ width: '100%' }}
                    placeholder="PlaceHolders"
                  />
                )}
              />
            )}
          </>
        )}
      </LocalizationProvider>
    );
  }
);

BasicDateRangePicker.defaultProps = {
  range: false,
  placeholder: null,
  value: null,
  control: null,
  name: '',
  handleDateChange: () => {},
  views: ['year', 'month', 'date'],
  format: 'DD-MM-YYYY',
};

export default BasicDateRangePicker;
