/* eslint-disable camelcase */
import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

const updateCache = ({ educationId, cache, queryKey }) => {
  const queryData = cache.getQueryData(queryKey);
  const updatedData = {
    ...queryData,
    education: queryData.education.filter((item) => item.id !== educationId),
  };
  cache.setQueryData(queryKey, updatedData);
};

export default function useDeleteEducationData(staffId: string) {
  const cache = useQueryCache();
  return useMutation((educationId: string) =>
    client(`employees/education-history/${educationId}/`, {
      method: 'DELETE',
    }).then(() => {
      updateCache({ cache, educationId, queryKey: ['employee-data', staffId] });
    })
  );
}
