/* eslint-disable camelcase */
import React from 'react';
import { useParams } from 'react-router-dom';
// components
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import DatePicker from 'components/common/DatePicker';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/common/Button';
import Select from 'components/common/Select';

import { useAddRecordOfService } from 'hooks/useAddRecordOfService';
import { useUpdateRecordOfService } from 'hooks/useUpdateRecordOfService';
import { format } from 'date-fns';

const salaryScales = [
  { id: '1', name: '150,000 - 300,000' },
  { id: '2', name: '350,000 - 600,000' },
  {
    id: '3',
    name: '750,000 - 100,000,000',
  },
];

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    fontStyle: 'normal',
  },
  personAvatarContainer: {
    height: 145,
    width: 145,
    background: '#FFFFFF',
    display: 'grid',
    placeItems: 'center',
    marginBottom: '20px',
  },
  formGroup: {
    margin: '15px 0',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#0A0A0A',
    marginBottom: '5px',
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    paddingTop: '10px',
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '22px',
  },
}));

type routeParams = {
  id: string;
};

type RecordHistoryForm = {
  designation: string;
  date_of_appointment: string | Date | null;
  salary_scale: string;
  increment_scale: string;
  increment_date: string | Date | null;
  annual_leave_period: [string | Date | null, string | Date | null];
  casual_leave_period: [string | Date | null, string | Date | null];
  special_leave_period: [string | Date | null, string | Date | null];
  sick_leave_period: [string | Date | null, string | Date | null];
};

type RecordHistory = {
  id: string;
  designation: string;
  date_of_appointment: string | Date | null;
  salary_scale: string;
  increment_scale: string;
  increment_date: string | Date | null;
  annual_leave_start_date: string | Date;
  annual_leave_end_date: string | Date;
  casual_leave_start_date: string | Date;
  casual_leave_end_date: string | Date;
  special_leave_start_date: string | Date;
  special_leave_end_date: string | Date;
  sick_leave_start_date: string | Date;
  sick_leave_end_date: string | Date;
};

interface FormProps {
  open: boolean;
  closeModal: Function;
  recordsOfService: RecordHistory;
}

const AddWorkHistoryModal: React.FC<FormProps> = (props: FormProps) => {
  const classes = useStyles();
  const { id }: routeParams = useParams();
  const { closeModal, open, recordsOfService } = props;

  const getFormValues = () => {
    return {
      ...recordsOfService,
      date_of_appointment: recordsOfService?.date_of_appointment
        ? recordsOfService.date_of_appointment
        : null,
      increment_date: recordsOfService?.increment_date
        ? recordsOfService?.increment_date
        : null,
      annual_leave_period: [
        recordsOfService?.annual_leave_start_date
          ? recordsOfService?.annual_leave_start_date
          : null,
        recordsOfService?.annual_leave_end_date
          ? recordsOfService?.annual_leave_end_date
          : null,
      ],
      casual_leave_period: [
        recordsOfService?.casual_leave_start_date
          ? recordsOfService?.casual_leave_start_date
          : null,
        recordsOfService?.casual_leave_end_date
          ? recordsOfService?.casual_leave_end_date
          : null,
      ],
      special_leave_period: [
        recordsOfService?.special_leave_start_date
          ? recordsOfService?.special_leave_start_date
          : null,
        recordsOfService?.special_leave_end_date
          ? recordsOfService?.special_leave_end_date
          : null,
      ],
      sick_leave_period: [
        recordsOfService?.sick_leave_start_date
          ? recordsOfService?.sick_leave_start_date
          : null,
        recordsOfService?.sick_leave_end_date
          ? recordsOfService?.sick_leave_end_date
          : null,
      ],
      salary_scale:
        salaryScales.find((scale) => scale.name === recordsOfService?.salary_scale)
          ?.id || '',
    };
  };

  const { register, handleSubmit, control, reset, setError } = useForm<
    RecordHistoryForm
  >({
    defaultValues: {
      ...getFormValues(),
    },
  });

  const [add, { isLoading: posting }] = useAddRecordOfService(id);
  const [update, { isLoading: updating }] = useUpdateRecordOfService(
    id,
    recordsOfService?.id
  );

  const addRecordOfService = (addAnother: boolean) => {
    handleSubmit((values: RecordHistoryForm) => {
      const fn = recordsOfService?.id ? update : add;
      const {
        annual_leave_period,
        casual_leave_period,
        special_leave_period,
        sick_leave_period,
        date_of_appointment,
        increment_date,
        salary_scale,
        ...rest
      } = values;
      if (!date_of_appointment) {
        setError('date_of_appointment', {
          type: 'manual',
          message: 'Date is required',
        });
        return;
      }
      fn(
        {
          annual_leave_start_date: format(
            new Date(annual_leave_period[0] || ''),
            'yyyy-MM-dd'
          ),
          annual_leave_end_date: format(
            new Date(annual_leave_period[1] || ''),
            'yyyy-MM-dd'
          ),
          casual_leave_start_date: format(
            new Date(casual_leave_period[0] || ''),
            'yyyy-MM-dd'
          ),
          casual_leave_end_date: format(
            new Date(casual_leave_period[1] || ''),
            'yyyy-MM-dd'
          ),
          special_leave_start_date: format(
            new Date(special_leave_period[0] || ''),
            'yyyy-MM-dd'
          ),
          special_leave_end_date: format(
            new Date(special_leave_period[1] || ''),
            'yyyy-MM-dd'
          ),
          sick_leave_start_date: format(
            new Date(sick_leave_period[0] || ''),
            'yyyy-MM-dd'
          ),
          sick_leave_end_date: format(
            new Date(sick_leave_period[1] || ''),
            'yyyy-MM-dd'
          ),
          date_of_appointment: format(new Date(date_of_appointment), 'yyyy-MM-dd'),
          increment_date: format(new Date(increment_date || ''), 'yyyy-MM-dd'),
          salary_scale: salaryScales.find((scale) => scale.id === salary_scale)
            ?.name,
          ...rest,
        },
        {
          onSuccess: () => {
            !addAnother && closeModal();
            reset({
              designation: '',
              salary_scale: '',
              date_of_appointment: null,
              annual_leave_period: [null, null],
              casual_leave_period: [null, null],
              special_leave_period: [null, null],
              sick_leave_period: [null, null],
              increment_date: null,
            });
          },
        }
      );
    })();
  };

  return (
    <Dialog open={open} disableEnforceFocus>
      <Box id="form-dialog-title" className={classes.dialogTitle}>
        <Typography variant="h5">Add Record of Service</Typography>
        <Button onClick={closeModal}>X</Button>
      </Box>
      <Divider />
      <DialogContent>
        <form className={classes.root}>
          <Box className={classes.formGroup}>
            <InputLabel className={classes.inputLabel}>Designation</InputLabel>
            <TextField
              name="designation"
              variant="outlined"
              style={{ width: '100%' }}
              inputRef={register({ required: true })}
              placeholder="Assistant Finance Manager"
              size="small"
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item md={6} sm={6}>
              <Box className={classes.formGroup}>
                <InputLabel className={classes.inputLabel}>
                  Scale of Salary
                </InputLabel>
                <Select
                  name="salary_scale"
                  control={control}
                  options={salaryScales}
                />
              </Box>
            </Grid>
            <Grid item md={6} sm={6}>
              <Box className={classes.formGroup}>
                <InputLabel className={classes.inputLabel}>
                  Date of Salary Increment
                </InputLabel>
                <DatePicker
                  name="increment_date"
                  control={control}
                  format="yyyy-MM-dd"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} sm={6}>
              <Box className={classes.formGroup}>
                <InputLabel className={classes.inputLabel}>
                  Increment Rate
                </InputLabel>
                <TextField
                  name="increment_scale"
                  variant="outlined"
                  inputRef={register({ required: true })}
                  placeholder="0.05%"
                  fullWidth
                  size="small"
                />
              </Box>
            </Grid>
            <Grid item md={6} sm={6}>
              <Box className={classes.formGroup}>
                <InputLabel className={classes.inputLabel}>
                  Date of Appointment
                </InputLabel>
                <DatePicker
                  name="date_of_appointment"
                  control={control}
                  ref={register({ required: true })}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} sm={6}>
              <Box className={classes.formGroup}>
                <InputLabel className={classes.inputLabel}>Casual Leave</InputLabel>
                <Box display="flex">
                  <DatePicker name="casual_leave_period" control={control} range />
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} sm={6}>
              <Box className={classes.formGroup}>
                <InputLabel className={classes.inputLabel}>Sick Leave</InputLabel>
                <DatePicker name="sick_leave_period" control={control} range />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} sm={6}>
              <Box className={classes.formGroup}>
                <InputLabel className={classes.inputLabel}>Annual Leave</InputLabel>
                <DatePicker name="annual_leave_period" control={control} range />
              </Box>
            </Grid>
            <Grid item md={6} sm={6}>
              <Box className={classes.formGroup}>
                <InputLabel className={classes.inputLabel}>Special Leave</InputLabel>
                <DatePicker name="special_leave_period" control={control} range />
              </Box>
            </Grid>
          </Grid>
        </form>
        <Box display="flex" justifyContent="flex-end" mt={4} mb={4}>
          <Button
            onClick={() => addRecordOfService(true)}
            variant="outlined"
            style={{ marginRight: '10px' }}
            isLoading={posting || updating}
            type="button"
          >
            Save and add another
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            isLoading={posting || updating}
            onClick={() => addRecordOfService(false)}
          >
            Save
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddWorkHistoryModal;
