/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import * as React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from 'components/Avatar';
import Link from '@material-ui/core/Link';

import useFetchEmployees from 'hooks/useFetchEmployees';
import { titleCase } from 'utils/formatText';
import { useHistory } from 'react-router-dom';

// components
import Table from 'components/Table';

const columns = [
  {
    header: 'Staff ID',
    key: 'staffId',
  },
  {
    header: 'Institution',
    key: 'ministry_data',
  },

  {
    header: 'Level',
    key: 'current_grade_level',
  },
  {
    header: 'Designation',
    key: 'currentRankTitle',
  },

  {
    header: 'Year of service',
    key: 'years_in_service',
  },
];

interface SearchValues {
  search: string;
  institution: string;
}

type Employee = {
  id: string;
  institution: string;
  firstname: string;
  surname: string;
  staff_id: string;
  current_rank_title: string;
  years_in_service: number;
  current_grade_level: number;
};

type EmployeeListProps = {
  searchValues: SearchValues;
};

const renderIdColumn = (name: string, staffId: string) => (
  <Box display="flex">
    <Avatar name={name}>AA</Avatar>
    <Box ml={2}>
      {staffId}
      <Typography>{name}</Typography>
    </Box>
  </Box>
);

export default function EmployeeList({ searchValues }: EmployeeListProps) {
  const [page, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const { data, isLoading } = useFetchEmployees({
    search: searchValues.search,
    ministry: searchValues?.institution?.trim(),
    page,
    page_size: pageSize,
  });

  const history = useHistory();

  function createData({
    name,
    id,
    staffId,
    ministry_data,
    currentRankTitle,
    years_in_service,
    current_grade_level,
  }) {
    return {
      id,
      staffId: renderIdColumn(name, staffId),
      ministry_data,
      currentRankTitle,
      years_in_service,
      current_grade_level,
    };
  }

  const list = data?.results?.map(
    ({
      id,
      ministry_data,
      firstname,
      surname,
      staff_id,
      current_rank_title,
      years_in_service,
      current_grade_level,
    }: Employee) =>
      createData({
        id,
        staffId: staff_id,
        name: titleCase(`${surname} ${firstname}`),
        current_grade_level,
        ministry_data: ministry_data?.name,
        currentRankTitle: titleCase(current_rank_title),
        years_in_service: `${years_in_service} Years`,
      })
  );

  const createCsvData = data?.results?.map(
    ({
      staff_id,
      firstname,
      surname,
      ministry_data,
      current_grade_level,
      current_rank_title,
      years_in_service,
    }) => ({
      staff_id,
      firstname,
      surname,
      current_grade_level,
      currentRankTitle: titleCase(current_rank_title),
      years_in_service: `${years_in_service} Years`,
      ministry_data: ministry_data?.name,
    })
  );

  const clist = createCsvData?.map((cdata) => Object.values(cdata));
  const csvData = [
    [
      'Staff ID',
      'First Name',
      'Last Name',
      'Levels',
      'Designation',
      'Years in Service',
      'Institution',
    ],
    ...(clist ?? []),
  ];

  return (
    <Table
      columns={columns}
      onRowItemClick={({ id }) => history.push(`/admin/staffs/${id}`)}
      data={list}
      isLoading={isLoading}
      pagination={{
        total: data?.total,
        totalPages: data?.total_pages,
        currentPage: page,
        setCurrentPage,
        pageSize,
        setPageSize,
      }}
      csvData={csvData}
      csvTitle="Staffs"
    />
  );
}
