import React, { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { DownloadIcon } from 'assets';
import Button from 'components/common/Button';
import { downloadCSV } from 'utils/csvDownload';

interface Props {
  isLoading?: boolean;
  currentListShowing?: number;
  total?: number;
  title?: string;
}

export default function TableStats(props: Props): ReactElement {
  const { isLoading, currentListShowing, total, title, csvData, csvTitle } = props;
  return (
    <Box mt={2} mb={2} display="flex" justifyContent="space-between" alignItems="center">
      {isLoading ? (
        <Typography color="secondary" m={2}>
          Loading...
        </Typography>
      ) : (
          <Typography color="secondary" m={2}>
            Showing {currentListShowing || 0} of {total || 0} {title || 'Staffs'}
          </Typography>
        )}
      <Button
        variant="text"
        color="secondary"
        onClick={() => downloadCSV(csvTitle, csvData)}
      >
        <Box mr={1.5} ml={4} display="flex">
          <DownloadIcon />
        </Box>
        Download
      </Button>
    </Box>
  );
}
