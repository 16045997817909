import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

export default function useInviteUser(params) {
  const cache = useQueryCache();
  return useMutation(
    (values) =>
      client('auth/users/invite-user/', { data: values }).then((res) => res),
    {
      onSuccess: () => {
        cache.invalidateQueries(['users', params]);
      },
    }
  );
}
