/* eslint-disable react/prop-types */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from 'components/common/Button';
import { ButtonPreviewIcon, FilePreviewIcon, SmallDeleteIcon } from 'assets';

const useStyles = makeStyles(() => ({
  documentPreview: {
    width: '279px',
    height: '65px',
    background: '#FFFFFF',
    border: '1px solid #C9D4DB',
    borderRadius: '3px',
    padding: '1px 0px',
    marginRight: '15px',
    marginTop: '10px',
  },
  documentPreviewTitle: {
    width: '180px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface DocumentProps {
  uploadingFile?: boolean;
  handleAction: Function;
  file: any;
  action: 'delete' | 'download';
}

const DocumentPreview: React.FC<DocumentProps> = (props) => {
  const { uploadingFile, file, handleAction, action } = props;
  const classes = useStyles();

  console.log(file);

  return (
    <Grid
      container
      className={classes.documentPreview}
      alignItems="center"
      spacing={1}
    >
      <Grid item direction="column" alignItems="center" md={3}>
        <FilePreviewIcon />
      </Grid>
      <Grid item md={9}>
        <Box className={classes.documentPreviewTitle}>{file.name}</Box>
        {file.id ? (
          <Box display="flex" alignItems="center">
            <Button>
              <a href={file.url} target="_blank" style={{ textDecoration: 'none' }}>
                Preview
                <ButtonPreviewIcon />
              </a>
            </Button>
            <Button
              color="secondary"
              style={{ color: '#BF0711' }}
              onClick={handleAction}
            >
              {action === 'delete' ? (
                <span>
                  Delete
                  <SmallDeleteIcon />
                </span>
              ) : (
                <a
                  href={file.file}
                  download
                  style={{ textDecoration: 'none', color: '#BF0711' }}
                >
                  Download
                </a>
              )}
            </Button>
          </Box>
        ) : (
          <>
            {uploadingFile && (
              <Typography>uploading...</Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DocumentPreview;
