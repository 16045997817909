import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AverageTimeIcon, CalendarActivity } from 'assets';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFFFFF',
    borderRadius: '3px',
    padding: '20px',
    width: '360px',
    height: '187px',
    marginBottom: '10px',
    position: 'relative'
  },
  icon: {
    position: "absolute",
    bottom: 0,
    right: 0
  }
}));

interface Props {
  title: string;
  icon: 'clock' | 'calendar';
  subtitle: string;
  bottomLeftElement: React.ReactNode
}

const icons = {
  clock: AverageTimeIcon,
  calendar: CalendarActivity,
};

const StaffTimeActivity: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { title, icon, bottomLeftElement, subtitle } = props;
  const Icon = icons[icon];
  return (
    <Box className={classes.root}>
      <Grid container style={{ width: '100%', height: '100%' }}>
        <Grid item md={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            height={1}
          >
            <Box>
              <Typography>{title}</Typography>
              <Typography variant="h4" style={{ fontWeight: "bold" }}>{subtitle}</Typography>
            </Box>
            {/* <Box>
              {bottomLeftElement}
            </Box> */}
          </Box>
        </Grid>
      </Grid>
      <Icon className={classes.icon} />
    </Box>
  );
};

export default StaffTimeActivity;
