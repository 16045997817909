/* eslint-disable camelcase */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// components
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'components/common/Select';
import TextField from '@material-ui/core/TextField';
import profileAvatar from 'assets/img/profile-avatar.jpg';
import { useForm } from 'react-hook-form';
import { Button, Typography } from '@material-ui/core';
import DatePicker from 'components/common/DatePicker';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

// hooks
import useUpdateEmployeeData from 'hooks/useUpdateEmployeeData';
import { useCountryList } from 'hooks/useCountries';
import { useCountryStates } from 'hooks/useCountryStates';
import { useLocalGovernmentArea } from 'hooks/useLocalGovernmentArea';
import FileUpload from 'components/common/FileUpload';
import { titleCase } from 'utils/formatText';
import { maritalStatus } from 'utils/constants';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    fontStyle: 'normal',
  },
  personAvatarContainer: {
    height: 145,
    width: 145,
    background: '#FFFFFF',
    display: 'grid',
    placeItems: 'center',
    marginBottom: '20px',
  },
  formGroup: {
    margin: '15px 0',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#4F4F4F',
  },
  textfield: {
    '& .MuiInputBase-input:focus': {
      outline: '2px solid rgba(201, 212, 219, 0.8)',
    },
  },
}));

type routeParams = {
  id: string;
};

interface FormProps {
  data: any;
}

export default function PersonalDataForm(props: FormProps) {
  const { data } = props;
  console.log(data);
  
  const classes = useStyles();
  const { id }: routeParams = useParams();
  const [updateData, { isLoading }] = useUpdateEmployeeData(id);

  const history = useHistory();

  const { register, handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      ...data,
      passport: data?.passport || profileAvatar,
      lga_of_origin: titleCase(data?.lga_of_origin),
      state_of_origin: titleCase(data?.state_of_origin)
    },
  });

  const { countries } = useCountryList();
  
  const updatePersonalDetails = (values: any) => {
    const country = countries.find(
      ({ id: isCode }) => isCode === values.nationality
    );
    const payload = {
      ...values,
      nationality: country?.name,
    };
    if (typeof values.passport === 'string') delete payload.passport;
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    updateData(formData);
  };

  React.useEffect(() => {
    const country = countries.find(({ name }) => name === titleCase(data.nationality));
    setValue('nationality', country?.id);
  }, [countries, data.nationality, setValue]);

  const { nationality, state_of_origin, passport } = watch([
    'nationality',
    'state_of_origin',
    'passport',
  ]);

  const preview =
    passport instanceof File ? URL.createObjectURL(passport) : passport;
  const { states } = useCountryStates(nationality);
  
  const { data: lgaData } = useLocalGovernmentArea(state_of_origin);

  return (
    <form className={classes.root} onSubmit={handleSubmit(updatePersonalDetails)}>
      <Grid container spacing={3}>
        <Grid item md={2} sm={6}>
          <img
            src={preview}
            alt="profile"
            className={classes.personAvatarContainer}
          />
          <FileUpload
            text="Change Picture"
            name="passport"
            control={control}
            icon={null}
            buttonUpload
          />
        </Grid>
        <Grid item md={4} sm={6}>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Staff ID:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <TextField
                className={classes.textfield}
                name="staff_id"
                variant="outlined"
                style={{ width: '100%' }}
                inputRef={register({ required: true })}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Title:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <Select
                name="title"
                control={control}
                options={[
                  { id: 'Mr', name: 'Mr.' },
                  { id: 'Mrs', name: 'Mrs.' },
                  { id: 'Miss', name: 'Miss.' },
                  { id: 'Doctor', name: 'Doctor' },
                  { id: 'Prof.', name: 'Prof.' },
                  { id: 'Cheif', name: 'Cheif' },
                  { id: 'Engr.', name: 'Engr.' },
                  { id: 'Mallam', name: 'Mallam' },
                  { id: 'Others', name: 'Others' },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>First Name:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <TextField
                className={classes.textfield}
                name="firstname"
                variant="outlined"
                style={{ width: '100%' }}
                inputRef={register({ required: true })}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Last Name:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <TextField
                className={classes.textfield}
                name="surname"
                variant="outlined"
                style={{ width: '100%' }}
                inputRef={register({ required: true })}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Middle Name:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <TextField
                className={classes.textfield}
                name="middlename"
                variant="outlined"
                style={{ width: '100%' }}
                inputRef={register}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Gender:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <Select
                name="gender"
                control={control}
                options={[
                  { id: 'MALE', name: 'Male' },
                  { id: 'FEMALE', name: 'Female' },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Marital Status:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <Select
                name="marital_status"
                control={control}
                options={maritalStatus}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} sm={6}>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Maiden Name:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <TextField
                className={classes.textfield}
                name="maiden_name"
                variant="outlined"
                style={{ width: '100%' }}
                inputRef={register}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Date of Birth:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <DatePicker name="date_of_birth" control={control} />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Religion:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <Select
                name="religion"
                control={control}
                options={[
                  { id: 'Christianity', name: 'Christianity' },
                  { id: 'Islam', name: 'Islam' },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Nationality:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <Select name="nationality" control={control} options={countries} />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>State of Origin:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <Select name="state_of_origin" control={control} options={states} />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>LGA of Origin:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <Select name="lga_of_origin" control={control} options={lgaData} />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>BVN No:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <TextField
                className={classes.textfield}
                name="bvn_number"
                variant="outlined"
                style={{ width: '100%' }}
                inputRef={register({ required: true })}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ marginTop: '100px' }}>
        <Typography style={{ marginBottom: '10px' }}>Contact Details</Typography>
        <Divider />
        <Grid container spacing={3}>
          <Grid item md={2} sm={1} />
          <Grid item md={8} sm={8}>
            <Grid container className={classes.formGroup}>
              <Grid xs={3} item className={classes.inputLabel}>
                <InputLabel>Phone Number:</InputLabel>
              </Grid>
              <Grid xs={8} item>
                <TextField
                  className={classes.textfield}
                  name="phone_number"
                  variant="outlined"
                  style={{ width: '100%' }}
                  inputRef={register({ required: true })}
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={3} item className={classes.inputLabel}>
                <InputLabel>Email Address:</InputLabel>
              </Grid>
              <Grid xs={8} item>
                <TextField
                  className={classes.textfield}
                  name="email_address"
                  variant="outlined"
                  style={{ width: '100%' }}
                  inputRef={register}
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={3} item className={classes.inputLabel}>
                <InputLabel>Permanent Address:</InputLabel>
              </Grid>
              <Grid xs={8} item>
                <TextField
                  className={classes.textfield}
                  name="permanent_address"
                  variant="outlined"
                  style={{ width: '100%' }}
                  inputRef={register}
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '100px' }}>
        <Typography style={{ marginBottom: '10px' }}>
          Relatives Information
        </Typography>
        <Divider />
        <Grid container spacing={3}>
          <Grid item md={2} sm={1} />
          <Grid item md={8} sm={8}>
            <Grid container className={classes.formGroup}>
              <Grid xs={3} item className={classes.inputLabel}>
                <InputLabel>Next of Kin:</InputLabel>
              </Grid>
              <Grid xs={8} item>
                <TextField
                  className={classes.textfield}
                  name="next_of_kin"
                  variant="outlined"
                  style={{ width: '100%' }}
                  inputRef={register}
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={3} item className={classes.inputLabel}>
                <InputLabel>Relationship:</InputLabel>
              </Grid>
              <Grid xs={8} item>
                <TextField
                  className={classes.textfield}
                  name="next_of_kin_relationship"
                  variant="outlined"
                  style={{ width: '100%' }}
                  inputRef={register}
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={3} item className={classes.inputLabel}>
                <InputLabel>Address:</InputLabel>
              </Grid>
              <Grid xs={8} item>
                <TextField
                  className={classes.textfield}
                  name="next_of_kin_address"
                  variant="outlined"
                  style={{ width: '100%' }}
                  inputRef={register}
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={3} item className={classes.inputLabel}>
                <InputLabel>Phone Number:</InputLabel>
              </Grid>
              <Grid xs={8} item>
                <TextField
                  className={classes.textfield}
                  name="next_of_kin_phone"
                  variant="outlined"
                  style={{ width: '100%' }}
                  inputRef={register}
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={3} item className={classes.inputLabel}>
                <InputLabel>Email Address:</InputLabel>
              </Grid>
              <Grid xs={8} item>
                <TextField
                  className={classes.textfield}
                  name="next_of_kin_email"
                  variant="outlined"
                  style={{ width: '100%' }}
                  inputRef={register}
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Box display="flex" justifyContent="flex-end" m={4}>
        <Button
          onClick={() => history.push(`/admin/staffs/${id}`)}
          variant="outlined"
          style={{ marginRight: '10px' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? <CircularProgress size={24} /> : 'Save Changes'}
        </Button>
      </Box>
    </form>
  );
}
