import { useQuery } from 'react-query';
import { client } from 'utils/api-client';
import { useNotification } from 'components/NotificationBanner';

export default function useFetchDashboardDailyAttendance(params: any) {
  const notification = useNotification();

  return useQuery(
    ['dashboard-daily-attendance', params],
    () => client(`dashboard/daily-attendance/`, { params }).then((res) => res.data)
    , {
      onError: (error) => {
        notification.error({
          message: "Error occurred",
          description: error.errors
        })
      }
    });
}
