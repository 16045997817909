/* eslint-disable camelcase */
import React from 'react';
import { useParams } from 'react-router-dom';
// components
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import DatePicker from 'components/common/DatePicker';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/common/Button';
import usePostEducationData from 'hooks/usePostEducationData';
import useUpdateEducationHistory from 'hooks/useUpdateEducationHistory';
import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    fontStyle: 'normal',
  },
  personAvatarContainer: {
    height: 145,
    width: 145,
    background: '#FFFFFF',
    display: 'grid',
    placeItems: 'center',
    marginBottom: '20px',
  },
  formGroup: {
    margin: '15px 0',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#0A0A0A',
    marginBottom: '5px',
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    paddingTop: '10px',
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '22px',
  },
}));

type routeParams = {
  id: string;
};

type Education = {
  id: string;
  name: string;
  qualification: string;
  course: string;
  year_of_resumption: string | Date;
  year_of_graduation: string | Date;
};

interface FormProps {
  open: boolean;
  closeModal: Function;
  education: Education;
}

const EducationDataForm: React.FC<FormProps> = (props: FormProps) => {
  const classes = useStyles();
  const { id }: routeParams = useParams();
  const { closeModal, open, education } = props;

  const { register, handleSubmit, control, errors, reset } = useForm<Education>({
    defaultValues: {
      ...education,
    },
  });

  const [postEducation, { isLoading: postingEducation }] = usePostEducationData(id);
  const [
    updateEducation,
    { isLoading: updatingEducation },
  ] = useUpdateEducationHistory(id, education.id);

  const saveEducation = (addAnother: any) => {
    handleSubmit((values) => {
      const { year_of_graduation, year_of_resumption, ...rest } = values;
      const fn = education.id ? updateEducation : postEducation;
      fn(
        {
          ...rest,
          year_of_graduation: format(new Date(year_of_graduation), 'yyyy'),
          // year_of_resumption: format(new Date(year_of_resumption), 'yyyy'),
        },
        {
          onSuccess: () => {
            if (addAnother) {
              reset();
              return;
            }
            closeModal(false);
          },
        }
      );
    })();
  };

  const isLoading = updatingEducation || postingEducation;

  return (
    <Dialog open={open}>
      <Box id="form-dialog-title" className={classes.dialogTitle}>
        <Typography variant="h5">Add education</Typography>
        <Button onClick={closeModal}>X</Button>
      </Box>
      <Divider />
      <DialogContent>
        <form className={classes.root}>
          <Box className={classes.formGroup}>
            <InputLabel className={classes.inputLabel}>Institution</InputLabel>
            <TextField
              name="name"
              error={errors?.name?.message}
              variant="outlined"
              style={{ width: '100%' }}
              inputRef={register({ required: 'Field is required' })}
              placeholder="Ex: Oyo State University"
              size="small"
              fullWidth
            />
          </Box>
          <Box className={classes.formGroup}>
            <InputLabel className={classes.inputLabel}>Degree</InputLabel>
            <TextField
              name="qualification"
              error={errors?.qualification?.message}
              variant="outlined"
              placeholder="Ex: Bachelor’s"
              style={{ width: '100%' }}
              inputRef={register({ required: 'Field is required' })}
              size="small"
            />
          </Box>
          <Box className={classes.formGroup}>
            <InputLabel className={classes.inputLabel}>Field of Study</InputLabel>
            <TextField
              name="course"
              error={errors?.course?.message}
              variant="outlined"
              style={{ width: '100%' }}
              placeholder="Ex: Business"
              inputRef={register({ required: 'Field is required' })}
              size="small"
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item md={6} sm={6}>
              <Box className={classes.formGroup}>
                <InputLabel className={classes.inputLabel}>Start Year</InputLabel>
                <DatePicker
                  name="year_of_resumption"
                  control={control}
                  views={['year']}
                  format="YYYY"
                />
              </Box>
            </Grid>
            <Grid item md={6} sm={6}>
              <Box className={classes.formGroup}>
                <InputLabel className={classes.inputLabel}>End Year</InputLabel>
                <DatePicker
                  name="year_of_graduation"
                  control={control}
                  views={['year']}
                  format="YYYY"
                />
              </Box>
            </Grid>
          </Grid>
        </form>

        <Box display="flex" justifyContent="flex-end" mt={4} mb={4}>
          <Button
            variant="outlined"
            style={{ marginRight: '10px' }}
            type="button"
            name="addAnother"
            onClick={() => saveEducation(true)}
            isLoading={isLoading}
          >
            Save and add another
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            name="nask"
            onClick={() => saveEducation(false)}
            isLoading={isLoading}
          >
            Save
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EducationDataForm;
