import React from 'react';

// components
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { InputLabel, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { Link as ReactRouterLink, Redirect } from 'react-router-dom';
import { VisibilityOff, Visibility } from '@material-ui/icons';

// hooks
import { useForm } from 'react-hook-form';
import { useAuth } from 'context/auth-context';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  welcomeTextSecondary: {
    color: '#A6ABB8',
  },
  welcomeTextMain: {
    fontWeight: 'bold',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  inputField: {
    background: '#fff',
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [hidePassword, setHidePassword] = React.useState(false);
  const { register, handleSubmit } = useForm({ defaultValues: {} });
  const { login, loginInfo, user } = useAuth();
  const { error, isLoading, isError } = loginInfo;

  if (user?.isAuthenticated) return <Redirect to="/admin/overview" />;
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h6" className={classes.welcomeTextMain}>
          Welcome back
        </Typography>
        <Typography
          component="h2"
          variant="subtitle1"
          className={classes.welcomeTextSecondary}
        >
          Sign in to continue
        </Typography>
        {isError && (
          <Box m={2}>
            <Alert severity="error" variant="standard">
              {error?.detail}
            </Alert>
          </Box>
        )}
        <form className={classes.form} onSubmit={handleSubmit(login)}>
          <Box>
            <InputLabel htmlFor="email">Email address</InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              className={classes.inputField}
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              placeholder="Email address"
              autoFocus
              inputRef={register({ required: true })}
            />
          </Box>
          <Box>
            <InputLabel>Password</InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              className={classes.inputField}
              name="password"
              type={hidePassword ? 'text' : 'password'}
              id="password"
              placeholder="Password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ padding: 5, fontSize: 20 }}
                      onClick={() => setHidePassword(!hidePassword)}
                    >
                      {hidePassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputRef={register({ required: true })}
            />
          </Box>
          <Grid container>
            <Grid item xs>
              <Link
                component={ReactRouterLink}
                color="secondary"
                variant="body2"
                to="/forgot-password"
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
          </Button>
        </form>
      </div>
    </Container>
  );
}
