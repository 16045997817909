/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core';
import * as React from 'react';
import { ImageUploadIcon } from 'assets';
import { makeStyles } from '@material-ui/core/styles';
import mergeRef from 'utils/mergeRef';
import { Controller, Control } from 'react-hook-form';

interface Props {
  onChange?: Function | undefined;
  control: Control;
  name: string;
  multiple?: boolean;
  icon?: React.ReactNode;
  buttonUpload: boolean;
  text?: string;
}

const useStyles = makeStyles(() => ({
  inputWrapper: {
    width: '279px',
    height: '65px',
    background: '#FFFFFF',
    border: '2px dashed #C9D4DB',
    borderRadius: '3px',
    padding: '3px 0px',
    cursor: 'pointer',
  },
  buttonUpload: {
    color: '#930100',
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
}));

const FileUpload = React.forwardRef(
  (
    { onChange, name, control, multiple, icon: Icon, text, buttonUpload }: Props,
    ref
  ) => {
    const classes = useStyles();
    const fileRef = React.useRef(null);

    const triggerFileSelection = () => {
      if (fileRef.current !== null) {
        fileRef.current.click();
      }
    };

    return (
      <div
        onClick={triggerFileSelection}
        role="presentation"
        className={buttonUpload ? classes.buttonUpload : classes.inputWrapper}
      >
        <Controller
          name={name}
          control={control}
          render={(props) => (
            <input
              type="file"
              ref={mergeRef([fileRef, ref])}
              name={name}
              onChange={(e) => {
                props.onChange(multiple ? e.target.files : e.target.files?.[0]);
                onChange && onChange(e.target.files);
              }}
              className={classes.input}
              multiple={multiple}
            />
          )}
        />
        <Box display="flex" alignItems="center" flexDirection="column">
          {Icon !== undefined ? Icon : <ImageUploadIcon />}
          {text || 'Click here to upload'}
        </Box>
      </div>
    );
  }
);

export default FileUpload;
