import * as React from 'react';
import PropTypes from 'prop-types';
import MuiSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: '0',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  border: {
    '&:focus': {
      backgroundColor: 'transparent',
      border: '1px solid black',
    },
  },
}));

const Select = ({
  options,
  name: inputName,
  control,
  onChange: onSelect,
  value,
  style,
  useNameAsValue,
}) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      {control ? (
        <Controller
          name={inputName}
          control={control}
          defaultValue={value}
          render={({ onChange, ...rest }) => (
            <MuiSelect
              classes={{ select: classes.border }}
              labelId="demo-simple-select-filled-label"
              id={`${inputName}-simple-select-filled`}
              IconComponent={ExpandMoreIcon}
              onChange={(e) => {
                onChange(e.target.value);
                onSelect(e);
              }}
              {...rest}
              style={{ height: '40px', maxHeight: 300, ...style }}
            >
              {options?.map(({ id, name }) => (
                <MenuItem
                  value={useNameAsValue ? name : id}
                  style={{ minWidth: 200 }}
                  key={id}
                >
                  {name}
                </MenuItem>
              ))}
            </MuiSelect>
          )}
        />
      ) : (
        <MuiSelect
          classes={{ select: classes.border }}
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          displayEmpty={false}
          IconComponent={ExpandMoreIcon}
          onChange={onSelect}
          value={value}
          style={{ height: '40px', maxHeight: 300, ...style }}
        >
          {options?.map(({ id, name }) => (
            <MenuItem value={id} style={{ minWidth: 200 }} key={id}>
              {name}
            </MenuItem>
          ))}
        </MuiSelect>
      )}
    </FormControl>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    })
  ),
  name: PropTypes.string,
  control: PropTypes.shape({}),
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

Select.defaultProps = {
  options: [],
  name: '',
  control: null,
  onChange: () => {},
  value: '',
  placeholder: '',
};

export default Select;
