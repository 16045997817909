/* eslint-disable camelcase */
import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

type DocumentQueryKey = [string, string];

type DocumentInfo = {
  id: string;
  category: string;
};

type DocumentCategoryData = {
  category: string;
  data: Array<DocumentInfo>;
  count: number;
};

type DocumentQueryDataType = {
  data: Array<DocumentCategoryData>;
};

const updateCache = ({ id, cache, queryKey, category }) => {
  const queryData: DocumentQueryDataType | undefined = cache.getQueryData(queryKey);
  if (!queryData) return;

  const updatedData = queryData.data?.map((item) => {
    if (item.category === category) {
      return {
        ...item,
        count: item.count - 1,
        data: item.data.filter((file) => file.id !== id),
      };
    }
    return item;
  });

  cache.setQueryData(queryKey, { ...queryData, data: updatedData });
};

export default function useDeleteDocument(queryKey: DocumentQueryKey) {
  const cache = useQueryCache();
  return useMutation(({ id, category }: DocumentInfo) =>
    client(`employees/document/${id}/`, {
      method: 'DELETE',
    }).then(() => {
      updateCache({ cache, id, queryKey, category });
    })
  );
}
