import { format } from 'date-fns';

class OverviewUtils {
  static formatDailyAttendanceApiResponse(data) {
    return (
      data?.map((item) => ({
        name: format(new Date(item.date), 'MMM dd'),
        value: item.total,
      })) || []
    );
  }

  static convertHoursToSeconds(hours) {
    return hours * 60 * 60;
  }

  static formatStaffDemographyApiResponse(data) {
    const maleRecords = data?.find((item) => item.gender === 'MALE') || {};
    const femaleRecords = data?.find((item) => item.gender === 'FEMALE') || {};
    const keys = OverviewUtils.generateStaffDemographyKeys;

    return keys.map((item) => ({
      name: item.name,
      male: maleRecords[item.key],
      female: femaleRecords[item.key],
    }));
  }

  static formatEmploymentStatusApiResponse(data) {
    let total = data?.reduce((acc, item) => acc + item.total, 0);

    return (
      data?.reduce((acc, item) => {
        if (item.status) {
          acc.push({
            name: item.status,
            value: Number(((item.total / total) * 100).toFixed(2)),
          });
        }
        return acc;
      }, []) || []
    );
  }

  static formatDepartmentAvgTimeSpentApiResponse(data) {
    return (
      data?.reduce((acc, item) => {
        if (item.department) {
          acc.push({
            name: item.department,
            value: Math.round(item.avg || 0),
          });
        }
        return acc;
      }, []) || data
    );
  }

  static formatContractTypeAvgTimeSpentApiResponse(data) {
    return (
      data?.reduce((acc, item) => {
        if (item.contract_type) {
          acc.push({
            name: item.contract_type,
            value: Math.round(item.avg || 0),
          });
        }
        return acc;
      }, []) || []
    );
  }

  static formatTimeGraphApiResponse(data) {
    //This is used as a referenced dummy date for all times
    let referenceDay = `2020-01-01`;

    return (
      data?.reduce((acc, item) => {
        let date = item.date;
        let earliestTime = `${referenceDay} ${item.earliest}`;
        let avgTime = `${referenceDay} ${item.avg}`;
        let latestTime = `${referenceDay} ${item.latest}`;

        acc.push({
          x: date,
          y: [
            new Date(earliestTime).getTime(),
            OverviewUtils.getTimeFirstQuartile(earliestTime, avgTime),
            new Date(avgTime).getTime(),
            OverviewUtils.getTimeThirdQuartile(latestTime, avgTime),
            new Date(latestTime).getTime(),
          ],
          total: item.total,
        });
        return acc;
      }, []) || []
    );
  }

  static getTimeFirstQuartile(earliestTime, avgTime) {
    let lowest = new Date(earliestTime);
    let avg = new Date(avgTime);
    let lowestTimestamp = lowest.getTime();
    let avgTimestamp = avg.getTime();

    let firstQuartileTimestamp = (avgTimestamp + lowestTimestamp) / 2;
    return Math.round(firstQuartileTimestamp);
  }

  static getTimeThirdQuartile(latestTime, avgTime) {
    let latest = new Date(latestTime);
    let avg = new Date(avgTime);
    let latestTimestamp = latest.getTime();
    let avgTimestamp = avg.getTime();

    let thirdQuartileTimestamp = (avgTimestamp + latestTimestamp) / 2;
    return Math.round(thirdQuartileTimestamp);
  }

  static generateStaffDemographyKeys = [
    {
      name: 'Above 60',
      key: 'above_60',
    },
    {
      name: '46 - 60',
      key: 'btw_46_60',
    },
    {
      name: '36 - 45',
      key: 'btw_36_45',
    },
    {
      name: '26 - 35',
      key: 'btw_26_35',
    },
    {
      name: 'Below',
      key: 'below_25',
    },
  ];
}

export default OverviewUtils;
