/* eslint-disable camelcase */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import DocumentPreview from 'components/DocumentPreview';
import useFetchEmployeeData from 'hooks/useFetchEmployeeData';
import useFetchEmployeeDocuments from 'hooks/useFetchEmployeeDocuments';
import { titleCase } from 'utils/formatText';
import ProfileSection from './ProfileSection';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderTopColor: theme.palette.secondary.main,
    border: '1px solid #C7C8C9',
    borderTop: '5px solid',
    padding: '16px',
    paddingTop: '35px',
  },
}));

const profileDetailKeys = {
  leftContent: [
    { key: 'staff_id', label: 'Staff ID' },
    { key: 'title', label: 'Title' },
    {
      key: (record: { firstname: string; surname: string }) => (
        <span>
          {titleCase(record.surname)} {titleCase(record.firstname)}
        </span>
      ),
      label: 'Name:',
    },
    {
      key: (record: { gender: any }) => titleCase(record.gender),
      label: 'Gender',
    },
    {
      key: (record: { marital_status: any }) => titleCase(record.marital_status),
      label: 'Marital Status',
    },
    {
      key: (record: { maiden_name: any }) => titleCase(record.maiden_name),
      label: 'Maiden Name',
    },
  ],
  rightContent: [
    { key: 'date_of_birth', label: 'Date of Birth' },
    {
      key: (record: { religion: any }) => titleCase(record.religion),
      label: 'Religion',
    },
    {
      key: (record: { lga_of_origin: any }) => titleCase(record.lga_of_origin),
      label: 'LGA of Origin',
    },
    {
      key: (record: { state_of_origin: any }) => titleCase(record.state_of_origin),
      label: 'State of Origin',
    },
    {
      key: (record: { nationality: any }) => titleCase(record?.nationality),
      label: 'Nationality',
    },
    { key: 'bvn_number', label: 'BVN No:' },
  ],
};

const contactDetailKeys = {
  leftContent: [
    { key: 'phone_number', label: 'Phone Number' },
    { key: 'email_address', label: 'Email Address' },
  ],
  rightContent: [
    {
      key: (record: { permanent_address: any }) =>
        titleCase(record.permanent_address),
      label: 'Permanent Address',
    },
  ],
};

const relativesInformation = {
  leftContent: [
    {
      key: (record: { next_of_kin: any }) => titleCase(record.next_of_kin),
      label: 'Next of Kin',
    },
    {
      key: (record: { next_of_kin_relationship: any }) =>
        titleCase(record.next_of_kin_relationship),
      label: 'Relationship',
    },
    { key: 'next_of_kin_phone', label: 'Phone Number' },
  ],
  rightContent: [
    { key: 'next_of_kin_email', label: 'Email Address' },
    {
      key: (record: { next_of_kin_address: any }) =>
        titleCase(record.next_of_kin_address),
      label: 'Permanent Address',
    },
  ],
};

const payRollInformation = {
  leftContent: [
    {
      key: (record: { paying_office: any }) => titleCase(record.paying_office),
      label: 'Paying Office',
    },
    {
      key: (record: { account_type: any }) => titleCase(record.account_type),
      label: 'Account Type',
    },
    { key: 'bank', label: 'Bank' },
    { key: 'account_number', label: 'Account Number' },
  ],
  rightContent: [
    { key: 'date_of_first_salary', label: 'Date of Salary' },
    { key: 'date_of_last_salary', label: 'Date of last Salary' },
  ],
};

const educationHistory = {
  leftContent: [
    {
      key: (record: { name: any }) => titleCase(record.name),
      label: 'Institution Attended',
    },
    {
      key: (record: { course: any }) => titleCase(record.course),
      label: 'Course Studied',
    },
    { key: 'qualification', label: 'Qualification' },
    { key: 'year_of_graduation', label: 'Year of Graduation' },
  ],
};

const employmentDetails = {
  leftContent: [
    { key: 'date_of_first_appointment', label: 'First Appointment Date' },
    { key: 'date_of_confirmation', label: 'Date of confirmation of appointment' },
    {
      key: (record: { status: any }) => titleCase(record.status),
      label: 'Employment Status',
    },
    { key: 'date_of_first_promotion', label: 'Date of first promotion' },
    { key: 'date_of_contract_expiration', label: 'Date of Contract Expiration' },
    { key: 'cadre', label: 'Cadre' },
    {
      key: (record: { entry_grade_level: string; entry_grade_step: string }) =>
        `Grade ${record.entry_grade_level} / Step ${record.entry_grade_step}`,
      label: 'Grade Level/Step on Entry',
    },
    {
      key: (record: { current_grade_level: string; current_grade_step: string }) =>
        `Grade ${record.current_grade_level} / Step ${record.current_grade_step}`,
      label: 'Current Grade Level/Step',
    },
    {
      key: (record: { current_rank_title: any }) =>
        titleCase(record.current_rank_title),
      label: 'Current Rank title',
    },
    {
      key: (record: { institution: any }) => titleCase(record.institution),
      label: 'Institution',
    },
  ],
  rightContent: [
    {
      key: (record: { faculty: any }) => titleCase(record.faculty),
      label: 'Faculty/Department',
    },
    { key: 'years_in_service', label: 'Years of service as at date' },
    {
      key: 'number_of_ministry_served',
      label: 'Number of ministries, agencies, institutions served',
    },
    {
      key: (record: {
        date_of_first_appointment: string;
        date_of_contract_expiration: string;
      }) => (
        <span>
          {record.date_of_first_appointment} - {record.date_of_contract_expiration}
        </span>
      ),
      label: 'Date of service',
    },
    {
      key: (record: { ministries_served: string[] }) =>
        `${record.ministries_served?.map((ministry: string) => ministry)}`,
      label: 'Name of ministries, agencies, institutions served',
    },
  ],
};

const recordOfService = {
  leftContent: [
    {
      key: (record: { designation: any }) => titleCase(record.designation),
      label: 'Designation',
    },
    { key: 'date_of_appointment', label: 'Date of Appointment' },
    { key: 'salary_scale', label: 'Scale of Salary' },
    {
      key: (record: { increment_scale: string }) => `${record.increment_scale}%`,
      label: 'Increments Rates',
    },
    { key: 'increment_date', label: 'Date of Salary Increment' },
    { key: 'annual_leave_start_date', label: 'Annual Leave from' },
    { key: 'annual_leave_end_date', label: 'Annual Leave to' },
  ],
  rightContent: [
    { key: 'casual_leave_start_date', label: 'Casual Leave from' },
    { key: 'casual_leave_end_date', label: 'Casual Leave to' },
    { key: 'special_leave_start_date', label: 'Special Leave from' },
    { key: 'special_leave_end_date', label: 'Special Leave to' },
    { key: 'sick_leave_start_date', label: 'Sick Leave from' },
    { key: 'sick_leave_end_date', label: 'Sick Leave to' },
  ],
};

type ProfilePreviewProps = {
  employeeId: string;
};

export default function ProfilePreview(props: ProfilePreviewProps) {
  const { employeeId } = props;
  const classes = useStyles();
  const { data, isLoading } = useFetchEmployeeData(employeeId);
  const queryKey: [string, string] = ['employee-documents', employeeId];
  const { data: employeeData, isLoading: loadingDocs } = useFetchEmployeeDocuments(
    queryKey
  );

  if (isLoading || loadingDocs) return null;
  return (
    <div className={classes.root}>
      <ProfileSection title="Profile Data" data={data} keys={profileDetailKeys} />
      <Divider />
      <ProfileSection title="Contact Details" data={data} keys={contactDetailKeys} />
      <Divider />
      <ProfileSection
        title="Relatives Information"
        data={data}
        keys={relativesInformation}
      />
      <Divider />
      <ProfileSection
        title="Payroll Information"
        data={data}
        keys={payRollInformation}
      />
      <Divider />
      <ProfileSection
        title="Educational History"
        data={data?.education}
        keys={educationHistory}
        multiple
      />
      <Divider />
      <ProfileSection
        title="Employment Details"
        data={data}
        keys={employmentDetails}
      />

      <Divider />
      <ProfileSection
        title="Record of Service"
        data={data?.service_history}
        multiple
        keys={recordOfService}
      />
      <Divider />

      <ProfileSection title="Documents">
        <Box p={4} display="flex" alignItems="center" flexWrap="wrap">
          {employeeData?.data
            ?.reduce((acc: any, next: { data: any }) => [...next.data, ...acc], [])
            ?.map((document: { category: string; file: string }) => (
              <DocumentPreview
                file={{
                  ...document,
                  name: `${titleCase(
                    document.category.toLowerCase()
                  )}-${document.file.match(/documents.*$/)}`,
                }}
                action="download"
                handleAction={() => {}}
              />
            ))}
        </Box>
      </ProfileSection>
    </div>
  );
}
