import React, { ReactElement } from 'react';
import ReactCalendar, { OnChangeDateCallback } from 'react-calendar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { CalendarWhite, CalendarDark } from 'assets';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
    position: 'relative',
  },
  calendarButton: {
    background: '#930100',
    display: 'flex',
    justifyItems: 'space-between',
    alignItems: 'center',
    height: 34
  },
  lightCalendarButton: {
    background: '#fff',
    display: 'flex',
    justifyItems: 'space-between',
    alignItems: 'center',
    border: '1px solid #C9D4DB',
    height: 34
  },
  timeSelection: {
    background: '#930100',
    border: '1px solid #930100',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25',
    paddingLeft: '30px',
    color: '#FFF',
  },
  lightTimeSelection: {
    background: '#fff',
    border: '1px solid #C9D4DB',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25',
    paddingLeft: '30px',
  },
}));

interface Props {
  selectRange?: boolean;
  light?: boolean;
  value: Date[] | Date;
  onChange: OnChangeDateCallback;
}
const Calendar: React.FC<Props> = (props: Props): ReactElement => {
  const { selectRange, value, onChange, light } = props;
  const [open, setOpen] = React.useState(false);
  const inputRef = React.useRef(null);
  const classes = useStyles();

  React.useEffect(() => {
    const hideCalendar = (e) => {
      if (!inputRef?.current?.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('click', hideCalendar);
    return () => document.removeEventListener('click', hideCalendar);
  }, []);

  return (
    <Box className={classes.root} ref={inputRef}>
      <Box className={light ? classes.lightCalendarButton : classes.calendarButton}>
        <Typography style={{ color: light ? '#A6ABB8' : '#fff', padding: 10 }}>
          {selectRange ? (
            <span>
              {' '}
              {`${format(new Date(value[0]), 'LLL d')} - ${format(
                new Date(value[1]),
                'LLL d'
              )}`}
            </span>
          ) : null}
        </Typography>
        <Box
          style={{
            borderLeft: `1px solid ${light ? '#A6ABB8' : '#fff'}`,
            padding: '2px 10px',
            height: 34
          }}
          className={light ? classes.lightTimeSelection : classes.timeSelection}
          onClick={() => setOpen(true)}
        >
          {light ? <CalendarDark /> : <CalendarWhite />}
        </Box>
      </Box>
      {open && (
        <Box style={{ position: 'absolute', zIndex: 100 }}>
          <ReactCalendar
            onChange={onChange}
            value={value}
            selectRange={selectRange}
          />
        </Box>
      )}
    </Box>
  );
};

export default Calendar;
