/* eslint-disable camelcase */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// components
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'components/common/Select';
import { useForm, Controller } from 'react-hook-form';
import { Button, TextField, Typography } from '@material-ui/core';
import DatePicker from 'components/common/DatePicker';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import EmptyState from 'components/EmptyState';
import { EducationIcon, EditIcon, PlusIcon, DeleteIcon } from 'assets';

// Fixtures

// hooks
import useUpdateEmployeeData from 'hooks/useUpdateEmployeeData';
import useDeletePlaceOfWork from 'hooks/useDeletePlaceOfWork';
import { useConfirm } from 'material-ui-confirm';

import AddWorkHistoryModal from './AddWorkHistoryModal';
import useMinistries from 'hooks/useMinistries';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontStyle: 'normal',
  },
  personAvatarContainer: {
    height: 145,
    width: 145,
    background: '#FFFFFF',
    display: 'grid',
    placeItems: 'center',
    marginBottom: '20px',
  },
  formGroup: {
    margin: '15px 0',
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#4F4F4F',
  },
  educationIcon: {
    width: '60px',
    height: '60px',
    background: '#FAFBFD',
    display: 'grid',
    placeItems: 'center',
  },
  editButton: {
    display: 'flex',
    background: '#F2F2F2',
    height: '29px',
    width: '29px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '20px',
    cursor: 'pointer',
  },
  textfield: {
    '& .MuiInputBase-input:focus': {
      outline: '2px solid rgba(201, 212, 219, 0.8)',
    },
  },
}));

type routeParams = {
  id: string;
};

interface FormProps {
  data: any;
}

const gradeOptions = Array.from({ length: 20 }).map((_, index) => ({
  id: index + 1,
  name: `${index + 1}`,
}));

export default function EmploymentDetails(props: FormProps) {
  const { data } = props;
  const classes = useStyles();
  const { id }: routeParams = useParams();
  const [updateData, { isLoading }] = useUpdateEmployeeData(id);
  const [open, setOpen] = React.useState(false);
  const [currentIndex, setIndex] = React.useState<number | null>(null);

  const history = useHistory();
  
  const ministries = useMinistries();

  const allInstitutions = ministries?.data
    ? [].concat(ministries?.data?.results)
    : [];

  const { handleSubmit, control } = useForm({
    defaultValues: {
      ...data,
      entry_grade_level: data.entry_grade_level || 1,
      entry_grade_step: data.entry_grade_level || 1,
      current_grade_level: data.current_grade_level || 1,
      current_grade_step: data.current_grade_step || 1,
      number_of_ministry_served: data.number_of_ministry_served || 1,
    },
  });

  const updatePersonalDetails = (values: any) => {
    updateData(values, {
      onSuccess: () => { },
    });
  };

  const handleEdit = (index: number) => {
    setIndex(index);
    setOpen(true);
  };

  const [deletePlaceOfWork, { isLoading: deleting }] = useDeletePlaceOfWork(id);

  const confirm = useConfirm();

  const handleDelete = (placeOfWorkId: string) => {
    confirm({
      title: 'Are you sure you want to delete this?',
      confirmationButtonProps: { color: 'secondary', variant: 'contained' },
    })
      .then(() => {
        deletePlaceOfWork(placeOfWorkId);
      })
      .catch(() => { });
  };

  return (
    <Box>
      <form className={classes.root} onSubmit={handleSubmit(updatePersonalDetails)}>
        <Grid container spacing={4}>
          <Grid item md={5} sm={6}>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>
                  First Appointment Date:
                </InputLabel>
              </Grid>
              <Grid xs={6} item>
                <DatePicker name="date_of_birth" control={control} />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>
                  Date of confirmation of appointment:
                </InputLabel>
              </Grid>
              <Grid xs={6} item>
                <DatePicker name="date_of_birth" control={control} />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>
                  Employment Status:
                </InputLabel>
              </Grid>
              <Grid xs={6} item>
                <Select
                  name="status"
                  control={control}
                  options={[
                    { id: 'Temporary', name: 'Temporary' },
                    { id: 'Permanent', name: 'Permanent' },
                    { id: 'Contract', name: 'Contract' },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>
                  Date of first promotion:
                </InputLabel>
              </Grid>
              <Grid xs={6} item>
                <DatePicker name="date_of_birth" control={control} />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>
                  Date of Contract Expiration:
                </InputLabel>
              </Grid>
              <Grid xs={6} item>
                <DatePicker name="date_of_birth" control={control} />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>Cadre:</InputLabel>
              </Grid>
              <Grid xs={6} item>
                <Select
                  name="cadre"
                  control={control}
                  options={[
                    { id: 'G/L', name: 'G/L' },
                    { id: 'HATISS', name: 'HATISS' },
                    { id: 'CONTISS', name: 'CONTISS' },
                    { id: 'Others', name: 'Others' },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>
                  Grade Level/Step on Entry:
                </InputLabel>
              </Grid>
              <Grid xs={3} item>
                <Box mr={1}>
                  <Select
                    name="entry_grade_level"
                    control={control}
                    options={gradeOptions}
                  />
                </Box>
              </Grid>
              <Grid xs={3} item>
                <Box ml={1}>
                  <Select
                    name="entry_grade_step"
                    control={control}
                    options={gradeOptions}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5} sm={12}>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>
                  Current Grade Level/Step:
                </InputLabel>
              </Grid>
              <Grid xs={3} item>
                <Box mr={1}>
                  <Select
                    name="current_grade_level"
                    control={control}
                    options={gradeOptions}
                  />
                </Box>
              </Grid>
              <Grid xs={3} item>
                <Box ml={1}>
                  <Select
                    name="current_grade_step"
                    control={control}
                    options={gradeOptions}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>
                  Current Rank Title:
                </InputLabel>
              </Grid>
              <Grid xs={6} item>
                <Controller
                  name="current_rank_title"
                  control={control}
                  rules={{ required: true }}
                  render={({ onChange, ...rest }) => (
                    <TextField
                      className={classes.textfield}
                      {...rest}
                      onChange={onChange}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>Institution:</InputLabel>
              </Grid>
              <Grid xs={6} item>
                <Select
                  name="ministry"
                  control={control}
                  options={allInstitutions}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>
                  Faculty/Department:
                </InputLabel>
              </Grid>
              <Grid xs={6} item>
                <Controller
                  name="department"
                  control={control}
                  rules={{ required: true }}
                  render={({ onChange, ...rest }) => (
                    <TextField
                      className={classes.textfield}
                      {...rest}
                      onChange={onChange}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>
                  Years of service as at date :
                </InputLabel>
              </Grid>
              <Grid xs={6} item>
                <Select
                  name="years_in_service"
                  control={control}
                  options={Array.from({ length: 40 }).map((_, index) => ({
                    id: index + 1,
                    name: `${index + 1}`,
                  }))}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.formGroup}>
              <Grid xs={6} item>
                <InputLabel className={classes.inputLabel}>
                  Number of ministries, agencies, institutions served:
                </InputLabel>
              </Grid>
              <Grid xs={6} item>
                <Select
                  name="number_of_ministry_served"
                  control={control}
                  options={gradeOptions}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box pl={1}>
          <Grid container spacing={3}>
            {data?.places_of_work?.length ? (
              <>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '24px',
                    marginBottom: '12px',
                  }}
                  width={1}
                >
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      color: '#63686D',
                    }}
                  >
                    Place of work
                  </Typography>
                  <Button
                    onClick={() => {
                      setOpen(true);
                      setIndex(null);
                    }}
                  >
                    <PlusIcon />
                  </Button>
                </Box>

                {data?.places_of_work?.map(
                  (
                    { id: placeOfWorkId, name, designation, start_year, end_year },
                    index
                  ) => (
                    <Grid container spacing={2} key={placeOfWorkId}>
                      <Grid item xs={1}>
                        <Box className={classes.educationIcon}>
                          <EducationIcon />
                        </Box>
                      </Grid>
                      <Grid container item xs={11}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          flexDirection="row"
                          width={1}
                        >
                          <Box>
                            <Typography variant="h5">{name}</Typography>
                            <Typography>{designation}</Typography>
                            <Typography>
                              {start_year} - {end_year}
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <div className={classes.editButton}>
                              <EditIcon onClick={() => handleEdit(index)} />
                            </div>
                            <div className={classes.editButton}>
                              <DeleteIcon
                                onClick={() => handleDelete(placeOfWorkId)}
                              />
                            </div>
                          </Box>
                        </Box>
                        <Box
                          style={{
                            width: '100%',
                            paddingRight: '24px',
                            marginBottom: '24px',
                            marginTop: '8px',
                          }}
                        >
                          <Divider />
                        </Box>
                      </Grid>
                    </Grid>
                  )
                )}
              </>
            ) : (
                <Box
                  width={1}
                  style={{
                    padding: '54px',
                    border: '1px solid #C7C8C9',
                    textAlign: 'center',
                    margin: '16px',
                  }}
                >
                  <Typography style={{ marginBottom: '15px' }}>
                    Ministry/Agencies/Institutions Served
                </Typography>
                  <EmptyState
                    title="Empty"
                    message="This user currently has no institution registered."
                    buttonTitle="Click here to add"
                    onClick={() => setOpen(true)}
                  />
                </Box>
              )}
          </Grid>
        </Box>

        <Box display="flex" justifyContent="flex-end" m={4}>
          <Button
            onClick={() => history.push(`/admin/staffs/${id}`)}
            variant="outlined"
            style={{ marginRight: '10px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={isLoading}
            type="submit"
          >
            {isLoading ? <CircularProgress size={24} /> : 'Save Changes'}
          </Button>
        </Box>
      </form>

      {open && (
        <AddWorkHistoryModal
          open={open}
          closeModal={() => setOpen(false)}
          placeOfWork={
            currentIndex !== null ? data?.places_of_work?.[currentIndex] : {}
          }
        />
      )}
    </Box>
  );
}
