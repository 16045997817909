import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { Paper, Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import Calendar from 'components/Calendar';
import Select from 'components/common/Select';
import NumberUtils from 'utils/NumberUtils';

export const TimeOfEntry = {
  'First Check in': 'clock_in',
  'Last Check in': 'clock_out',
};

const TimeGraph = ({
  timeOfEntry,
  date,
  onChangeTimeOfEntry,
  onChangeDate,
  data,
}) => {
  const classes = useStyles();

  const options = useMemo(
    () => ({
      title: {
        text: undefined,
      },
      plotOptions: {
        boxPlot: {
          colors: {
            upper: '#b20385',
            lower: '#b20385',
          },
        },
      },
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return format(new Date(value), 'hh:mm aaa');
          },
        },
      },
      xaxis: {
        labels: {
          formatter: function (value) {
            return value ? format(new Date(value), 'MMM d') : '';
          },
        },
      },
      tooltip: {
        custom: function (values) {
          return generateToolTipHtml(values);
        },
      },
      stroke: {
        colors: ['#948F8F', '#fff'],
        width: 1,
      },
      markers: {
        colors: ['#fff'],
      },
      fill: {
        colors: '#fff',
        opacity: 0.9,
        type: 'solid',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
      noData: {
        text: 'No attendance entry during this period',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '16px',
        },
      },
    }),
    [data]
  );

  const series = [
    {
      type: 'boxPlot',
      data,
    },
  ];

  const generateToolTipHtml = ({ dataPointIndex }) => {
    const formattedTimeOfEntry = timeOfEntry?.replace('_', ' ');

    return `
      <div class="arrow_box" style="padding: 10px">
        <span>Unique attendance: ${NumberUtils.formatNumberUsingCommas(
          data[dataPointIndex].total
        )}</span><br />
        <span>Earliest ${formattedTimeOfEntry}: ${format(
      new Date(data[dataPointIndex].y[0]),
      'hh:mm aaa'
    )}</span><br />
        <span>Latest ${formattedTimeOfEntry}: ${format(
      new Date(data[dataPointIndex].y[4]),
      'hh:mm aaa'
    )}</span><br />
        <span>Average ${formattedTimeOfEntry}: ${format(
      new Date(data[dataPointIndex].y[2]),
      'hh:mm aaa'
    )}</span>
      </div>
    `;
  };

  const renderHeader = () => {
    return (
      <Box display="flex" justifyContent="space-between" className={classes.header}>
        <Typography color="textPrimary">Time Graph</Typography>
        <Box display="flex">
          <Calendar
            selectRange
            value={date || [new Date(), new Date()]}
            onChange={onChangeDate}
          />
          <Box ml={1}>
            <Select
              name="institution"
              options={Object.keys(TimeOfEntry).map((time) => ({
                id: TimeOfEntry[time],
                name: time,
              }))}
              value={timeOfEntry}
              onChange={(e) => onChangeTimeOfEntry(e.target.value)}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Paper>
      {renderHeader()}
      <Chart options={options} series={series} type="boxPlot" height={350} />
    </Paper>
  );
};

const useStyles = makeStyles({
  header: {
    padding: `10px 15px`,

    '& .MuiSelect-icon.MuiSelect-iconOutlined': {
      color: '#fff',
    },

    '& .MuiOutlinedInput-root.MuiInputBase-formControl': {
      height: `34px !important`,
      background: '#930100',
      width: 150,
    },

    '& .MuiSelect-outlined.MuiSelect-outlined': {
      color: '#fff',
    },
  },
});

TimeGraph.propTypes = {
  timeOfEntry: PropTypes.any,
  date: PropTypes.any,
  onChangeTimeOfEntry: PropTypes.func,
  onChangeDate: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.arrayOf(PropTypes.number).isRequired,
      total: PropTypes.number.isRequired,
    })
  ),
};

export default React.memo(TimeGraph);
