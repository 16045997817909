import * as React from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

type Props = {
  children: React.ReactNode;
};

type ToastProps = {
  toast?: Function;
  setOptions?: Function;
};

type ToastOptions = {
  message?: string;
  severity?: 'success' | 'error' | 'info';
};

interface Toast {
  init(): void;
  setOptions: Function;
  show(): void;
  setShow(): Function;
}

const value: ToastProps = {};

const ToastContext = React.createContext(value);

class ToastClass implements Toast {
  constructor(setOptions: Function, setShow: Function) {
    this.setOptions = setOptions;
    this.setShow = setShow;
  }

  init(options: ToastOptions) {
    if (options) {
      this.setOptions((defaultOptions: ToastOptions) => ({
        ...defaultOptions,
        ...options,
      }));
    }
    return this;
  }

  show(): any {
    this.setShow(true);
  }
}

const ToastProvider = ({ children }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<ToastOptions>({
    message: 'Operations was successful',
    severity: 'success',
  });

  return (
    <ToastContext.Provider
      value={{
        toast: new ToastClass(setOptions, setOpen),
        setOptions,
      }}
    >
      <>
        {children}
        <Snackbar
          autoHideDuration={3000}
          open={open}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity={options.severity}>{options.message}</Alert>
        </Snackbar>
      </>
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const context = React.useContext(ToastContext);
  return context;
};

export { useToast, ToastProvider };
