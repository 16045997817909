import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export default function useUsers(params) {
  // console.log(params);

  return useQuery(['users', params], (_, fieldParams) =>
    client('auth/users/', { params: fieldParams }).then((res) => res)
  );
}
