import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Box, makeStyles, Typography } from '@material-ui/core';

const TimeSpentBarChart = ({ title, data, height }) => {
  const classes = useStyles();

  const options = {
    title: {
      text: undefined,
    },
    chart: {
      type: 'bar',
      background: '#F2F2F2',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: '50%',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom',
        },
      },
    },
    colors: data?.map((_item) => '#53627C'),
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#4F4F4F'],
      },
      formatter: function (_val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex];
      },
      offsetX: 0,
      offsetY: -25,
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      width: 1,
      colors: ['#53627C'],
    },
    xaxis: {
      labels: {
        show: false,
      },
      categories: data?.map((item) => item.name),
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return '';
          },
        },
      },
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
    noData: {
      text: 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
      },
    },
  };

  const series = [
    {
      data: data?.map((item) => item.value),
    },
  ];

  return (
    <Box className={classes.container}>
      <Typography color="textPrimary" className={classes.title}>
        {title}
      </Typography>
      <Chart options={options} series={series} type="bar" height={height || 700} />
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    background: '#F2F2F2',
  },
  title: {
    fontWeight: 700,
    lineHeight: 1.1,
    padding: `20px 20px 0px`,
  },
});

TimeSpentBarChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  height: PropTypes.number,
};

export default React.memo(TimeSpentBarChart);
