import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import Select from 'components/common/Select';
import Pagination from '@material-ui/lab/Pagination';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { ExitToApp, PersonAdd, BorderColor, Book } from '@material-ui/icons';
import { event as log_event } from 'utils/constants';
import { useForm } from 'react-hook-form';
import useFetchAuditLog from 'hooks/useAuditLog';
import DatePicker from 'components/common/DatePicker';
import useUsers from 'hooks/useUsers';
import SearchIcon from 'assets/img/EmptySearchResults.png';

const Index = () => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postPerPage] = React.useState(10);

  const { control, watch } = useForm({
    defaultValues: {
      selectUser: 'Select user',
      date: null,
      event: 'All event',
    },
  });

  const { date, selectUser, event } = watch();
  const { data, isLoading } = useFetchAuditLog({
    page: currentPage,
    page_size: postPerPage,
    start_date: date === null ? undefined : format(date, 'yyyy-MM-dd'),
    search: event === 'All event' ? undefined : event,
    user: selectUser === 'Select user' ? undefined : selectUser,
  });

  const handlePageChange = (_event, value) => {
    setCurrentPage(value);
  };

  const all_user = data?.results?.map((usr) => {
    const checkUser = usr.user ? usr.user : 'Not availabe';
    const { id, firstname, lastname } = checkUser;
    const fullname = `${firstname} ${lastname}`;
    return {
      id,
      fullname,
    };
  });

  const { data: userList } = useUsers({
    page_size: 50,
  });
  const selectUserOption =
    userList?.results?.map((user) => ({
      id: user.id,
      name: user.firstname + ' ' + user.lastname,
    })) || [];

  return (
    <Box>
      <form>
        <Box className={classes.top_container}>
          <Select
            style={{ width: '90%' }}
            name="event"
            value={event}
            control={control}
            options={[
              {
                id: 'All event',
                name: 'All event',
              },
              ...log_event,
            ]}
            placeholder="Event"
          />
          <DatePicker
            placeholder="Date"
            name="date"
            control={control}
            value={date}
            style={{ width: '90%' }}
            mr={1}
          />
          <Select
            style={{ marginLeft: 30 }}
            value={selectUser}
            control={control}
            name="selectUser"
            options={[
              {
                id: 'Select user',
                name: 'Select user',
              },
              ...selectUserOption,
            ]}
            placeholder="Select User"
          />
          {/* <input placeholder="Select user" value={selectUser} name="selectUser" /> */}
        </Box>
      </form>
      <Box className={classes.tableBox} component={Paper}>
        {isLoading && (
          <CircularProgress style={{ color: '#933B00', textAlign: 'center' }} />
        )}
        {data?.total === 0 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ width: '100%' }}
          >
            <Typography
              color="textSecondary"
              style={{ fontWeight: 600, padding: '100px 0' }}
            >
              <img src={SearchIcon} alt="SearchIcon" /> <br />
              No data available
            </Typography>
          </Box>
        )}
        {data?.results?.map((auditLog) => {
          const checkUser = auditLog.user ? auditLog.user : 'Not availabe';
          const { firstname, lastname } = checkUser;
          const { id, updated_at, action, description } = auditLog;
          const name = `${firstname} ${lastname}`;
          const dateT = new Date(updated_at);
          const dateTime =
            dateT.toLocaleDateString() + ' ' + dateT.toLocaleTimeString();
          return (
            <>
              <div key={id} className={classes.auditTable}>
                <Box
                  display="flex"
                  justifyContent="space-around"
                  style={{
                    width: '100%',
                  }}
                >
                  <Box className={classes.btnIcon}> {getAuditIcon(action)}</Box>
                  <Box width={1}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography className={classes.status}>{action}</Typography>
                      <Typography className={classes.time}>{dateTime}</Typography>
                    </Box>
                    <Typography className={classes.name}>
                      {name}{' '}
                      <span className={classes.desc_span}>
                        {' '}
                        {getAuditDescription(action, name, description)}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </div>
              <Divider />
            </>
          );
        })}
        <Box style={{ height: 30 }}>
          <Pagination
            count={data?.total_pages}
            page={currentPage}
            onChange={handlePageChange}
            color="secondary"
            shape="rounded"
            className={classes.footer}
          />
        </Box>
      </Box>
    </Box>
  );
};

const Status = {
  LOGIN: 'Log in',
  UPDATE: 'Profile update',
  CREATION: 'User creation',
  ATTENDANCE: 'Attendance log update',
};

const getAuditDescription = (action, name, description) => {
  let desc;
  switch (action) {
    case Status.LOGIN:
      desc = (
        <Typography style={{ color: '#948f8f', paddingLeft: 10 }}>
          {description}
        </Typography>
      );
      break;
    case Status.UPDATE:
      desc = (
        <Typography style={{ color: '#948f8f', paddingLeft: 10 }}>
          {description}
        </Typography>
      );
      break;
    case Status.CREATION:
      desc = (
        <Typography style={{ color: '#948f8f', paddingLeft: 10 }}>
          {description}
        </Typography>
      );
      break;
    case Status.ATTENDANCE:
      desc = (
        <Typography style={{ color: '#948f8f', paddingLeft: 10 }}>
          {description}
        </Typography>
      );
      break;
    default:
      desc = 'No details for this activity';
      break;
  }
  return desc;
};

const getAuditIcon = (action) => {
  let icon;
  switch (action) {
    case Status.LOGIN:
      icon = <ExitToApp style={{ fontSize: 25 }} />;
      break;
    case Status.UPDATE:
      icon = <BorderColor style={{ fontSize: 25 }} />;
      break;
    case Status.CREATION:
      icon = <PersonAdd style={{ fontSize: 25 }} />;
      break;
    case Status.ATTENDANCE:
      icon = <Book style={{ fontSize: 25 }} />;
      break;
    default:
      icon = null;
      break;
  }
  return icon;
};

export default Index;

const useStyles = makeStyles({
  top_container: {
    display: 'flex',
    paddingTop: 40,
    width: '70%',
    marginBottom: 40,
  },
  tableBox: {
    padding: '20px 20px',
    marginBottom: 50,
    position: 'relative',
    overflowY: 'scroll',
    maxHeight: '70vh',
  },
  auditTable: {
    display: 'flex',
    justifyContent: 'space-between',
    justifyItems: 'center',
    paddingBottom: 10,
    paddingTop: 10,
  },
  status: {
    fontSize: 15,
    fontWeight: 400,
    color: '#930100;',
    fontFamily: 'Nunito Sans',
    fontSize: 14,
    fontWeight: 400,
  },
  name: {
    fontSize: 14,
    fontWeight: 600,
    display: 'flex',
    justifyItems: 'center',
  },
  btnIcon: {
    color: '#930100;',
    marginRight: 20,
    fontSize: 15,
  },
  desc_span: {
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    fontWeight: 500,
    color: '#948f8f',
  },
  time: {
    color: '#00000',
    fontSize: 14,
    fontWeight: 400,
  },
  footer: {
    position: 'relative',
    bottom: -10,
    float: 'right',
  },
});
