import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import { Link as ReactRouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  welcomeTextSecondary: {
    color: '#A6ABB8',
    textAlign: 'center',
  },
  welcomeTextMain: {
    fontWeight: 'bold',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h6" className={classes.welcomeTextMain}>
          Forgot password
        </Typography>
        <Typography
          component="h2"
          variant="subtitle1"
          className={classes.welcomeTextSecondary}
        >
          Don’t worry, just enter the email address you registered with and we will
          send you a link to reset the password{' '}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Box>
            <InputLabel>Email address</InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              placeholder="Email address"
              autoFocus
              inputRef={register}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Send Link
          </Button>
          <Box justifyContent="center" display="flex">
            <Link component={ReactRouterLink} variant="body2" to="/login">
              Go Back
            </Link>
          </Box>
        </form>
      </div>
    </Container>
  );
}
