/* eslint-disable camelcase */
import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

type PlaceOfWork = {
  id: string;
  name: string;
  designation: string;
  start_year: string;
  end_year?: string;
};

type employeeDataType = {
  places_of_work: Array<PlaceOfWork>;
};

export default function usePostPlaceOfWork(staffId: string) {
  const cache = useQueryCache();
  const addPlaceOfWork = (data: PlaceOfWork) => {
    const employeeData: employeeDataType = cache.getQueryData([
      'employee-data',
      staffId,
    ]);

    if (employeeData) {
      const updatedData = {
        ...employeeData,
        places_of_work: [data, ...employeeData.places_of_work],
      };
      cache.setQueryData(['employee-data', staffId], updatedData);
    }
  };
  return useMutation((values: any) =>
    client(`employees/${staffId}/place-of-work/`, {
      data: values,
    }).then(({ data }) => addPlaceOfWork(data))
  );
}
