import { useQuery } from 'react-query';
// import { client } from 'utils/api-client';

export function useLocalGovernmentArea(state: string) {  
  return useQuery(['all-staff-attendance', state], () => {
    if (!state) return Promise.resolve([]);
    return fetch(
      `http://locationsng-api.herokuapp.com/api/v1/states/${state
        .replace('State', '')
        .trim()}/lgas`
    )
      .then((res) => res.json())
      .then((data) => data.map((lga: string) => ({ id: lga, name: lga })));
  });
}
