import React from 'react';
import { Typography, Grid, Box, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { StaffGroupIcon, AverageTimeIcon, CalendarActivity } from 'assets';
export default function StatisticCards({
  staffSize,
  averageTimeSpent,
  mostActiveDay,
}) {
  const classes = useStyles();
  const statistics = [
    {
      name: 'Staff Size',
      value: staffSize?.toLocaleString() || 0,
      icon: StaffGroupIcon,
    },
    {
      name: 'Average Time Spent',
      value: averageTimeSpent || 0,
      icon: AverageTimeIcon,
    },
    {
      name: 'Most Active Day',
      value: mostActiveDay || 0,
      icon: CalendarActivity,
    },
  ];

  const renderStatisticCard = (statistic) => {
    const IconComponent = statistic.icon;

    return (
      <Paper>
        <Box display="flex" className={classes.statCard} flexDirection="column">
          <Typography className="name" color="textSecondary">
            {statistic.name}
          </Typography>
          <Typography className="value" variant="h5" color="textPrimary">
            {statistic.value}
          </Typography>
          <IconComponent className="icon" />
        </Box>
      </Paper>
    );
  };

  return (
    <Grid container spacing={2}>
      {statistics.map((statistic) => (
        <Grid item xs={4}>
          <React.Fragment key={statistic.name}>
            {renderStatisticCard(statistic)}
          </React.Fragment>
        </Grid>
      ))}
    </Grid>
  );
}

StatisticCards.propTypes = {
  staffSize: PropTypes.number.isRequired,
  averageTimeSpent: PropTypes.number.isRequired,
  mostActiveDay: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
  statCard: {
    padding: 20,
    position: 'relative',

    '& .name': {
      fontSize: 14,
    },

    '& .value': {
      fontWeight: 700,
      textTransform: 'capitalize',
    },

    '& .icon': {
      position: 'absolute',
      bottom: 0,
      right: 5,
    },
  },
}));
