import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

type SearchParams = {
  search: string;
  staff_id?: string;
  page: number;
  page_size: number;
};

export default function useFetchEmployees(params: SearchParams) {
  return useQuery(['employees', params], (_, fieldParams: SearchParams) =>
    client('employees', { params: fieldParams }).then((res) => res)
  );
}
