import csc from 'country-state-city';

const countryList = csc.getAllCountries().map((country) => ({
  name: country.name,
  id: country.isoCode,
}));

export const useCountryList = () => {
  return {
    countries: countryList,
  };
};
