/* eslint-disable camelcase */
import React from 'react';
import { useParams } from 'react-router-dom';
// components
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import DatePicker from 'components/common/DatePicker';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/common/Button';

import usePostPlaceOfWork from 'hooks/usePostPlaceOfWork';
import useUpdatePlaceOfWork from 'hooks/useUpdatePlaceOfWork';
import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    fontStyle: 'normal',
  },
  personAvatarContainer: {
    height: 145,
    width: 145,
    background: '#FFFFFF',
    display: 'grid',
    placeItems: 'center',
    marginBottom: '20px',
  },
  formGroup: {
    margin: '15px 0',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#0A0A0A',
    marginBottom: '5px',
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    paddingTop: '10px',
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '22px',
  },
}));

type routeParams = {
  id: string;
};

type PlaceOfWork = {
  id: string;
  name: string;
  designation: string;
  start_year: string | Date;
  end_year: string | Date;
};

interface FormProps {
  open: boolean;
  closeModal: Function;
  placeOfWork: PlaceOfWork;
}

const AddWorkHistoryModal: React.FC<FormProps> = (props: FormProps) => {
  const classes = useStyles();
  const { id }: routeParams = useParams();
  const { closeModal, open, placeOfWork } = props;

  const getValues = (data: PlaceOfWork): PlaceOfWork => {
    return {
      ...data,
      start_year: `${data.start_year}`,
      end_year: `${data.end_year}`,
    };
  };

  const { register, handleSubmit, control, reset, errors } = useForm<PlaceOfWork>({
    defaultValues: {
      ...getValues(placeOfWork),
    },
  });

  const [postPlaceOfWork, { isLoading: posting }] = usePostPlaceOfWork(id);
  const [updatePlaceOfWork, { isLoading: updating }] = useUpdatePlaceOfWork(
    id,
    placeOfWork.id
  );

  const handleUpdatePlaceOfWork = (saveAnother: boolean) => {
    handleSubmit((values: PlaceOfWork) => {
      const { start_year, end_year } = values;
      const fn = placeOfWork?.id ? updatePlaceOfWork : postPlaceOfWork;
      fn(
        {
          ...values,
          start_year: format(new Date(start_year), 'yyyy'),
          end_year: format(new Date(end_year), 'yyyy'),
        },
        {
          onSuccess: () => {
            reset({
              name: '',
              start_year: new Date(),
              end_year: new Date(),
              designation: '',
            });
            !saveAnother && closeModal();
          },
        }
      );
    })();
  };

  const isLoading = posting || updating;

  const isFieldError = (name: string) => {
    return errors?.[name]?.message;
  };

  return (
    <Dialog open={open}>
      <Box id="form-dialog-title" className={classes.dialogTitle}>
        <Typography variant="h5">Place of work</Typography>
        <Button onClick={closeModal}>X</Button>
      </Box>
      <Divider />
      <DialogContent>
        <form className={classes.root}>
          <Box className={classes.formGroup}>
            <InputLabel className={classes.inputLabel}>
              Name (Ministry, agency, institution)
            </InputLabel>
            <TextField
              name="name"
              variant="outlined"
              error={isFieldError('name')}
              style={{ width: '100%' }}
              inputRef={register({ required: 'Field is required' })}
              placeholder="Ex: Oyo State University"
              size="small"
            />
          </Box>
          <Box className={classes.formGroup}>
            <InputLabel className={classes.inputLabel}>Work Designation</InputLabel>
            <TextField
              name="designation"
              variant="outlined"
              error={isFieldError('designation')}
              placeholder="Ex: Account Auditor"
              style={{ width: '100%' }}
              inputRef={register({ required: 'Field is required' })}
              size="small"
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item md={6} sm={6}>
              <Box className={classes.formGroup}>
                <InputLabel className={classes.inputLabel}>Start Year</InputLabel>
                <DatePicker
                  name="start_year"
                  control={control}
                  views={['year']}
                  format="YYYY"
                />
              </Box>
            </Grid>
            <Grid item md={6} sm={6}>
              <Box className={classes.formGroup}>
                <InputLabel className={classes.inputLabel}>End Year</InputLabel>
                <DatePicker
                  name="end_year"
                  control={control}
                  views={['year']}
                  format="YYYY"
                />
              </Box>
            </Grid>
          </Grid>
        </form>
        <Box display="flex" justifyContent="flex-end" mt={4} mb={4}>
          <Button
            onClick={() => handleUpdatePlaceOfWork(true)}
            variant="outlined"
            style={{ marginRight: '10px' }}
            isLoading={isLoading}
            type="button"
          >
            Save and add another
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            isLoading={isLoading}
            onClick={() => handleUpdatePlaceOfWork(false)}
          >
            Save
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddWorkHistoryModal;
