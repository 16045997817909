import React from 'react';
/* eslint-disable camelcase */

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

interface ProfileSectionProps {
  title: string;
  data?: any;
  keys?: any;
  multiple?: boolean;
  center?: boolean;
  children?: React.ReactNode;
}

interface dataItem {
  key: string | Function;
  label: string;
}

export default function ProfileSection(props: ProfileSectionProps) {
  const { title, keys = {}, data, multiple, children } = props;
  const { leftContent, rightContent } = keys;
  const profileData = multiple ? data : [data];
  return (
    <Grid container spacing={3} style={{ margin: '10px 0' }}>
      <Grid item xs={3}>
        <Typography
          variant="body1"
          component="h3"
          style={{ fontWeight: 'bold', color: '#000000' }}
        >
          {title}
        </Typography>
      </Grid>
      <>
        {children ? (
          <Grid xs={8}>{children}</Grid>
        ) : (
          <>
            {profileData?.map((record) => (
              <>
                <Grid item xs={4}>
                  {leftContent.map(({ key, label }: dataItem) => (
                    <Grid container>
                      <Grid xs={4} item>
                        {label}:
                      </Grid>
                      <Grid xs={8} item>
                        {typeof key === 'function'
                          ? key(record) || 'N/A'
                          : record[key] || 'N/A'}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                {rightContent && (
                  <Grid item xs={4}>
                    {rightContent.map(({ key, label }: dataItem) => (
                      <Grid container>
                        <Grid xs={4} item>
                          {label}:
                        </Grid>
                        <Grid xs={8} item>
                          {typeof key === 'function'
                            ? key(record) || 'N/A'
                            : record[key] || 'N/A'}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
                <Divider />
              </>
            ))}
          </>
        )}
      </>
    </Grid>
  );
}
