import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, makeStyles, Typography } from '@material-ui/core';
import { format } from 'date-fns';

import Select from 'components/common/Select';
import TimeSpentBarChart from './TimeSpentBarChart';
import OverviewUtils from 'utils/OverviewUtils';

export const TimeSpent = {
  '0 - 2hrs': `0-${OverviewUtils.convertHoursToSeconds(2)}`,
  '3 - 5hrs': `${OverviewUtils.convertHoursToSeconds(
    3
  )}-${OverviewUtils.convertHoursToSeconds(5)}`,
  'Above 5hrs': `${OverviewUtils.convertHoursToSeconds(
    6
  )}-${OverviewUtils.convertHoursToSeconds(24)}`,
};

const AverageTimeSpent = ({
  date,
  timeSpent,
  onChangeTimeSpent,
  data: { department, contractType },
}) => {
  const classes = useStyles();

  const getLength = (data) => {
    const height = data?.length !== 0 ? data?.length * 50 + 100 : 300;
    return height < 300 ? 300 : height;
  };

  const sortData = (data) => data.sort((a, b) => a.name.localeCompare(b.name));

  const renderHeader = () => {
    return (
      <Box display="flex" justifyContent="space-between" className={classes.header}>
        <div>
          <Typography className="title" variant="h6">
            Time Graph
          </Typography>
          <Typography className="sub-title">
            {format(date, 'EEEE LLL d, yyyy')}
          </Typography>
        </div>
        <Box ml={2}>
          <Select
            name="hours"
            options={Object.keys(TimeSpent).map((time) => ({
              id: TimeSpent[time],
              name: time,
            }))}
            value={timeSpent}
            onChange={(e) => onChangeTimeSpent(e.target.value)}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Paper>
      {renderHeader()}
      <Box p={2} style={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'scroll' }}>
        <TimeSpentBarChart
          title="Average Time spent by Contract Type"
          data={sortData(contractType || [])}
          height={getLength(contractType)}
        />
        <Box mt={2}>
          <TimeSpentBarChart
            title="Average Time spent by Department"
            data={sortData(department || [])}
            height={getLength(department)}
          />
        </Box>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles({
  header: {
    background: '#31373D',
    padding: '20px',

    '& .title': {
      color: '#fff',
      fontWeight: 700,
      lineHeight: 1,
    },

    '& .sub-title': {
      color: '#fff',
    },

    '& .MuiSelect-icon.MuiSelect-iconOutlined': {
      color: '#fff',
    },

    '& .MuiOutlinedInput-root.MuiInputBase-formControl': {
      height: `34px !important`,
      background: '#930100',
      width: 150,
    },

    '& .MuiSelect-outlined.MuiSelect-outlined': {
      color: '#fff',
    },
  },
});

AverageTimeSpent.propTypes = {
  date: PropTypes.any.isRequired,
  timeSpent: PropTypes.string.isRequired,
  onChangeTimeSpent: PropTypes.func.isRequired,
  data: PropTypes.shape({
    department: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ),
    contractType: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ),
  }),
};

export default React.memo(AverageTimeSpent);
