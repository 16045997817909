export const downloadCSV = (title, arrayData) => {
  let csv = '';
  arrayData.forEach((row) => {
    csv += row.join(',');
    csv += '\n';
  });

  console.log('log...');

  const hiddenElement = document.createElement('a');
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
  hiddenElement.target = '_blank';
  hiddenElement.download = `${title}.csv` || 'Attendance-template.csv';
  hiddenElement.click();
};
