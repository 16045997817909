/* eslint-disable camelcase */
import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

type UserType = {
  id: string;
};

type queryDataObject = {
  results: Array<UserType>;
  total: number;
};

export default function useDeleteUser(params) {
  const cache = useQueryCache();

  return useMutation((userId: string) =>
    client(`auth/users/${userId}/`, {
      method: 'DELETE',
    }).then(() => {
      const queryData: queryDataObject | undefined = cache.getQueryData([
        'users',
        params,
      ]);
      const data = {
        ...queryData,
        total: queryData ? queryData?.total - 1 : 0,
        results: queryData
          ? queryData.results.filter((user) => user.id !== userId)
          : [],
      };
      cache.setQueryData(['users', params], data);
    })
  );
}
