/* eslint-disable camelcase */
import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

type PlacesOfWork = {
  id: string;
  name: string;
  designation: string;
  start_year: string;
  end_year: string;
};

type employeeDataType = {
  places_of_work: Array<PlacesOfWork>;
};

export default function useDeletePlaceOfWork(staffId: string) {
  const cache = useQueryCache();

  const removePlaceOfWork = (placeOfWorkId: string) => {
    const employeeData: employeeDataType = cache.getQueryData([
      'employee-data',
      staffId,
    ]);

    if (employeeData) {
      const updatedData = {
        ...employeeData,
        places_of_work: employeeData.places_of_work.filter(
          ({ id }) => id !== placeOfWorkId
        ),
      };
      cache.setQueryData(['employee-data', staffId], updatedData);
    }
  };

  return useMutation((placeOfWorkId: string) =>
    client(`employees/place-of-work/${placeOfWorkId}/`, {
      method: 'DELETE',
    }).then((res) => removePlaceOfWork(placeOfWorkId))
  );
}
