import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import { makeStyles } from '@material-ui/core/styles';
import FileUpload from './FileUpload/FileUpload';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  formArea: {
    padding: 18,
  },
}));

export default function StaffList() {
  const classes = useStyles();
  const [csvData, setData] = React.useState([]);

  return (
    <Grid className={classes.container} container>
      <Card className={classes.formArea}>
        {/* <FileUpload setData={setData} data={csvData} /> */}
      </Card>
    </Grid>
  );
}
