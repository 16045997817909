import * as React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Select from 'components/common/Select';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import Button from 'components/common/Button';
import { useForm } from 'react-hook-form';
import useMinistries from 'hooks/useMinistries';

import EmployeeList from './EmployeeList';
import { useAuth } from 'context/auth-context';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderTopColor: theme.palette.secondary.main,
    border: '1px solid #C7C8C9',
    borderTop: '5px solid',
    padding: '16px',
    marginTop: '24px',
    maxHeight: '800px',
    overflowY: 'scroll',
    background: '#FFFFFF',
  },
  searchWrapper: {
    '& .search-icon': {
      color: theme.palette.secondary.text,
    },
  },
}));

type routerParamsType = {
  id: string;
};

export default function Personnel() {
  const classes = useStyles();
  const { id }: routerParamsType = useParams();
  const [searchValues, setSearchValues] = React.useState({
    institution: ' ',
    search: '',
  });

  const { handleSubmit, control, watch, register } = useForm();

  const ministries = useMinistries();
  const { user } = useAuth();

  const allInstitutions = ministries?.data
    ? [].concat(ministries?.data?.results)
    : [];

  const onSubmit = (data) => {
    setSearchValues(data);
  };

  return (
    <Box pt={6}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" className={classes.searchWrapper}>
          <TextField
            variant="outlined"
            size="small"
            name="search"
            placeholder="Search Name"
            style={{ width: 400 }}
            defaultValue={searchValues.search}
            inputRef={register}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="search-icon" />
                </InputAdornment>
              ),
            }}
          />
          {/* <Box style={{ width: 400 }} ml={1} mr={1}>
            <Select
              name="institution"
              control={control}
              options={[{ id: ' ', name: 'All Institutions' }, ...allInstitutions]}
              placeholder="All Institutions"
              value={searchValues.institution || ''}
            />
          </Box> */}
          {(user.role === 'GLOBAL ADMIN' || user.role === 'GLOBAL USER') && (
            <>
              <Box style={{ width: 400 }} ml={1} mr={1}>
                <Select
                  name="institution"
                  control={control}
                  options={[
                    { id: ' ', name: 'All Institutions' },
                    ...allInstitutions,
                  ]}
                  placeholder="All Institutions"
                  value={searchValues.institution || ''}
                />
              </Box>
            </>
          )}
          <Box ml={1}>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              disableRipple
              type="submit"
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              Search
            </Button>
          </Box>
        </Box>
      </form>
      <EmployeeList searchValues={searchValues} />
    </Box>
  );
}
