import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MUIAvatar from '@material-ui/core/Avatar';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatarColor: {
    background: theme.palette.secondary.main,
  },
  avatarSizeSmall: {
    height: 24,
    width: 24,
    fontSize: '10px',
  },
  avatarSizeBig: {
    height: 35,
    width: 35,
  },
}));

export default function Avatar({ name, image, size }) {
  // const [firstName, lastName] = name?.split(' ');
  // const [firstName, lastName] = (name || '')?.split(' ');
  const [firstName, lastName] = name ? name.split(' ') : ['Admin', 'User'];
  const classes = useStyles();
  return (
    <MUIAvatar
      className={clsx(
        classes.avatarColor,
        size === 'small' ? classes.avatarSizeSmall : classes.avatarSizeBig
      )}
      src={image}
    >
      {firstName.charAt(0).toUpperCase()} {lastName.charAt(0).toUpperCase()}
    </MUIAvatar>
  );
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  image: PropTypes.string,
};

Avatar.defaultProps = {
  size: 'big',
  image: '',
};
