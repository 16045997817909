/* eslint-disable camelcase */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
// components
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'components/common/Select';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import DatePicker from 'components/common/DatePicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import nigerianBanks from 'utils/nigerianBanks.json'

// hooks
import useUpdateEmployeeData from 'hooks/useUpdateEmployeeData';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    fontStyle: 'normal',
  },
  personAvatarContainer: {
    height: 145,
    width: 145,
    background: '#FFFFFF',
    display: 'grid',
    placeItems: 'center',
    marginBottom: '20px',
  },
  formGroup: {
    margin: '15px 0',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#4F4F4F',
  },
  textfield: {
    '& .MuiInputBase-input:focus': {
      outline: '2px solid rgba(201, 212, 219, 0.8)',
    },
  },
}));

type routeParams = {
  id: string;
};

interface FormProps {
  data: any;
}

export default function PayrollDataForm(props: FormProps) {
  const { data } = props;
  const classes = useStyles();
  const { id }: routeParams = useParams();
  const [updateData, { isLoading }] = useUpdateEmployeeData(id);

  const history = useHistory();

  const { register, handleSubmit, control } = useForm({
    defaultValues: { ...data },
  });

  const updatePersonalDetails = (values: any) => {
    let { date_of_first_salary, date_of_last_salary } = values;
    date_of_first_salary = format(
      new Date(values.date_of_first_salary),
      'yyyy-MM-dd'
    );
    date_of_last_salary = format(new Date(values.date_of_last_salary), 'yyyy-MM-dd');
    updateData({ ...values, date_of_first_salary, date_of_last_salary });
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit(updatePersonalDetails)}>
      <Grid container spacing={10}>
        <Grid item md={6} sm={6}>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Paying Office:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <TextField
                className={classes.textfield}
                name="staff_id"
                variant="outlined"
                style={{ width: '100%' }}
                inputRef={register({ required: true })}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Account Type:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <Select
                name="account_type"
                control={control}
                options={[
                  { id: 'CURRENT', name: 'Current' },
                  { id: 'SAVINGS', name: 'Savings' },
                  { id: 'OTHERS', name: 'Others' },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Bank:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <Select
                name="bank"
                control={control}
                useNameAsValue={true}
                options={nigerianBanks}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6}>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Account Number:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <TextField
                className={classes.textfield}
                name="account_number"
                variant="outlined"
                style={{ width: '100%' }}
                inputRef={register({ required: true })}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Date of Salary:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <DatePicker
                name="date_of_first_salary"
                control={control}
                format="MM/dd/yyyy"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.formGroup}>
            <Grid xs={4} item className={classes.inputLabel}>
              <InputLabel>Date of last Salary:</InputLabel>
            </Grid>
            <Grid xs={8} item>
              <DatePicker
                name="date_of_last_salary"
                control={control}
                format="MM/dd/yyyy"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="flex-end" m={4}>
        <Button
          onClick={() => history.push(`/admin/staffs/${id}`)}
          variant="outlined"
          style={{ marginRight: '10px' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? <CircularProgress size={24} /> : 'Save Changes'}
        </Button>
      </Box>
    </form>
  );
}
