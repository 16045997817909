/* eslint-disable camelcase */
import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

const updateCache = ({ education, cache, queryKey }) => {
  const queryData = cache.getQueryData(queryKey);
  const updatedData = {
    ...queryData,
    education: queryData.education.map((item) => {
      if (item.id === education.id) return education;
      return item;
    }),
  };
  cache.setQueryData(queryKey, updatedData);
};

export default function useUpdateEducationHistory(
  staffId: string,
  educationId: string
) {
  const cache = useQueryCache();
  return useMutation(
    (values: any) =>
      client(`employees/education-history/${educationId}/`, {
        data: values,
        method: 'PATCH',
      }).then((res) => res),
    {
      onSuccess: ({ data }) => {
        updateCache({
          education: data,
          cache,
          queryKey: ['employee-data', staffId],
        });
      },
    }
  );
}
