/* eslint-disable camelcase */
import * as React from 'react';
import { Tab, TabPanel, TabProvider, Tabs } from 'components/Tabs';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import useFetchEmployeeData from 'hooks/useFetchEmployeeData';

import { CircularProgress } from '@material-ui/core';
import PersonalDataForm from './PersonalDataForm';
import PayrollDataForm from './PayrollDataForm';
import EducationDataForm from './EducationDataForm';
import EmploymentDetails from './EmploymentDetails';
import RecordsOfService from './RecordsOfService';
import Documents from './Documents';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderTopColor: theme.palette.secondary.main,
    border: '1px solid #C7C8C9',
    borderTop: '5px solid',
    padding: '16px',
    marginTop: '24px',
    maxHeight: '800px',
    overflowY: 'scroll',
    background: '#FFFFFF',
  },
}));

type routerParamsType = {
  id: string;
};

export default function EditProfile() {
  const classes = useStyles();
  const { id }: routerParamsType = useParams();
  const { data, isLoading } = useFetchEmployeeData(id);

  return (
    <Box pt={8} style={{ overflow: 'hidden' }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TabProvider>
          <Tabs>
            <Tab label="Personal Data" />
            <Tab label="Payroll Information" />
            <Tab label="Educational History" />
            <Tab label="Employment Details" />
            <Tab label="Record of Service" />
            <Tab label="Documents" />
          </Tabs>
          <TabPanel className={classes.root}>
            <PersonalDataForm data={data} />
          </TabPanel>
          <TabPanel className={classes.root}>
            <PayrollDataForm data={data} />
          </TabPanel>
          <TabPanel className={classes.root}>
            <EducationDataForm data={data} />
          </TabPanel>
          <TabPanel className={classes.root}>
            <EmploymentDetails data={data} />
          </TabPanel>
          <TabPanel className={classes.root}>
            <RecordsOfService serviceHistory={data?.service_history} />
          </TabPanel>
          <TabPanel className={classes.root}>
            <Documents data={data} />
          </TabPanel>
        </TabProvider>
      )}
    </Box>
  );
}
