export const roles = [
  {
    id: 'GLOBAL ADMIN',
    name: 'Global Admin',
  },
  {
    id: 'LOCAL ADMIN',
    name: 'Local Admin',
  },
  {
    id: 'GLOBAL USER',
    name: 'Global User',
  },
  {
    id: 'LOCAL USER',
    name: 'Local User',
  },
];

export const localRoles = [
  {
    id: 'LOCAL ADMIN',
    name: 'Local Admin',
  },
  {
    id: 'LOCAL USER',
    name: 'Local User',
  },
];

export const maritalStatus = [
  { id: 'SINGLE', name: 'Single' },
  { id: 'MARRIED', name: 'Married' },
  { id: 'DIVORCED', name: 'Divorced' },
  { id: 'WIDOWED', name: 'Widowed' },
];

export const event = [
  {
    id: 'User creation',
    name: 'User creation',
  },
  {
    id: 'Log in',
    name: 'Log in',
  },
  {
    id: 'Profile update',
    name: 'Profile update',
  },
  {
    id: 'Attendance log update',
    name: 'Atendance log update',
  },
];
