/* eslint-disable camelcase */
import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

type PlaceOfWork = {
  id: string;
  name: string;
  designation: string;
  start_year: string;
  end_year?: string;
};

type employeeDataType = {
  places_of_work: Array<PlaceOfWork>;
};

export default function useUpdatePlaceOfWork(
  staffId: string,
  placeOfWorkId: string
) {
  const cache = useQueryCache();
  const updatePlaceOfWork = (id: string, data: PlaceOfWork) => {
    const employeeData: employeeDataType = cache.getQueryData([
      'employee-data',
      staffId,
    ]);

    if (employeeData) {
      const updatedData = {
        ...employeeData,
        places_of_work: employeeData.places_of_work.map((work) =>
          work.id !== id ? work : data
        ),
      };
      cache.setQueryData(['employee-data', staffId], updatedData);
    }
  };
  return useMutation((values: any) =>
    client(`employees/place-of-work/${placeOfWorkId}/`, {
      data: values,
      method: 'PATCH',
    }).then(({ data }) => updatePlaceOfWork(placeOfWorkId, data))
  );
}
