import decodeJWT from 'jwt-decode';

export const setAuthUser = (response) => {
  try {
    const { access } = response;
    const decodedToken = decodeJWT(access);
    delete decodedToken.token_type;
    delete decodedToken.exp;

    const { ...rest } = decodedToken;
    const user = { ...rest, isAuthenticated: true };
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', access);
    return user;
  } catch (error) {
    return null;
  }
};

export const getUserFromStorage = () => {
  const { token } = localStorage;

  const data = {
    isAuthenticated: false,
  };

  if (token) {
    const decodedToken = decodeJWT(token);
    const { exp } = decodedToken;
    const currentTime = Date.now() / 1000;

    if (exp < currentTime) {
      localStorage.removeItem('token');
      return data;
    }

    const user = JSON.parse(localStorage.getItem('user'));

    return {
      ...user,
      isAuthenticated: true,
    };
  }

  return data;
};

export const updateUser = (data) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const updatedUserDetails = { ...user, ...data };
  localStorage.setItem('user', JSON.stringify(updatedUserDetails));
  return updatedUserDetails;
};

export const removeUserFromStorage = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
};

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  window.location.replace('/');
};
