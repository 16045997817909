import { useQuery } from 'react-query';
import { client } from 'utils/api-client';

export default function useFetchStaffAttendance(params) {
  const { id, ...rest } = params;
  return useQuery(['staff-attendance', id, rest], (_, _id, fieldParams) =>
    client(`employees/${id}/attendance`, { params: fieldParams }).then(
      (res) => res.data
    )
  );
}
