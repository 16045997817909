/* eslint-disable camelcase */
import React, { useState, useEffect} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FileUpload from './FileUpload';
import useUploadAttendance from 'hooks/useUploadAttendance';
import { useNotification } from "components/NotificationBanner";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  DialogActions: {
    marginTop: 16,
    padding: '0px 24px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function UploadModal({ open, handleClose }) {
  const [upload, {isLoading, isError, isSuccess}] = useUploadAttendance();
  const [uploadedFile, setUploadedFile] = useState(null);

  const classes = useStyles();
  const notification = useNotification();

  
  useEffect(() => {
    if (isSuccess){
      handleCloseModal();
      notification.success({
      message: "Success!!",
      description: "Data uploaded successfully."
    })} 

    if (isError){
      notification.error({
        message: "Failed to Upload Data",
        description: "Kindly make sure document is in the correct format"
      })} 
         
 // eslint-disable-next-line
  },[isSuccess, isError])

  const handleUploadClick = () => {
    const formData = new FormData();
    formData.append('file', uploadedFile);
    upload(formData);
  };

  const handleCloseModal = () => {
    setUploadedFile(null);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h5">Upload Document</Typography>
      </DialogTitle>
      <DialogContent style={{ width: '500px' }}>
        <FileUpload setData={(file) => setUploadedFile(file)} />
      </DialogContent>
      <DialogActions className={classes.DialogActions}>
        {!!uploadedFile && (
          <Button
            variant="contained"
            color="secondary"
            disabled={isLoading}
            type="button"
            style={{ marginRight: '10px' }}
            onClick={handleUploadClick}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Proceed'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

UploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
