import React from 'react';
import PropTypes from 'prop-types';
// components
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import Menu from 'components/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';
import TableStats from './TableStats';
import { EmptySearch } from 'assets';
import SearchIcon from 'assets/img/EmptySearchResults.png';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  avatar: {
    height: 35,
    width: 35,
  },
  pagination: {
    background: '#FFFFFF',
    border: '1px solid #E4E9EC',
    padding: 10,
  },
  tableHeading: {
    color: theme.palette.secondary.text,
    fontWeight: 'bold',
  },
}));

const BasicTable = React.forwardRef(
  (
    {
      columns,
      data,
      pagination,
      showTableStats,
      isLoading,
      onRowItemClick,
      csvData,
      csvTitle,
    },
    ref
  ) => {
    const classes = useStyles();

    const menuItems = [
      {
        id: 10,
        text: 10,
      },
      {
        id: 20,
        text: 20,
      },
      {
        id: 50,
        text: 50,
      },
      {
        id: 100,
        text: 100,
      },
    ];

    let currentListShowing = pagination?.total;

    if (pagination?.total / pagination?.pageSize > 1) {
      currentListShowing =
        pagination.pageSize * (pagination.currentPage - 1) + data?.length;
    }

    const renderTableHeader = () => {
      return (
        <TableHead>
          <TableRow>
            {columns.map(({ header, sort }) => (
              <React.Fragment key={header}>
                {sort ? (
                  <TableCell className={classes.tableHeading}>
                    <TableSortLabel active IconComponent={ExpandMoreIcon}>
                      {header}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell className={classes.tableHeading}>{header}</TableCell>
                )}
              </React.Fragment>
            ))}
          </TableRow>
        </TableHead>
      );
    };

    const renderTableBodyContent = () => {
      if (isLoading) {
        return (
          <TableRow>
            <TableCell colSpan={columns.length} align="center">
              <CircularProgress color="secondary" />
            </TableCell>
          </TableRow>
        );
      } else if (data?.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={columns.length} align="center">
              <Typography
                color="textSecondary"
                style={{ fontWeight: 600, padding: '100px 0' }}
              >
                <img src={SearchIcon} alt="SearchIcon" /> <br />
                No data available
              </Typography>
            </TableCell>
          </TableRow>
        );
      }
      return data?.map((row, index) => (
        <TableRow
          key={index}
          onClick={() => onRowItemClick(row)}
          style={{ cursor: !!onRowItemClick && 'pointer' }}
          hover
        >
          {columns.map(({ key, render }) => {
            const cell = render?.(row) || row[key];
            return (
              <>
                {key === 'id' ? (
                  <TableCell component="th" scope="row">
                    {cell}
                  </TableCell>
                ) : (
                  <TableCell>{cell}</TableCell>
                )}
              </>
            );
          })}
        </TableRow>
      ));
    };

    const renderTableFooter = () => {
      return (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={columns.length}>
              <Box display="flex" width={1} justifyContent="flex-end">
                <Box display="flex" component="span">
                  <Box
                    display="flex"
                    alignItems="center"
                    ml={4}
                    component="span"
                    color="secondary"
                  >
                    Items per page: {pagination?.pageSize}
                  </Box>
                  <Menu
                    icon={ExpandMoreIcon}
                    menuItems={menuItems}
                    onMenuItemSelected={(pagesize) => {
                      pagination?.setPageSize(pagesize);
                      pagination?.setCurrentPage(1);
                    }}
                  />
                </Box>
                <Pagination
                  count={pagination?.totalPages}
                  color="secondary"
                  shape="rounded"
                  page={pagination?.currentPage}
                  style={{ display: 'flex' }}
                  onChange={(_, value) => pagination?.setCurrentPage(value)}
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableFooter>
      );
    };

    return (
      <Box>
        {showTableStats ? (
          <TableStats
            isLoading={isLoading}
            currentListShowing={currentListShowing}
            total={pagination?.total}
            csvData={csvData}
            csvTitle={csvTitle}
          />
        ) : null}
        <TableContainer
          component={Paper}
          style={{ overflowY: 'scroll', maxHeight: '70vh' }}
        >
          <Table
            stickyHeader
            className={classes.table}
            aria-label="simple table"
            style={{ tableLayout: 'fixed' }}
          >
            {renderTableHeader()}
            <TableBody>{renderTableBodyContent()}</TableBody>
            {renderTableFooter()}
          </Table>
        </TableContainer>
      </Box>
    );
  }
);

BasicTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      sort: PropTypes.boll,
    })
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.node,
    })
  ).isRequired,
  pagination: PropTypes.shape({
    total: PropTypes.number,
    totalPages: PropTypes.number,
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func,
    setPageSize: PropTypes.func,
  }).isRequired,
  showTableStats: PropTypes.bool,
  isLoading: PropTypes.bool,
  onRowItemClick: PropTypes.func,
  csvData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  csvTitle: PropTypes.string,
};

BasicTable.defaultProps = {
  showTableStats: true,
  isLoading: false,
  onRowItemClick: () => {},
  csvData: [],
  csvTitle: '',
};

export default BasicTable;
