import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { EllipsesIcon } from 'assets';

export default function MenuDropdown({ menuItems, onMenuItemSelected, icon: Icon }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
      >
        {Icon ? <Icon /> : <EllipsesIcon />}
      </IconButton>
      {menuItems.length > 0 && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          {menuItems.map(({ text, id, onItemClick }) => (
            <MenuItem
              onClick={() => {
                onMenuItemSelected(id);
                onItemClick && onItemClick(id);
                handleClose();
              }}
              style={{ padding: '.5em' }}
              key={id}
            >
              {text}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
}

MenuDropdown.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  icon: PropTypes.elementType,
  onMenuItemSelected: PropTypes.func,
};

MenuDropdown.defaultProps = {
  menuItems: [],
  icon: null,
  onMenuItemSelected: () => {},
};
