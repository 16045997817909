/* eslint-disable react/prop-types */
import * as React from 'react';
import { styled } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import { spacing } from '@material-ui/system';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * Applies the spacing system to the material UI Button
 */
const Button = styled(MuiButton)(spacing);

const EnhancedButton = (props) => {
  const { isLoading, children, ...rest } = props;
  return (
    <Button {...rest}>
      {isLoading ? <CircularProgress size={24} /> : children}
    </Button>
  );
};

export default EnhancedButton;
