import React from 'react';

// components
import { Button, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import FileUpload from 'components/common/FileUpload';
import DocumentPreview from 'components/DocumentPreview';
import { UpArrowIcon, DownArrowIcon } from 'assets';

// hooks
import { useForm } from 'react-hook-form';
import { titleCase } from 'utils/formatText';

type FileObject = {
  id?: string;
  name?: string;
  file?: string;
};

type Document = {
  category: string;
  data: Array<FileObject>;
  count: number;
};

interface DocumentUploadFormProps {
  onSubmit: Function;
  handleDelete: Function;
  document: Document;
  uploadingFile: boolean;
  uploadComplete: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    fontStyle: 'normal',
  },
  personAvatarContainer: {
    height: 145,
    width: 145,
    background: '#FFFFFF',
    display: 'grid',
    placeItems: 'center',
    marginBottom: '20px',
  },
  formGroup: {
    margin: '15px 0',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#4F4F4F',
  },
  educationIcon: {
    width: '60px',
    height: '60px',
    background: '#FAFBFD',
    display: 'grid',
    placeItems: 'center',
  },
  editButton: {
    display: 'flex',
    background: '#F2F2F2',
    height: '29px',
    width: '29px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '20px',
    cursor: 'pointer',
  },
  documentSection: {
    background: '#FFFFFF',
    border: '1px solid #C9D4DB',
  },
  documentSectionHeader: {
    borderBottom: '1px solid #C9D4DB',
    padding: '10px 8px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  documentPreview: {
    width: '279px',
    height: '65px',
    background: '#FFFFFF',
    border: '1px solid #C9D4DB',
    borderRadius: '3px',
    padding: '1px 0px',
    marginRight: '15px',
    marginTop: '10px',
  },
}));

export default function DocumentUploadForm(props: DocumentUploadFormProps) {
  const { onSubmit, document, uploadingFile, uploadComplete, handleDelete } = props;
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      files: [],
    },
  });
  const [open, setOpen] = React.useState<boolean>(false);

  const classes = useStyles();

  const { files } = watch(['files']);

  React.useEffect(() => {
    setValue('files', []);
  }, [uploadComplete, setValue]);

  const previewFiles = React.useMemo(() => {
    if (document?.data?.length) {
      const savedFiles = document?.data?.map((fileObj) => ({
        id: fileObj.id,
        name: fileObj?.file?.match(/.+(\/.+)$/)?.[1]?.replace('/', ''),
        url: fileObj?.file,
      }));
      return files?.length
        ? [
            ...savedFiles,
            ...Array.from(files).map((file) => ({
              url: URL.createObjectURL(file),
              name: file.name,
            })),
          ]
        : savedFiles;
    }
    return Array.from(files).map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name,
    }));
  }, [files, document?.data]);

  return (
    <form
      onSubmit={handleSubmit((values) =>
        onSubmit({ ...values, category: document.category })
      )}
    >
      <Box
        display="flex"
        className={classes.documentSection}
        flexDirection="column"
        mb={4}
      >
        <Box
          className={classes.documentSectionHeader}
          onClick={() => setOpen(!open)}
        >
          <span>
            <Typography component="span" style={{ color: '#63686D' }} variant="h5">
              {titleCase(document.category)}
            </Typography>
            <Typography component="span" color="textSecondary">
              {' '}
              - {document?.count} documents
            </Typography>
          </span>
          <span>{open ? <UpArrowIcon /> : <DownArrowIcon />}</span>
        </Box>
        <Box
          display={`${open ? 'flex' : 'none'}`}
          flexDirection="column"
          alignItems="center"
        >
          <Box p={4} display="flex" alignItems="center" flexWrap="wrap">
            {previewFiles.map((file) => (
              <DocumentPreview
                file={file}
                uploadingFile={uploadingFile}
                document={document}
                action="delete"
                handleAction={() =>
                  handleDelete({
                    id: file?.id,
                    category: document.category,
                  })
                }
              />
            ))}
            <Box
              className={classes.documentPreview}
              alignItems="center"
              ml="-4px"
              style={{ marginTop: '12px' }}
            >
              <FileUpload control={control} name="files" multiple />
            </Box>
          </Box>
          <Box ml={4} pb={4}>
            <Button variant="contained" color="secondary" type="submit">
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}
