import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import { Logo } from 'assets';
import { PrunedgeLogo } from 'assets';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: '#FAF2F2',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    paddingTop: '20px',
  },
  logo: {
    // marginBottom: '20px',
  },
}));

export default function AuthScreenLayout({ children }) {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          {/* <Logo
            className={classes.logo}
            onClick={() => history.push('/')}
            style={{ cursor: 'pointer' }}
          /> */}
          <PrunedgeLogo
            className={classes.logo}
            onClick={() => history.push('/')}
            style={{ cursor: 'pointer', width: 223, height: 100 }}
          />
          {/* <Typography>Oyo State Personnel Data Management Platform</Typography> */}
          <Typography>Biometric Time & Attendance Management System</Typography>
        </Box>
        <Container maxWidth="lg">{children}</Container>
      </main>
    </div>
  );
}

AuthScreenLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
