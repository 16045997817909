import React, { Fragment } from 'react';
import Admin from 'layouts/admin-layout';
import AuthLayout from 'layouts/auth-layout';

// routes
import { Route } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';

// pages
import Attendance from 'screens/Attendance';
import StaffDetails from 'screens/StaffDetails';
import EditProfile from 'screens/EditProfile';
import MangeUsers from 'screens/MangeUsers';
import UploadAttendance from 'screens/UploadAttendance';
import Login from 'screens/Login';
import UploadPreview from 'screens/UploadPreview';
import ForgotPassword from 'screens/ForgotPassword';
import Employees from 'screens/Employees';
import Overview from 'screens/Overview';
import Audit from 'screens/Audit';
import { VerifyUser } from 'screens/ConfirmAccount';

export const routes = [
  {
    headerTitle: 'Overview',
    path: '/admin/overview',
    component: Overview,
    exact: true,
    layout: Admin,
    route: PrivateRoute,
  },
  {
    headerTitle: 'Attendance',
    path: '/admin/attendance',
    component: Attendance,
    exact: true,
    layout: Admin,
    route: PrivateRoute,
  },
  {
    headerTitle: 'Upload Document',
    path: '/admin/staffs/upload',
    component: UploadAttendance,
    exact: true,
    layout: Admin,
    route: PrivateRoute,
  },
  {
    headerTitle: 'Preview',
    path: '/admin/staffs/upload/preview',
    component: UploadPreview,
    exact: true,
    layout: Fragment,
    route: PrivateRoute,
  },
  {
    headerTitle: 'Staff',
    path: '/admin/staffs/:id',
    exact: true,
    component: StaffDetails,
    layout: Admin,
    route: PrivateRoute,
  },
  {
    headerTitle: 'Profile',
    path: '/admin/staffs/:id/edit',
    exact: true,
    component: EditProfile,
    layout: Admin,
    route: PrivateRoute,
  },
  {
    headerTitle: 'Users',
    path: '/admin/users',
    component: MangeUsers,
    exact: true,
    layout: Admin,
    route: PrivateRoute,
  },
  {
    headerTitle: 'Audit Log',
    path: '/admin/audit',
    component: Audit,
    exact: true,
    layout: Admin,
    route: PrivateRoute,
  },
  {
    path: '/',
    component: Login,
    exact: true,
    layout: AuthLayout,
    route: Route,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
    layout: AuthLayout,
    route: Route,
  },
  {
    path: '/verify-user',
    component: VerifyUser,
    exact: true,
    layout: AuthLayout,
    route: Route,
  },
  {
    headerTitle: 'Staff',
    path: '/admin/staffs',
    component: Employees,
    exact: false,
    layout: Admin,
    route: Route,
  },
];
