/* eslint-disable camelcase */
import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';
import { useToast } from 'context/toast-context';

export default function useUpdateEmployeeData(staff_id) {
  const cache = useQueryCache();
  const { toast } = useToast();
  return useMutation(
    (values: any) =>
      client(`employees/${staff_id}/`, {
        data: values,
        method: 'PATCH',
      }).then((res) => res),
    {
      onSuccess: () => {
        toast
          .init({
            message: 'Profile updated',
          })
          .show();
        cache.invalidateQueries(['employee-data', staff_id]);
      },
    }
  );
}
