import React, { useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { sub, format } from 'date-fns';

import AutoComplete from './components/Autocomplete';
import StatisticCards from './components/StatisticCards';
import TimeGraph, { TimeOfEntry } from './components/TimeGraph';
import DailyAttendance from './components/DailyAttendance';
import StaffDemography from './components/StaffDemography';
import EmploymentStatus from './components/EmploymentStatus';
import AverageTimeSpent, { TimeSpent } from './components/AverageTimeSpent';
import useFetchDashboardTimeGraph from 'hooks/useFetchDashboardTimeGraph';
import useFetchDashboardOverview from 'hooks/useFetchDashboardOverview';
import useFetchDashboardDepartmentAvgTimeSpent from 'hooks/useFetchDashboardDepartmentAvgTimeSpent';
import useFetchDashboardContractTypeTimeSpent from 'hooks/useFetchDashboardContractTypeAvgTimeSpent';
import useFetchDashboardDailyAttendance from 'hooks/useFetchDashboardDailyAttendance';
import LoadingView from 'components/LoadingView';
import OverviewUtils from 'utils/OverviewUtils';
import { useAuth } from 'context/auth-context';
import { titleCase } from 'utils/formatText';

export default function Overview() {
  const { user } = useAuth();
  const classes = useStyles();
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const initialDate = new Date(
    sub(new Date(), {
      days: 20,
    })
  );
  const [timeGraphQueryParams, setTimeGraphQueryParams] = useState({
    date: [initialDate, new Date()],
    filter_type: TimeOfEntry['First Check in'],
  });
  const [dailyAttendanceQueryParams, setDailyAttendanceQueryParams] = useState({
    date: [initialDate, new Date()],
  });
  const [averageTimeSpentQueryParams, setAverageTimeSpentQueryParams] = useState({
    timeSpent: TimeSpent['0 - 2hrs'],
  });

  const ministries = useMemo(() => {
    return selectedInstitutions.map((institution) => institution.id);
  }, [selectedInstitutions]);

  const {
    isFetching: isLoadingTimeGraph,
    data: timeGraphData,
  } = useFetchDashboardTimeGraph({
    ministries,
    filter_type: timeGraphQueryParams.filter_type,
    start_date: format(timeGraphQueryParams.date[0], 'yyyy-MM-dd'),
    end_date: format(timeGraphQueryParams.date[1], 'yyyy-MM-dd'),
  });

  const {
    isFetching: isLoadingOverview,
    data: overviewData,
  } = useFetchDashboardOverview({
    ministries,
  });

  const {
    isFetching: isLoadingAvgDepartmentTimeSpent,
    data: avgDepartmentTimeSpentData,
  } = useFetchDashboardDepartmentAvgTimeSpent({
    ministries,
    date: format(timeGraphQueryParams.date[0], 'yyyy-MM-dd'),
    start_duration: averageTimeSpentQueryParams.timeSpent.split('-')[0],
    end_duration: averageTimeSpentQueryParams.timeSpent.split('-')[1],
  });

  const {
    isFetching: isLoadingAvgContractTypeTimeSpent,
    data: avgContractTypeTimeSpentData,
  } = useFetchDashboardContractTypeTimeSpent({
    ministries,
    date: format(timeGraphQueryParams.date[0], 'yyyy-MM-dd'),
    start_duration: averageTimeSpentQueryParams.timeSpent.split('-')[0],
    end_duration: averageTimeSpentQueryParams.timeSpent.split('-')[1],
  });

  const {
    isFetching: isLoadingDailyAttendance,
    data: dailyAttendanceData,
  } = useFetchDashboardDailyAttendance({
    ministries,
    start_date: format(dailyAttendanceQueryParams.date[0], 'yyyy-MM-dd'),
    end_date: format(dailyAttendanceQueryParams.date[1], 'yyyy-MM-dd'),
  });

  const handleChangeTimeGraphQueryParams = (changeset) => {
    setTimeGraphQueryParams((prevState) => ({
      ...prevState,
      ...changeset,
    }));
  };

  const handleChangeDailyAttendanceQueryParams = (changeset) => {
    setDailyAttendanceQueryParams((prevState) => ({
      ...prevState,
      ...changeset,
    }));
  };

  const handleChangeAverageTimeSpentQueryParams = (changeset) => {
    setAverageTimeSpentQueryParams((prevState) => ({
      ...prevState,
      ...changeset,
    }));
  };

  const renderLeftSection = () => {
    return (
      <Grid className={classes.leftSection}>
        {(user.role === 'GLOBAL USER' || user.role === 'GLOBAL ADMIN') && (
          <AutoComplete
            value={selectedInstitutions}
            onChange={(value) => setSelectedInstitutions(value)}
          />
        )}
        <Box mb={2} mt={2}>
          <LoadingView isLoading={isLoadingOverview}>
            <StatisticCards
              staffSize={overviewData?.employee_count || 0}
              averageTimeSpent={overviewData?.average_time_spent || 0}
              mostActiveDay={overviewData?.most_active_day?.toLowerCase() || ''}
            />
          </LoadingView>
        </Box>
        <Box width={1} mb={2}>
          <LoadingView isLoading={isLoadingTimeGraph}>
            <TimeGraph
              timeOfEntry={timeGraphQueryParams.filter_type}
              date={timeGraphQueryParams.date}
              onChangeTimeOfEntry={(filter_type) =>
                handleChangeTimeGraphQueryParams({ filter_type })
              }
              onChangeDate={(date) => handleChangeTimeGraphQueryParams({ date })}
              data={OverviewUtils.formatTimeGraphApiResponse(timeGraphData)}
            />
          </LoadingView>
        </Box>
        <Box width={1} mb={2}>
          <LoadingView isLoading={isLoadingDailyAttendance}>
            <DailyAttendance
              date={dailyAttendanceQueryParams.date}
              onChangeDate={(date) =>
                handleChangeDailyAttendanceQueryParams({ date })
              }
              data={OverviewUtils.formatDailyAttendanceApiResponse(
                dailyAttendanceData
              )}
            />
          </LoadingView>
        </Box>
        <Grid container spacing={1}>
          <Grid item md={7}>
            <LoadingView isLoading={isLoadingOverview}>
              <StaffDemography
                data={OverviewUtils.formatStaffDemographyApiResponse(
                  overviewData?.demography
                )}
              />
            </LoadingView>
          </Grid>
          <Grid item md={5}>
            <LoadingView isLoading={isLoadingOverview} fullHeight>
              <EmploymentStatus
                data={OverviewUtils.formatEmploymentStatusApiResponse(
                  overviewData?.employment_status
                )}
              />
            </LoadingView>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderRightSeection = () => {
    return (
      <Box className={classes.timeGraphSection}>
        <LoadingView
          isLoading={
            isLoadingAvgContractTypeTimeSpent || isLoadingAvgDepartmentTimeSpent
          }
        >
          <AverageTimeSpent
            date={timeGraphQueryParams.date[0]}
            timeSpent={averageTimeSpentQueryParams.timeSpent}
            onChangeTimeSpent={(timeSpent) =>
              handleChangeAverageTimeSpentQueryParams({ timeSpent })
            }
            data={{
              department: avgDepartmentTimeSpentData?.map((data) => ({
                name: (titleCase(data?.department) || 'Default') + ' - ' + data?.avg,
                value: data?.avg || 0,
              })),

              contractType: avgContractTypeTimeSpentData?.map((data) => ({
                name:
                  (titleCase(data?.contract_type) || 'Default') + ' - ' + data?.avg,
                value: data?.avg || 0,
              })),
            }}
          />
        </LoadingView>
      </Box>
    );
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={3}>
        {renderLeftSection()}
        {renderRightSeection()}
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  leftSection: {
    paddingTop: `${theme.spacing(7)}px !important`,
    width: '65%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  timeGraphSection: {
    backgroundColor: 'transparent',
    width: '30%',
    height: '100vh',
    position: 'fixed',
    padding: 20,
    right: 0,
    zIndex: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
