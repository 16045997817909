import React from 'react';
import Chart from 'react-apexcharts';
import { Paper, Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import Calendar from 'components/Calendar';

const DailyAttendance = ({ date, onChangeDate, data }) => {
  const classes = useStyles();
  const indicatorColor = data?.map((_item) => '#930100');

  const options = {
    title: {
      text: undefined,
    },
    plotOptions: {
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
    },
    colors: indicatorColor,
    chart: {
      type: 'bar',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: data?.map((item) => item.name),
    },
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: 'No attendance entry during this period',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
      },
    },
  };

  const series = [
    {
      name: 'Attendance',
      data: data?.map((item) => item.value),
    },
  ];

  const renderHeader = () => {
    return (
      <Box display="flex" justifyContent="space-between" className={classes.header}>
        <Typography color="textPrimary">Daily Attendance</Typography>
        <Box display="flex">
          <Calendar
            selectRange
            value={date || [new Date(), new Date()]}
            onChange={onChangeDate}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Paper>
      {renderHeader()}
      <Chart options={options} series={series} type="bar" height={350} />
    </Paper>
  );
};

const useStyles = makeStyles({
  header: {
    padding: `10px 15px`,
  },
});

DailyAttendance.propTypes = {
  dateRange: PropTypes.array,
  onChange: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

export default React.memo(DailyAttendance);
