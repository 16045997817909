/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ImageUploadIcon, FilePreviewIcon } from 'assets';

export default function FileUpload({ setData }) {
  const [uploadedFile, setUploadedFile] =useState(null)
  const classes = useStyles();
  
  const handleUpload = (fileData) => {
  setUploadedFile(fileData)
  setData(fileData);
};

return (
<Box>
  <Box>
    <Typography>
      Attach  <span>.DAT</span> File
    </Typography>
  </Box>
  <div className={classes.UploadContainer}>
    <input type="file" id="avatar" name="avatar" onChange={(e)=>handleUpload(e.target?.files[0])}
    className = "file-input"
    />
    <Box className="file-input-cover">
      {!!uploadedFile ?
      <FilePreviewIcon className="file-input-cover-icon" />:
      <ImageUploadIcon className="file-input-cover-icon" />
      }
      <Box component="span" mt={1} className="file-input-cover-text">
        {!!uploadedFile ? uploadedFile?.name : "Click here to drop files" }
      </Box>
    </Box>
  </div>
</Box>
);
}

const useStyles = makeStyles((theme) => ({
UploadContainer:{
position: "relative",
width: "100%",
height: "100%",
textAlign: "center",
border: "2px dashed #DFE3E8",
borderRadius:2,
margin: "14px 0",
"& .file-input":{
opacity: 0,
position: "absolute",
top: 0,
left: 0,
width: "100%",
height: "100%",
zIndex: 99,
},
"& .file-input-cover":{
position: "relative",
display: "flex",
flexDirection: "column",
alignItems: "center",
justifyContent: "center",
width: "100%",
height: "100%",
padding: "10%",
},
"& .file-input-cover-icon":{
height: 70,
width: 70
},
"& .file-input-cover-text":{
color: "#637381"
}
}
}));


FileUpload.propTypes = {
setData: PropTypes.func.isRequired,
};