import { useMutation } from 'react-query';
import { client } from 'utils/api-client';
import { useHistory } from 'react-router-dom';

export default function useUploadAttendance() {
  const history = useHistory();

  const apiRequest = async (values) => {
    try {
      let res = await client('attendance/upload/', { data: values });
      return res
    }
    catch(e){
      throw e
    }
  }

  return useMutation(apiRequest, {
    onSuccess: ()=>{
      history.push('/admin/attendance')
    }
  });
}
