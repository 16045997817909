/* eslint-disable camelcase */
import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

const updateCache = ({ education, cache, queryKey }) => {
  const queryData = cache.getQueryData(queryKey);
  const updatedData = {
    ...queryData,
    education: [education, ...queryData.education],
  };
  cache.setQueryData(queryKey, updatedData);
};

export default function usePostEducationData(staffId) {
  const cache = useQueryCache();
  return useMutation(
    (values: any) =>
      client(`employees/${staffId}/education-history/`, {
        data: values,
      }).then((res) => res),
    {
      onSuccess: ({ data }) => {
        updateCache({
          education: data,
          cache,
          queryKey: ['employee-data', staffId],
        });
      },
    }
  );
}
