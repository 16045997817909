import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { NavLink as ReactRouterLink } from 'react-router-dom';
import {
  AttendanceOverViewIcon,
  LogOutIcon,
  UsersIcon,
  // Logo,
  MenuCalendarIcon,
  AuditIcon,
  PrunedgeLogo,
} from 'assets';
import { useAuth } from 'context/auth-context';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    background: theme.palette.primary.main,
    height: '100vh',
    textAlign: 'center',
  },
  logo: {
    // padding: '2em',
    display: 'grid',
    placeItems: 'center',
    // marginLeft: 16,
  },
  link: {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none !important',
    paddingLeft: '2em',
    position: 'relative',
    marginTop: 18,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 16,
    '&:after': {
      position: 'absolute',
      content: 'fewfwfwe',
      right: '10%',
      top: 0,
      left: 0,
      height: '100%',
      backgroundColor: 'red',
    },
  },
  linkActive: {
    borderLeft: `3px solid`,
    backgroundColor: 'rgba(242, 242, 242, 0.5)',
    color: theme.palette.secondary.main,
    position: 'relative',
    // fontWeight: 'bold',
    '&:after': {
      position: 'absolute',
      content: 'qwdqwdqwx',
      top: 0,
      left: 0,
      right: '10%',
      width: '9px',
      height: '100%',
      backgroundColor: 'red',
    },
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  menuList: {
    marginTop: 60,
  },
  appShortName: {
    fontWeight: 700,
    // marginTop: -10,
    marginTop: -20,
    marginBottom: 10,
  },
  appName: {
    fontWeight: 700,
    lineHeight: 1,
  },
}));

export default function SideBar() {
  const classes = useStyles();
  const { logout } = useAuth();
  return (
    <div className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        width={1}
      >
        <Box>
          <Box className={classes.logo}>
            <PrunedgeLogo style={{ width: 173, height: 180 }} />
            {
              //<Logo style={{ width: 123, height: 150 }} />
            }
          </Box>
          <Typography
            color="secondary"
            align="center"
            variant="h5"
            className={classes.appShortName}
          >
            {/* OYO-PDMP */}
            BiTaMS
          </Typography>
          <Typography
            color="textPrimary"
            align="center"
            variant="body2"
            component="span"
            className={classes.appName}
          >
            {/* Personnel <br /> Data Management Platform */}
            Biometric <br /> Time & Attendance Management <br /> System
          </Typography>

          {/* <Box mt={12}> */}
          <Box mt={8}>
            <Link
              component={ReactRouterLink}
              className={classes.link}
              to="/admin/overview"
              activeClassName={classes.linkActive}
            >
              <AttendanceOverViewIcon className={classes.icon} />
              Overview
            </Link>
            <Link
              component={ReactRouterLink}
              className={classes.link}
              to="/admin/attendance"
              activeClassName={classes.linkActive}
            >
              <MenuCalendarIcon className={classes.icon} />
              Attendance
            </Link>
            <Link
              component={ReactRouterLink}
              className={classes.link}
              to="/admin/staffs"
              exact={false}
              activeClassName={classes.linkActive}
            >
              <AttendanceOverViewIcon className={classes.icon} />
              Personnel
            </Link>
            <Link
              component={ReactRouterLink}
              className={classes.link}
              to="/admin/users"
              activeClassName={classes.linkActive}
            >
              <UsersIcon className={classes.icon} />
              User Admin
            </Link>
            <Link
              component={ReactRouterLink}
              className={classes.link}
              to="/admin/audit"
              activeClassName={classes.linkActive}
            >
              <AuditIcon className={classes.icon} />
              Audit
            </Link>
          </Box>
        </Box>
        <List>
          <ListItem style={{ paddingLeft: 0 }}>
            <Link
              component={ReactRouterLink}
              className={classes.link}
              onClick={logout}
              to="/"
            >
              <LogOutIcon className={classes.icon} />
              Log Out
            </Link>
          </ListItem>
        </List>
      </Box>
    </div>
  );
}
