/* eslint-disable camelcase */
import * as React from 'react';
import Select from 'components/common/Select';
import Button from 'components/common/Button';
import Menu from 'components/Menu';
import Box from '@material-ui/core/Box';
import Table from 'components/Table';
import Avatar from 'components/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useMinistries from 'hooks/useMinistries';

import { titleCase } from 'utils/formatText';
import useUsers from 'hooks/useUsers';
import useInviteUser from 'hooks/useInviteUser';
import useDeleteUser from 'hooks/useDeleteUser';
import { useConfirm } from 'material-ui-confirm';
import { useToast } from 'context/toast-context';
import AddUserModal from './AddUserModal';
import { roles as userRoles } from 'utils/constants';
import { useAuth } from 'context/auth-context';

const columns = [
  {
    header: 'Staff ID',
    key: 'id',
  },
  {
    header: 'Institution',
    key: 'institution',
  },

  {
    header: 'Role',
    sort: true,
    key: 'role',
    sortDirection: 'asc',
  },
  {
    header: 'Action',
    key: 'action',
  },
];

// const userRoles = roles;

const renderIdColumn = ({ name, image }) => (
  <Box display="flex">
    <Avatar name={name} image={image} size="big" />
    <Box ml={2}>
      <Typography>{name}</Typography>
    </Box>
  </Box>
);

const renderActionButton = (deleteFunction, id) => {
  const menuActionItems = [
    {
      text: 'Delete',
      onItemClick: () => deleteFunction(id),
    },
  ];
  return (
    <Box display="flex" style={{ cursor: 'pointer' }}>
      <Menu menuItems={menuActionItems} />
    </Box>
  );
};

export default function ManageUsers() {
  const ministries = useMinistries();
  const [currentInstitution, setCurrentInstitution] = React.useState('all');
  const [currentRole, setCurrentRole] = React.useState('all');
  const { showToast } = useToast();
  const { user } = useAuth();

  const allInstitutions = ministries?.data
    ? [].concat(ministries?.data?.results)
    : [];

  const [page, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState('');

  const params = {
    institution: allInstitutions.find(({ id }) => id === currentInstitution)?.id,
    search: searchTerm,
    role: userRoles.find(({ id }) => id === currentRole)?.id,
    page_size: pageSize,
    page,
  };

  const confirm = useConfirm();

  const [deleteUser, { isLoading: deleting }] = useDeleteUser(params);

  const handleDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this account ?',
      confirmationButtonProps: { color: 'secondary', variant: 'contained' },
    })
      .then(() => {
        deleteUser(id);
      })
      .catch(() => {});
  };

  function createData({ id, name, image }, institution, role) {
    return {
      id: renderIdColumn({ name, image }),
      institution,
      role,
      action: renderActionButton(handleDelete, id),
    };
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const users = useUsers(params);

  const data = users?.data?.results.map(
    ({ id, firstname, lastname, staff_id, ministry, role, image }) =>
      createData(
        { id, staffId: staff_id, name: `${lastname} ${firstname}`, image },
        ministry?.name,
        titleCase(role)
      )
  );

  const createCsvData = users?.data?.results?.map(
    ({ firstname, lastname, ministry, role }) => ({
      firstname,
      lastname,
      institution: ministry?.name,
      role,
    })
  );

  const clist = createCsvData?.map((cdata) => Object.values(cdata));
  const csvData = [
    ['First Name', 'Last Name', 'Institution', 'Role'],
    ...(clist ?? []),
  ];

  const [inviteUser, { isLoading: sendingInvite, error, isError }] = useInviteUser(
    params
  );

  const handleInviteUser = (values) => {
    inviteUser(values, {
      onSuccess: () => {
        setOpen(false);
        showToast(true);
      },
    });
  };

  if (ministries.isLoading) return null;

  return (
    <Box pt={6}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <Box mr={1} style={{ width: 200 }}>
            <TextField
              variant="outlined"
              size="small"
              name="search"
              placeholder="Search Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className="search-icon" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mr={1} style={{ width: 400 }}>
            <Select
              placeholder="All Institutions"
              options={[{ id: 'all', name: 'All Institutions' }, ...allInstitutions]}
              onChange={(e) => setCurrentInstitution(e.target.value)}
              value={currentInstitution}
            />
          </Box>
          <Box style={{ width: 200 }}>
            <Select
              onChange={(e) => setCurrentRole(e.target.value)}
              value={currentRole}
              options={[
                {
                  id: 'all',
                  name: 'All Roles',
                },
                ...userRoles,
              ]}
              placeholder="All Roles"
            />
          </Box>
        </Box>
        {(user.role === 'LOCAL ADMIN' || user.role === 'GLOBAL ADMIN') && (
          <>
            <Box ml={1}>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                disableRipple
                size="medium"
                style={{ paddingLeft: 20, paddingRight: 20 }}
                onClick={handleClickOpen}
              >
                Add New User
              </Button>
            </Box>
          </>
        )}
      </Box>
      <Table
        columns={columns}
        data={data}
        isLoading={users?.isLoading}
        pagination={{
          total: users?.data?.total,
          totalPages: users?.data?.total_pages,
          currentPage: page,
          setCurrentPage,
          pageSize,
          setPageSize,
        }}
        csvData={csvData}
        csvTitle="Users"
      />
      <AddUserModal
        handleClose={handleClose}
        open={open}
        institutions={ministries?.data?.results}
        inviteUser={handleInviteUser}
        error={error}
        createCsvData={createCsvData}
        isLoading={sendingInvite}
        isError={isError}
      />
    </Box>
  );
}
