import { useQuery } from 'react-query';
import { client } from 'utils/api-client';
import { useNotification } from 'components/NotificationBanner';

export default function useFetchAuditLog(params) {
  const notification = useNotification();

  const { data, isLoading } = useQuery(
    ['audit-log', params],
    (_, fieldParams) =>
      client(`audit-log/`, { params: fieldParams }).then((res) => res),
    {
      onError: (error) => {
        notification.error({
          message: 'Error occurred',
          description: error.errors,
        });
      },
    }
  );
  return {
    data,
    isLoading,
  };
}
