import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'components/common/Select';
import DatePicker from 'components/common/DatePicker';
// import DateRange from 'components/common/DateRangePicker';

import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Button from 'components/common/Button';
import useMinistries from 'hooks/useMinistries';
import UploadModal from 'screens/UploadAttendance/FileUpload/UploadModal';
import { formateDate } from 'utils/dateUtils';
import { useForm } from 'react-hook-form';
import 'react-calendar/dist/Calendar.css';

import List from './List';
import { useAuth } from 'context/auth-context';

export default function StaffList() {
  const ministries = useMinistries();
  const [openModal, setOpenModal] = React.useState(false);

  const { push } = useHistory();

  const allInstitutions = ministries?.data
    ? [].concat(ministries?.data?.results)
    : [];

  const { control, watch } = useForm({
    defaultValues: {
      institution: 'All Institutions',
      date: null,
    },
  });
  const { date, institution } = watch(['date', 'institution']);

  const { user } = useAuth();

  return (
    <Box pt={5}>
      <Box
        display="flex"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <form>
          <Box display="flex">
            {/* <Box style={{ width: 400 }}> */}
            {(user.role === 'GLOBAL USER' || user.role === 'GLOBAL ADMIN') && (
              <Box style={{ width: 400 }}>
                <Select
                  name="institution"
                  control={control}
                  style={{ height: 40 }}
                  options={[
                    { id: 'All Institutions', name: 'All Institutions' },
                    ...allInstitutions,
                  ]}
                  value={institution}
                />
              </Box>
            )}
            <Box
              ml={
                user.role === 'GLOBAL USER' || user.role === 'GLOBAL ADMIN' ? 2 : 0
              }
            >
              <DatePicker
                placeholder="Current Date"
                name="date"
                control={control}
                value={date}
              />
            </Box>
          </Box>
        </form>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          disableRipple
          m={1}
          onClick={() => setOpenModal(true)}
        >
          <AddIcon /> Data Upload
        </Button>
      </Box>
      <List
        date={!!date ? new Date(date).toLocaleDateString('en-GB') : ''}
        institution={allInstitutions.find(({ id }) => id === institution)?.id}
      />
      <UploadModal open={openModal} handleClose={() => setOpenModal(false)} />
    </Box>
  );
}
