import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { client } from 'utils/api-client';

export default function useCreatePassword() {
  const history = useHistory();
  return useMutation(
    (data: any) =>
      client('auth/users/create-password/', {
        data,
      }).then((res) => res),
    {
      onSuccess: () => {
        history.push('/login');
      },
    }
  );
}
