import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { routes } from 'routes';
import { NotificationProvider } from 'components/NotificationBanner';

function App() {
  return (
    <div className="App">
      <NotificationProvider>
        <Switch>
          {routes.map(
            ({
              component: Component,
              path,
              layout: Layout,
              headerTitle,
              route: Route,
            }) => (
              <Route path={path} exact key={path}>
                <Layout headerTitle={headerTitle}>
                  <Component />
                </Layout>
              </Route>
            )
          )}
          <Redirect to="/admin/overview" />
        </Switch>
      </NotificationProvider>
    </div>
  );
}

export default App;
