/* eslint-disable camelcase */
import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

type DocumentInfo = {
  id: string;
  category: string;
};

type DocumentCategoryData = {
  category: string;
  data: Array<DocumentInfo>;
  count: number;
};

type DocumentQueryDataType = {
  data: Array<DocumentCategoryData>;
};

export default function useUploadEmployeeDocument(queryKey: [string, string]) {
  const [_, staffId] = queryKey;
  const cache = useQueryCache();

  const updateDocumentCache = (data) => {
    const { category } = data[0];
    const queryData: DocumentQueryDataType | undefined = cache.getQueryData(
      queryKey
    );

    if (!queryData) return;
    const updatedData = queryData.data?.map((item) => {
      if (item.category === category) {
        return {
          ...item,
          count: item.count + 1,
          data: [...item.data, data[0]],
        };
      }
      return item;
    });

    cache.setQueryData(queryKey, { ...queryData, data: updatedData });
  };
  return useMutation((values: any) =>
    client(`employees/${staffId}/documents/`, {
      data: values,
    }).then(({ data }) => {
      updateDocumentCache(data);
    })
  );
}
