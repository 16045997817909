
class NumberUtils {
  static formatShortForm(num, digits = 2) {
    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'Qd' },
      { value: 1e18, symbol: 'Qt' },
    ];
    // used to remove trailing zeroes
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i -= 1) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
  }

  static formatNumberUsingCommas = (number, lengthOfDecimal, lengthOfSections) => {
    var re =
      '\\d(?=(\\d{' + (lengthOfSections || 3) + '})+' + (lengthOfDecimal > 0 ? '\\.' : '$') + ')';
    return number?.toFixed(Math.max(0, ~~lengthOfDecimal)).replace(new RegExp(re, 'g'), '$&,');
  };
}

export default NumberUtils