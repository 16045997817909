import { useMutation } from 'react-query';
import { client } from 'utils/api-client';

export default function useVerifyUserToken() {
  return useMutation((data: any) =>
    client(`auth/users/verify-token/`, {
      data,
    }).then((res) => res)
  );
}
