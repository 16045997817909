/* eslint-disable camelcase */
import { useMutation, useQueryCache } from 'react-query';
import { client } from 'utils/api-client';

export function useAddRecordOfService(staffId: string) {
  const cache = useQueryCache();
  return useMutation(
    (values: any) =>
      client(`employees/${staffId}/service-history/`, {
        data: values,
      }).then((res) => res),
    {
      onSuccess: () => {
        cache.invalidateQueries(['employee-data', staffId]);
      },
    }
  );
}
