/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import useFetchEmployeeData from 'hooks/useFetchEmployeeData';
import Calendar from 'components/Calendar';
import { formateDate } from 'utils/dateUtils';
import Box from '@material-ui/core/Box';

import TableStats from 'components/Table/TableStats';
import useFetchStaffAttendance from 'hooks/useFetchStaffAttendance';

const columns = [
  {
    header: 'Date',
    key: 'date',
  },
  {
    header: 'First Check In',
    sort: true,
    key: 'timeIn',
    sortDirection: 'asc',
  },
  {
    header: 'Last Check in',
    sort: true,
    key: 'timeOut',
    sortDirection: 'asc',
  },
  {
    header: 'Estimated Time Spent',
    key: 'timeSpent',
  },
];

function createData(date, timeIn, timeOut, timeSpent) {
  return {
    date,
    timeIn,
    timeOut,
    timeSpent,
  };
}

export default function AttendanceList({ employeeId, name }) {
  const getTimeSpent = (timeIn = '0', timeOut = '0') => {
    const date = new Date('Nov 1,2020').toLocaleDateString({
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const dateTime1 = new Date(`${`${date} `}${timeIn}`);
    const dateTime2 = new Date(`${`${date} `}${timeOut}`);
    const diff = dateTime2 - dateTime1;
    return `${
      isNaN(new Date(diff).getHours() - 1) ? 0 : new Date(diff).getHours() - 1
    }.${isNaN(new Date(diff).getMinutes()) ? 0 : new Date(diff).getMinutes()} Hours`;
  };
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const { data, isLoading } = useFetchStaffAttendance({
    id: employeeId,
    page_size: pageSize,
    current_page: currentPage,
  });

  const pagination = {
    total_pages: data?.attendance?.total_pages,
    total: data?.attendance?.total,
    setCurrentPage,
    setPageSize,
    pageSize,
    currentPage,
  };

  const rows = data?.attendance?.results?.map(({ clock_in, clock_out, date }) =>
    createData(
      new Date(date).toLocaleDateString(),
      clock_in,
      clock_out,
      getTimeSpent(clock_in, clock_out)
    )
  );

  const createCsvData = data?.attendance?.results?.map(
    ({ clock_in, clock_out, date }) => ({
      date,
      clock_in,
      clock_out,
    })
  );

  const clist = createCsvData?.map((cdata) => Object.values(cdata));
  const csvData = [['Clock in time', 'Clock out time', 'Date'], ...(clist ?? [])];

  // const { data } = useFetchEmployeeData();
  const [selectedDate, setSelectedDate] = React.useState([null, new Date()]);

  const handleDateChange = (dateRange) => {
    if (dateRange[1]) {
      const currentDate = dateRange.map((date) =>
        formateDate(date).split('/').reverse().join('-')
      );
      setSelectedDate(currentDate);
    }
  };

  let currentListShowing = pagination?.total;

  if (pagination?.total / pagination?.pageSize > 1) {
    currentListShowing =
      pagination.pageSize * (pagination.currentPage - 1) + data?.length;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ background: '#FFFFFF', border: '1px solid #E0E0E0' }}
        mb={4}
      >
        <Calendar
          selectRange
          onChange={handleDateChange}
          value={selectedDate}
          light
        />
        <TableStats
          isLoading={isLoading}
          currentListShowing={currentListShowing}
          total={pagination?.total}
          title="Activity Details"
          csvData={csvData}
          csvTitle={name}
        />
      </Box>
      <Table
        columns={columns}
        data={rows}
        isLoading={isLoading}
        showTableStats={false}
        pagination={{
          total: pagination?.total,
          totalPages: pagination?.total_pages,
          currentPage: pagination.currentPage,
          setCurrentPage: pagination.setCurrentPage,
          pageSize: pagination.pageSize,
          setPageSize: pagination.setPageSize,
        }}
      />
    </>
  );
}

AttendanceList.propTypes = {
  employeeId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
