import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, makeStyles } from "@material-ui/core";

const LoadingView = ({ children, isLoading, fullHeight }) => {
  const classes = useStyles();
  const fullHeightClassName = fullHeight ? 'fullHeight' : '';

  return (
    <div className={`${classes.container} ${fullHeightClassName}`}>
      {children}
      {isLoading && (
        <div className={classes.overlay}>
          <CircularProgress color="secondary" size={20} thickness={3} />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    position: "relative",
    height: "auto",
    width: "100%",
    '&.fullHeight': {
      height: "100%"
    }
  },
  overlay: {
    position: "absolute",
    top: 0,
    height: "100%",
    maxHeight: "100vh",
    width: "100%",
    background: "rgba(255,255,255,0.4)",
    zIndex: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

LoadingView.propTypes = {
  isLoading: PropTypes.bool.isRequired, 
  fullHeight: PropTypes.bool.isRequired 
}

export default React.memo(LoadingView);
