/* eslint-disable camelcase */
import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { titleCase } from 'utils/formatText';
import useFetchEmployeeData from 'hooks/useFetchEmployeeData';

import { ReactComponent as PhoneIcon } from 'assets/svg/phone-icon.svg';
import { ReactComponent as MailIcon } from 'assets/svg/mail-icon.svg';
import { ReactComponent as MapLocationIcon } from 'assets/svg/map-location-icon.svg';
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar-icon.svg';
import { ReactComponent as PresentIcon } from 'assets/svg/present-icon.svg';
import { ReactComponent as CancelIcon } from 'assets/svg/cancel-icon.svg';
import { Tab, TabPanel, TabProvider, Tabs } from 'components/Tabs';
import StaffTimeActivity from 'components/StaffTimeActivity';
import profileAvatar from 'assets/img/profile-avatar.jpg';

import ActivityList from './ActivityList';
import ProfilePreview from './ProfilePreview';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '80vh',
    paddingTop: 44,
  },
  leftContent: {
    padding: 16,
    paddingTop: theme.spacing(4),
    height: '80vh',
  },
  rightContent: {
    padding: 16,
    paddingTop: theme.spacing(4),
    height: '80vh',
  },
  personAvatarContainer: {
    height: 120,
    width: 120,
    background: '#FFFFFF',
    display: 'grid',
    placeItems: 'center',
    marginBottom: '20px',
  },
  personAvatar: {
    height: 59,
    width: 56,
  },
  personalDetails: {
    marginRight: '14px',
  },
  iconBorder: {
    background: '#F8FAFB',
    border: '1px solid #CBD4DA',
    height: '33px',
    width: '33px',
    borderRadius: '50%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  percentageChange: {
    color: theme.palette.success.main,
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: theme.palette.success.main,
    },
    '&.error': {
      color: theme.palette.error.main,
      '& .MuiSvgIcon-root': {
        color: theme.palette.error.main,
      },
    },
  },
}));

export default function StaffList() {
  const classes = useStyles();
  const { id } = useParams();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const { data, isLoading } = useFetchEmployeeData(id);

  const history = useHistory();
  const passportPreview = data?.passport || profileAvatar;

  const convertSecondsToHours = (seconds) => {
    return (seconds / 3600).toFixed(1);
  };

  return (
    <Box pt={8}>
      <Grid
        direction="row"
        container
        spacing={1}
        justify="center"
        alignItems="center"
      >
        <Grid item container xs={6}>
          <Grid display="flex" item>
            <img
              src={passportPreview}
              alt="profile"
              className={classes.personAvatarContainer}
            />
          </Grid>
          <Grid item style={{ marginLeft: '20px' }}>
            {isLoading ? (
              <Typography>Loading...</Typography>
            ) : (
              <>
                <Typography
                  variant="h5"
                  style={{ color: '#4F4F4F', fontWeight: 'bold' }}
                >
                  {titleCase(`${data?.surname} ${data?.firstname}`)}
                </Typography>
                <Typography>
                  <PhoneIcon className={classes.personalDetails} />
                  {data?.phone_number}
                </Typography>
                <Typography>
                  <MailIcon className={classes.personalDetails} />
                  {data?.email_address?.toLowerCase()}
                </Typography>
                <Typography>
                  <MapLocationIcon className={classes.personalDetails} />
                  {titleCase(data?.permanent_address)}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="row" justifyContent="space-around">
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box className={classes.iconBorder}>
                <CalendarIcon />
              </Box>
              <Typography style={{ textAlign: 'center' }}>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  {data?.attendance_overview?.total_days}
                </span>{' '}
                <br />
                Total Days
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box className={classes.iconBorder}>
                <PresentIcon />
              </Box>
              <Typography style={{ textAlign: 'center' }}>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  {data?.attendance_overview?.present_days}
                </span>{' '}
                <br /> Present
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box className={classes.iconBorder}>
                <CancelIcon />
              </Box>
              <Typography style={{ textAlign: 'center' }}>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  {data?.attendance_overview?.absent_days}
                </span>{' '}
                <br /> Absent
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid xs={2}>
          <Button
            onClick={() => history.push(`/admin/staffs/${id}/edit`)}
            color="secondary"
            variant="outlined"
          >
            Edit Profile
          </Button>
        </Grid>
      </Grid>
      <TabProvider>
        <Tabs
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="icon label tabs example"
        >
          {/* <Tab label="Activity" /> */}
          <Tab label="TimeStamp" />
          <Tab label="Profile" />
        </Tabs>
        {/* <TabPanel>
          <Grid container>
            <Grid item md={4}>
              <StaffTimeActivity
                title="Average  Time Spent"
                icon="clock"
                subtitle={`${convertSecondsToHours(
                  data?.attendance_overview?.average_time_spent || 0
                )} Hours`}
                // bottomLeftElement={() => {
                //   let percentageChange = data?.attendance_overview?.avg_percentage_change;

                //   return (
                //     <>
                //       <Typography>Percentage change</Typography>
                //       <Typography className={`${classes.percentageChange} ${percentageChange > 0 ? "success" : "error"}`} component="span">
                //         {percentageChange?.toFixed(2)}%
                //         {percentageChange > 0 ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                //       </Typography>
                //     </>
                //   )
                // }}
              />
              <StaffTimeActivity
                title="Most Active Day"
                icon="calendar"
                subtitle={data?.attendance_overview?.most_active_day || '---'}
                // bottomLeftElement={(
                //   <>
                //     <Typography>Previously</Typography>
                //     <Typography>Wednesday</Typography>
                //   </>
                // )}
              />
            </Grid>
            <Grid item md={8} />
          </Grid>
        </TabPanel> */}
        <TabPanel>
          <ActivityList
            employeeId={id}
            name={titleCase(`${data?.surname} ${data?.firstname}`)}
          />
        </TabPanel>
        <TabPanel>
          <ProfilePreview employeeId={id} />
        </TabPanel>
      </TabProvider>
    </Box>
  );
}
