import React, { useState } from "react";
import { Snackbar, makeStyles } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { INotificationBannerProps } from "./typeDefinition";
import { usePrevious } from "hooks/usePrevious";
import { useUpdateEffect } from "hooks/useUpdateEffect";

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const DefaultNotificationView: React.FC<INotificationBannerProps> = ({
  style,
  message,
  description,
  id,
  duration = "long",
}) => {
  const [isBannerHidden, setIsBannerHidden] = useState<boolean>(true);
  const queueRef = React.useRef<INotificationBannerProps[]>([]);
  const [messageInfo, setMessageInfo] = React.useState<
    INotificationBannerProps | undefined
  >(undefined);
  const previousId = usePrevious(id);
  const classes = useStyles();

  useUpdateEffect(() => {
    if (id !== previousId) {
      queueRef.current.push({
        style,
        message,
        description,
        id,
        duration,
      });
    }

    if (isBannerHidden === false) {
      setIsBannerHidden(true);
    } else {
      processQueue();
    }
  }, [id]);

  const processQueue = () => {
    if (queueRef.current.length > 0) {
      setMessageInfo(queueRef.current.shift());
      setIsBannerHidden(false);
    }
  };

  const handleCloseBanner = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setIsBannerHidden(true);
  };

  const handleExited = () => {
    processQueue();
  };

  let snackbarDuration = messageInfo
    ? messageInfo.duration === "short"
      ? 1000
      : 5000
    : 1000;
  return (
    <Snackbar
      key={messageInfo ? messageInfo.id : undefined}
      onExited={handleExited}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={isBannerHidden === false}
      autoHideDuration={snackbarDuration}
      onClose={handleCloseBanner}
      className={classes.container}
    >
      {messageInfo && (
        <Alert onClose={handleCloseBanner} severity={messageInfo.style}>
          {messageInfo.message && (
            <p className="title">
              {messageInfo.message}
              {messageInfo.style === "success" && "!"}
            </p>
          )}
          {messageInfo.description && (
            <p dangerouslySetInnerHTML={{ __html: messageInfo.description }} />
          )}
        </Alert>
      )}
    </Snackbar>
  );
};

const useStyles = makeStyles({
  container: {
    "& p": {
      margin: 0,
    },
    "& p.title": {
      fontWeight: 600,
    },
  },
});

export default React.memo(DefaultNotificationView);
