/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import useAttendanceList from 'hooks/useFetchAllStaff';

// components
import Box from '@material-ui/core/Box';
import Table from 'components/Table';
import Avatar from 'components/Avatar';
import Typography from '@material-ui/core/Typography';

export default function StaffList({
  date: dateParam,
  institution: currentInstitution,
}) {
  const [page, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const history = useHistory();
  const { data, isLoading } = useAttendanceList({
    employee__ministry:
      currentInstitution === 'All Institutions' ? undefined : currentInstitution,
    page,
    page_size: pageSize,
    date: dateParam, // dateParam === '1970-01-01' ? undefined :
  });
  const renderIdColumn = ({ id, name, staffId, image }) => {
    return (
      <Box display="flex">
        <Avatar name={name} image={image} size="big" />
        <Box ml={2}>
          {staffId}
          <Typography>{name}</Typography>
        </Box>
      </Box>
    );
  };

  const list = data?.results?.map(
    ({ employee, staff_id, date, clock_in, clock_out }) => ({
      id: employee?.id,
      name: `${employee?.surname} ${employee?.firstname}`,
      staffId: staff_id,
      image: employee?.image,

      institution: employee?.ministry_data?.name,
      date: new Date(date).toLocaleDateString('en-GB'),
      clock_in,
      clock_out,
    })
  );

  const createCsvData = data?.results?.map(
    ({ employee, staff_id, clock_in, clock_out, date }) => ({
      staffId: staff_id,
      institution: employee?.ministry_data?.name,
      clock_in,
      clock_out,
      date,
    })
  );

  const clist = createCsvData?.map((cdata) => Object.values(cdata));
  const csvData = [
    ['Staff ID', 'Institution', 'Clock in time', 'Clock out time', 'Date'],
    ...(clist ?? []),
  ];

  const handleRowClick = (row) => {
    if (row.id) history.push(`/admin/staffs/${row.id}`);
  };

  const columns = [
    {
      header: 'Staff ID',
      dataIndex: 'id',
      render: renderIdColumn,
    },
    {
      header: 'Institution',
      key: 'institution',
    },
    {
      header: 'Date',
      key: 'date',
    },
    {
      header: 'First Check In',
      sort: true,
      key: 'clock_in',
      sortDirection: 'asc',
    },
    {
      header: 'Last Check in',
      sort: true,
      key: 'clock_out',
      sortDirection: 'asc',
    },
  ];

  return (
    <Table
      columns={columns}
      data={list}
      isLoading={isLoading}
      pagination={{
        total: data?.total,
        totalPages: data?.total_pages,
        currentPage: page,
        setCurrentPage,
        pageSize,
        setPageSize,
      }}
      csvData={csvData}
      csvTitle="Attendance"
      onRowItemClick={handleRowClick}
    />
  );
}

StaffList.defaultProps = {
  date: '',
  institution: '',
};
