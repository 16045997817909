import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  CircularProgress,
  Typography,
  TextField,
  makeStyles,
  Grid,
} from '@material-ui/core';

import useMinistries from 'hooks/useMinistries';

const Autocomplete = ({ value, onChange }) => {
  const ministries = useMinistries();
  const classes = useStyles();

  const options = useMemo(() => {
    if (ministries?.data) return ministries?.data?.results;

    return [];
  }, [ministries?.data]);

  const selectedInstitutionsIds = useMemo(() => {
    return value?.map((item) => item.id) || [];
  }, [value]);

  const handleAddTag = (_event, newValue) => {
    let isAvailable = value.find((v) => v.id === newValue?.id);

    if (!isAvailable && newValue) {
      onChange([...value, newValue]);
    }
  };

  const handleRemoveTag = (item) => () => {
    const newValue = value.filter((v) => v.id !== item.id);
    onChange(newValue);
  };

  const renderTags = () => {
    return (
      <Grid container spacing={1} className={classes.tags}>
        {value.map((item) => (
          <Grid item key={item} className="tag">
            <Box display="flex" alignItems="center">
              <Typography variant="caption">{item.name}</Typography>
              <CloseIcon onClick={handleRemoveTag(item)} className="close-icon" />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <MuiAutocomplete
        id="search-institution"
        options={options}
        getOptionLabel={(option) => option.name}
        onChange={handleAddTag}
        value={{}}
        getOptionSelected={(option) =>
          selectedInstitutionsIds.indexOf(option.id) !== -1
        }
        loading={ministries.isLoading}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Institution"
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {ministries.isLoading ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              startAdornment: <SearchIcon className="search-icon" />,
            }}
          />
        )}
      />
      {renderTags()}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .search-icon': {
      color: theme.palette.secondary.text,
    },

    '& .MuiFormControl-fullWidth': {
      background: '#fff',
    },
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > div.tag': {
      '&>div:first-child': {
        padding: `2px 10px`,
        borderRadius: 10,
        color: '#fff',
        background: '#828282',
      },

      '& .close-icon': {
        fontSize: 12,
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
      },
    },
  },
}));

Autocomplete.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
};

export default React.memo(Autocomplete);
