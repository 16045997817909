import React from 'react';
import Box from '@material-ui/core/Box';
import Button from 'components/common/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    fontStyle: 'normal',
    background: '#FAFBFD',
    padding: '24px',
    border: '1px solid #C9D4DB',
    borderRadius: '3px',
  },
  description: {
    color: '#828282',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
  },
  button: {
    color: '#930100',
    fontSize: '14px',
  },
}));

interface EmptyStateProps {
  title: string;
  message: string;
  buttonTitle: string;
  onClick: Function;
}

export default function EmptyState(props: EmptyStateProps) {
  const { title, message, buttonTitle, onClick } = props;
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box>
        <Typography>{title}</Typography>
      </Box>
      <Box>
        <Typography className={classes.description}>{message}</Typography>
      </Box>
      <Box>
        <Button className={classes.button} onClick={onClick}>
          {buttonTitle}
        </Button>
      </Box>
    </Box>
  );
}
