import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// components
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DocumentUploadForm from 'components/DocumentUploadForm';
import { useConfirm } from 'material-ui-confirm';

// fixtures
import documentCategories from 'fixtures/document-categories';

// hooks
import useFetchEmployeeDocuments from 'hooks/useFetchEmployeeDocuments';
import useUploadEmployeeDocument from 'hooks/useUploadEmployeeDocument';
import useDeleteDocument from 'hooks/useDeleteDocument';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    fontStyle: 'normal',
  },
  personAvatarContainer: {
    height: 145,
    width: 145,
    background: '#FFFFFF',
    display: 'grid',
    placeItems: 'center',
    marginBottom: '20px',
  },
  formGroup: {
    margin: '15px 0',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#4F4F4F',
  },
  educationIcon: {
    width: '60px',
    height: '60px',
    background: '#FAFBFD',
    display: 'grid',
    placeItems: 'center',
  },
  editButton: {
    display: 'flex',
    background: '#F2F2F2',
    height: '29px',
    width: '29px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '20px',
    cursor: 'pointer',
  },
  documentSection: {
    background: '#FFFFFF',
    border: '1px solid #C9D4DB',
  },
  documentSectionHeader: {
    borderBottom: '1px solid #C9D4DB',
    padding: '10px 8px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  documentPreview: {
    width: '279px',
    height: '65px',
    background: '#FFFFFF',
    border: '1px solid #C9D4DB',
    borderRadius: '3px',
    padding: '1px 0px',
    marginRight: '15px',
  },
}));

type routeParams = {
  id: string;
};

type Document = {
  category: string;
  data: Array<string>;
  count: number;
};

export default function Documents() {
  const classes = useStyles();
  const { id }: routeParams = useParams();
  const queryKey: [string, string] = ['employee-documents', id];

  const [
    uploadDocuments,
    { isLoading: uploadingFile, isSuccess },
  ] = useUploadEmployeeDocument(queryKey);
  const { data: employeeData, isLoading: loadingDocs } = useFetchEmployeeDocuments(
    queryKey
  );

  const history = useHistory();

  const onSubmit = (values) => {
    const formData = new FormData();
    const { category, files } = values;
    formData.append('category', category);
    Array.from(files).forEach((file: any) => {
      formData.append('documents', file);
    });
    uploadDocuments(formData);
  };

  const confirm = useConfirm();

  const [deleteDocument, { isLoading: deleting }] = useDeleteDocument(queryKey);

  const handleDelete = (documentInfo: { id: string; category: string }) => {
    confirm({
      title: 'Are you sure you want to delete this?',
      confirmationButtonProps: { color: 'secondary', variant: 'contained' },
    })
      .then(() => {
        deleteDocument(documentInfo);
      })
      .catch(() => {});
  };

  if (loadingDocs) return null;

  return (
    <div className={classes.root}>
      <Box m={4}>
        <Typography style={{ marginBottom: '24px' }}>Upload Documents</Typography>
        {documentCategories.map((category) => (
          <DocumentUploadForm
            onSubmit={onSubmit}
            key={category}
            document={employeeData?.data?.find(
              (doc: Document) => doc.category === category
            )}
            uploadingFile={uploadingFile}
            uploadComplete={isSuccess}
            handleDelete={handleDelete}
          />
        ))}
      </Box>
      <Box display="flex" justifyContent="flex-end" m={4} mr={1}>
        <Button
          onClick={() => history.goBack()}
          variant="outlined"
          style={{ marginRight: '24px' }}
        >
          Cancel
        </Button>
      </Box>
    </div>
  );
}
