import React from 'react';
import Chart from 'react-apexcharts';
import { Paper, Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from "prop-types";

import NumberUtils from "utils/NumberUtils";

const StaffDemography = ({data}) => {
  const classes = useStyles();

  const options = {
    title: {
      text: undefined
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
        borderRadius: 7,
        radiusOnLastStackedBar: false
      },
    },
    colors: ['#930100', '#93006E'],
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      stacked: true,
      type: 'bar',
    },
    stroke: {
      width: 1,
      colors: ["#fff"]
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return val
        }
      },
      y: {
        formatter: function (val) {
          return Math.abs(val)
        }
      }
    },
    xaxis: {
      categories: data?.map(item => item.name),
      title: {
        text: 'Gender'
      },
      labels: {
        formatter: function (value) {
          return NumberUtils.formatShortForm(Math.abs(value), 1);
        },
      }
    },
    yaxis: {
      min: -(Math.max(...data?.map(item => item.male)) + 50),
      max: Math.max(...data?.map(item => item.female)) + 50,
    },
  }

  const series = [
    {
      name: 'Males',
      data: data?.map(item => -item.male)
    },
    {
      name: 'Females',
      data: data?.map(item => item.female)
    }
  ]

  const renderHeader = () => {
    return (
      <Box display="flex" justifyContent="space-between" className={classes.header}>
        <Typography color="textPrimary">Staff Demography</Typography>
      </Box>
    )
  }

  return (
    <Paper style={{width: "100%"}}>
      {renderHeader()}
      <Chart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </Paper>
  )
}

const useStyles = makeStyles({
  header: {
    padding: `10px 15px`,
  }
})

StaffDemography.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    male: PropTypes.number.isRequired,
    female: PropTypes.number.isRequired,
  }))
}

export default React.memo(StaffDemography);