/* eslint-disable camelcase */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// components
import Grid from '@material-ui/core/Grid';

import { Button, Divider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import EmptyState from 'components/EmptyState';
import { EducationIcon, EditIcon, PlusIcon, ArrowUpIcon, DeleteIcon } from 'assets';

import useDeleteRecordOfService from 'hooks/useDeleteRecordOfService';
import { useConfirm } from 'material-ui-confirm';
import AddRecordOfServiceModal from './AddRecordOfServiceModal';

const formateDate = (date: string | Date): string => {
  return new Date(date).toLocaleDateString();
};

// hooks
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontStyle: 'normal',
  },
  educationIcon: {
    width: '60px',
    height: '60px',
    background: '#FAFBFD',
    display: 'grid',
    placeItems: 'center',
  },
  editButton: {
    display: 'flex',
    background: '#F2F2F2',
    height: '29px',
    width: '29px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '20px',
    cursor: 'pointer',
  },
  incrementBadge: {
    background: '#27AE60',
    borderRadius: '12px',
    color: '#FFF',
    marginLeft: '10px',
    padding: '4px',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: '15px',
  },
  dateText: {
    // width: '280px',
    border: '1px solid #E0E0E0',
    background: '#FFFFFF',
    borderRadius: '16px',
    fontSize: '12px',
    padding: '2px 10px',
    marginTop: '12px',
    marginRight: '10px',
  },
}));

type routeParams = {
  id: string;
};

type RecordHistory = {
  id: string;
  designation: string;
  date_of_appointment: string | Date | null;
  salary_scale: string;
  increment_scale: string;
  increment_date: string | Date | null;
  annual_leave_start_date: string | Date;
  annual_leave_end_date: string | Date;
  casual_leave_start_date: string | Date;
  casual_leave_end_date: string | Date;
  special_leave_start_date: string | Date;
  special_leave_end_date: string | Date;
  sick_leave_start_date: string | Date;
  sick_leave_end_date: string | Date;
};

interface FormProps {
  serviceHistory: Array<RecordHistory>;
}

export default function EducationDataForm(props: FormProps) {
  const { serviceHistory } = props;
  const classes = useStyles();
  const { id }: routeParams = useParams();
  const [open, setOpen] = React.useState<boolean>(false);

  const [currentIndex, setIndex] = React.useState<number | null>(null);

  const history = useHistory();

  const handleEdit = (index: number) => {
    setIndex(index);
    setOpen(true);
  };

  const confirm = useConfirm();

  const [deleteRecord, { isLoading: deleting }] = useDeleteRecordOfService(id);

  const handleDelete = (recordId: string) => {
    confirm({
      title: 'Are you sure you want to delete this?',
      confirmationButtonProps: { color: 'secondary', variant: 'contained' },
    })
      .then(() => {
        deleteRecord(recordId);
      })
      .catch(() => {});
  };

  return (
    <div className={classes.root}>
      <Box m={4}>
        <>
          {serviceHistory?.length ? (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '24px',
                  marginBottom: '12px',
                }}
              >
                <Button
                  onClick={() => {
                    setOpen(true);
                    setIndex(null);
                  }}
                >
                  <PlusIcon />
                </Button>
              </div>

              {serviceHistory?.map(
                (
                  {
                    id: serviceId,
                    designation,
                    salary_scale,
                    increment_scale,
                    date_of_appointment,
                    increment_date,
                    annual_leave_end_date,
                    annual_leave_start_date,
                    casual_leave_end_date,
                    casual_leave_start_date,
                    sick_leave_start_date,
                    sick_leave_end_date,
                    special_leave_end_date,
                    special_leave_start_date,
                  },
                  index
                ) => (
                  <Grid container spacing={2}>
                    <Grid item xs={1}>
                      <Box className={classes.educationIcon}>
                        <EducationIcon />
                      </Box>
                    </Grid>
                    <Grid container item xs={11}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        flexDirection="row"
                        width={1}
                      >
                        <Box>
                          <Box alignItems="center">
                            <Typography variant="h5">{designation}</Typography>
                            <Typography component="span">
                              {salary_scale
                                .replace(/\s/g, '')
                                ?.split('-')
                                ?.map((scale: string) => `N${scale}`)
                                .join(' - ')}
                            </Typography>
                            <Typography
                              component="span"
                              className={classes.incrementBadge}
                            >
                              {increment_scale}
                              %
                              <ArrowUpIcon style={{ marginLeft: '4px' }} />
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            justifyContent="space-between"
                          >
                            <Box className={classes.dateText}>
                              <Typography component="span" color="textSecondary">
                                Date of Appointment:
                              </Typography>
                              <Typography component="span">
                                {' '}
                                {date_of_appointment
                                  ? formateDate(date_of_appointment)
                                  : ' N/A'}
                              </Typography>
                            </Box>

                            <Box className={classes.dateText}>
                              <Typography component="span" color="textSecondary">
                                Annual Leave:
                              </Typography>
                              <Typography component="span">
                                {' '}
                                {annual_leave_start_date && annual_leave_end_date
                                  ? `${formateDate(
                                      annual_leave_start_date
                                    )} - ${formateDate(annual_leave_end_date)}`
                                  : ' N/A'}
                              </Typography>
                            </Box>
                            <Box className={classes.dateText}>
                              <Typography component="span" color="textSecondary">
                                Casual Leave:
                              </Typography>
                              <Typography component="span">
                                {' '}
                                {casual_leave_start_date && casual_leave_end_date
                                  ? `${formateDate(
                                      casual_leave_start_date
                                    )} - ${formateDate(casual_leave_end_date)}`
                                  : ' N/A'}
                              </Typography>
                            </Box>
                            <Box className={classes.dateText}>
                              <Typography component="span" color="textSecondary">
                                Date of Salary Increment:
                              </Typography>
                              <Typography component="span">
                                {' '}
                                {increment_date
                                  ? formateDate(increment_date)
                                  : ' N/A'}
                              </Typography>
                            </Box>
                            <Box className={classes.dateText}>
                              <Typography component="span" color="textSecondary">
                                Sick Leave:
                              </Typography>
                              <Typography component="span">
                                {' '}
                                {sick_leave_start_date && sick_leave_end_date
                                  ? `${formateDate(
                                      sick_leave_start_date
                                    )} - ${formateDate(sick_leave_end_date)}`
                                  : ' N/A'}
                              </Typography>
                            </Box>
                            <Box className={classes.dateText}>
                              <Typography component="span" color="textSecondary">
                                Special Leave:
                              </Typography>
                              <Typography component="span">
                                {' '}
                                {special_leave_start_date && special_leave_end_date
                                  ? `${formateDate(
                                      special_leave_start_date
                                    )} - ${formateDate(special_leave_end_date)}`
                                  : ' N/A'}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box display="flex">
                          <div className={classes.editButton}>
                            <EditIcon onClick={() => handleEdit(index)} />
                          </div>
                          <div className={classes.editButton}>
                            <DeleteIcon onClick={() => handleDelete(serviceId)} />
                          </div>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          width: '100%',
                          paddingRight: '24px',
                          marginBottom: '24px',
                          marginTop: '8px',
                        }}
                      >
                        <Divider />
                      </Box>
                    </Grid>
                  </Grid>
                )
              )}
            </>
          ) : (
            <EmptyState
              title="Empty"
              message="This user currently has no institution registered."
              buttonTitle="Click here to add"
              onClick={() => setOpen(true)}
            />
          )}
        </>

        <Box display="flex" justifyContent="flex-end" m={4} mr={1}>
          <Button
            onClick={() => history.goBack()}
            variant="outlined"
            style={{ marginRight: '16px' }}
          >
            Cancel
          </Button>
        </Box>
        {open && (
          <AddRecordOfServiceModal
            open={open}
            closeModal={() => setOpen(false)}
            recordsOfService={
              currentIndex !== null ? serviceHistory[currentIndex] : {}
            }
          />
        )}
      </Box>
    </div>
  );
}
