import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  typography: {
    button: {
      fontSize: '1rem',
      textTransform: 'none',
    },
    fontFamily: [
      'Nunito Sans',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      muted: '#A6ABB8',
      lightText: '#948F8F',
      text: '#000'
    },
    secondary: {
      main: '#930100',
      text: "#4F4F4F"
    },
  },
  overrides: {
    MuiAvatar: {
      root: {
        fontSize: '1em',
      },
      colorDefault: {
        backgroundColor: '#007BFC',
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 16,
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'start',
        marginBottom: 48,
      },
    },
    MuiTableCell: {
      root: {
        padding: 8,
      },
    },
    MuiFormHelperText: {
      root: {
        display: 'none',
      },
    },
    MuiTableRow: {
      hover: {
        '&:hover': {
          backgroundColor: 'rgba(147, 1, 0, 0.05) !important',
        },
      },
    },
    MuiTypography:{
      colorTextPrimary: {
        color: "#000"
      },
      colorTextSecondary: {
        color: "#4F4F4F"
      }
    },
    MuiPaper:{
      elevation1: {
        boxShadow: "0px 1px 1px rgba(201, 212, 219, 0.6)"
      }
    }
  },
});
