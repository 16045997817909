/* eslint-disable react/prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Avatar from 'components/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from 'context/auth-context';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: 65,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarLeftContent: {
    display: 'flex',
    alignItems: 'center',
  },
  appBarRightContent: {
    display: 'flex',
    alignItems: 'center',
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    fontSize: '16px',
    fontWeight: '600',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    display: 'flex',
    justifyContent: 'space-between',
  },
  appBarSpacer: theme.mixins.toolbar,
  avatar: {
    background: theme.palette.secondary.main,
    height: 23,
    width: 23,
    fontSize: 12,
  },
  boldText: {
    fontWeight: 700,
    color: '#930100',
  },
}));

export default function NavBar({ open, headerTitle }) {
  const classes = useStyles();
  const { user } = useAuth();
  const { state } = useLocation();
  const [breadCrumbsData, setBreadCrumbsData] = React.useState([
    { name: headerTitle },
  ]);

  React.useEffect(() => {
    if (state?.breadCrumbData) {
      setBreadCrumbsData((prev) => [...prev, ...state.breadCrumbData]);
    }
  }, [state?.breadCrumbData]);

  const history = useHistory();

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, classes.appBarShift)}
      color="default"
      variant="outlined"
    >
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Breadcrumbs>
              {breadCrumbsData.map(({ name }, index) => (
                <div key={name}>
                  {index === breadCrumbsData.length - 1 ? (
                    <Typography
                      component="h1"
                      variant="h6"
                      className={classes.boldText}
                    >
                      {name}
                    </Typography>
                  ) : (
                    <Button color="inherit" onClick={() => history.goBack()}>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="textPrimary"
                        className={classes.boldText}
                      >
                        {name}
                      </Typography>
                    </Button>
                  )}
                </div>
              ))}
            </Breadcrumbs>
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <Box m={1}>
            <Typography
              component="h1"
              variant="h6"
              color="textSecondary"
              noWrap
              className={classes.title}
            >
              {user?.fullname}
            </Typography>
          </Box>
          <Avatar name={user?.fullname} size="small" image={user.image} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

NavBar.propTypes = {
  open: PropTypes.bool.isRequired,
  // headerTitle: PropTypes.string,
};

NavBar.defaultProps = {};
