/* eslint-disable camelcase */
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Table from 'components/Table';
import Avatar from 'components/Avatar';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import { downloadCSV } from 'utils/csvDownload';
import { makeStyles } from '@material-ui/core/styles';
import useUploadAttendance from 'hooks/useUploadAttendance';

const columns = [
  {
    header: 'Staff ID',
    key: 'id',
  },
  {
    header: 'Institution',
    key: 'institution',
  },

  {
    header: 'First Check In',
    sort: true,
    key: 'clockIn',
    sortDirection: 'asc',
  },
  {
    header: 'Last Check in',
    sort: true,
    key: 'clockOut',
    sortDirection: 'asc',
  },
  {
    header: 'Date',
    key: 'date',
  },
];

const CSVHeader = [
  'S/N',
  'Staff ID',
  'Staff Name',
  'Institution',
  'Clock in time',
  'Clock out time',
  'Date',
  'Error',
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: '82px',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing(8),
  },
  appBarSpacer: {
    paddingTop: '100px',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
}));

export default function UploadPreview() {
  const { state } = useLocation();

  const [page, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const [upload, { isLoading }] = useUploadAttendance();
  const history = useHistory();

  const renderIdColumn = (id, name, staffId) => (
    <Box display="flex">
      <Avatar name={name}>AA</Avatar>
      <Box ml={2}>
        <Link to={`/admin/staffs/${id}`} color="secondary">
          {staffId}
        </Link>
        <Typography>{name}</Typography>
      </Box>
    </Box>
  );

  function createData({ id, name, staff_id }, institution, clockIn, clockOut, date) {
    return {
      id: renderIdColumn(id, name, staff_id),
      institution,
      clockIn,
      clockOut,
      date,
    };
  }

  const total = state?.data.length;
  const totalPages = Math.ceil(total / pageSize);
  const pageStart = pageSize * (page - 1);
  const pageEnd = pageStart + pageSize;

  const list = state?.data
    ?.slice(pageStart, pageEnd)
    ?.map(({ name, institution, staff_id, clock_in, clock_out, date }) =>
      createData(
        {
          id: 'aadasdasd',
          staff_id,
          name,
        },
        institution,
        clock_in,
        clock_out,
        date
      )
    );

  const classes = useStyles();
  const hasInvalidData = state?.error.data.length > 0;

  const handleUpload = () => {
    if (hasInvalidData) {
      const invalidData = state?.error.data.map(
        (
          { name, institution, staff_id, clock_in, clock_out, date, error },
          index
        ) => [
          index + 1,
          staff_id,
          name,
          institution,
          clock_in,
          clock_out,
          date,
          error,
        ]
      );
      downloadCSV('Invalid Records', [CSVHeader, ...invalidData]);
    }
    if (state?.data?.length) {
      upload({ attendance: state?.data });
    }
  };

  return (
    <Box p={4}>
      <AppBar
        color="primary light"
        variant="outlined"
        className={clsx(classes.appBar, classes.appBarShift)}
      >
        <Typography variant="h5">Preview</Typography>
      </AppBar>
      <div className={classes.appBarSpacer} />
      {hasInvalidData && (
        <Box style={{ marginBottom: '16px', marginTop: '24px' }}>
          <Typography
            component="span"
            color="secondary"
            style={{ fontSize: 16, lineHeight: '15px' }}
          >
            {state?.error.data.length} record(s) of {state?.total} are invalid and
            will fail to upload.
          </Typography>
          <Typography style={{ fontSize: 16, lineHeight: '15px' }} component="span">
            Click on Proceed means you choose to upload {state?.data?.length} valid
            record(s) and download the invalid records automatically.
          </Typography>
        </Box>
      )}
      <Table
        columns={columns}
        data={list}
        showTableStats={false}
        pagination={{
          total: list?.length,
          totalPages,
          currentPage: page,
          setCurrentPage,
          pageSize,
          setPageSize,
        }}
      />
      <Box display="flex" justifyContent="flex-end" mt={4}>
        <Button
          variant="contained"
          color="secondary"
          disabled={isLoading}
          type="button"
          onClick={handleUpload}
          style={{ marginRight: '10px' }}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Proceed'}
        </Button>
        <Button onClick={() => history.goBack()} variant="outlined">
          Close
        </Button>
      </Box>
    </Box>
  );
}
