import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// components
import Grid from '@material-ui/core/Grid';

import { Button, Divider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import EmptyState from 'components/EmptyState';
import { EducationIcon, EditIcon, PlusIcon, DeleteIcon } from 'assets';

import useDeleteEducationData from 'hooks/useDeleteEducationData';
import { useConfirm } from 'material-ui-confirm';
import AddEducationModal from './AddEducationModal';

// hooks
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontStyle: 'normal',
  },
  personAvatarContainer: {
    height: 145,
    width: 145,
    background: '#FFFFFF',
    display: 'grid',
    placeItems: 'center',
    marginBottom: '20px',
  },
  formGroup: {
    margin: '15px 0',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#4F4F4F',
  },
  educationIcon: {
    width: '60px',
    height: '60px',
    background: '#FAFBFD',
    display: 'grid',
    placeItems: 'center',
  },
  editButton: {
    display: 'flex',
    background: '#F2F2F2',
    height: '29px',
    width: '29px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '20px',
    cursor: 'pointer',
  },
}));

type routeParams = {
  id: string;
};

interface FormProps {
  data: any;
}

export default function EducationDataForm(props: FormProps) {
  const { data } = props;
  const classes = useStyles();
  const { id }: routeParams = useParams();
  const [open, setOpen] = React.useState<boolean>(false);
  const [currentIndex, setIndex] = React.useState<number | null>(null);

  const history = useHistory();
  const confirm = useConfirm();

  const [deleteData, { isLoading: deleting }] = useDeleteEducationData(id);

  const handleDelete = (educationId: string) => {
    confirm({
      title: 'Are you sure you want to delete this?',
      confirmationButtonProps: { color: 'secondary', variant: 'contained' },
    })
      .then(() => {
        deleteData(educationId);
      })
      .catch(() => {});
  };

  const handleEdit = (index: number) => {
    setIndex(index);
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <Box m={4}>
        <>
          {data?.education?.length ? (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '24px',
                  marginBottom: '12px',
                }}
              >
                <Typography variant="h5">Education History</Typography>
                <Button
                  onClick={() => {
                    setOpen(true);
                    setIndex(null);
                  }}
                >
                  <PlusIcon />
                </Button>
              </div>

              {data?.education.map(
                ({ id: educationId, name, qualification, course, year_of_graduation , year_of_admission}, index) => (
                  <Grid container spacing={2} key={id}>
                    <Grid item xs={1}>
                      <Box className={classes.educationIcon}>
                        <EducationIcon />
                      </Box>
                    </Grid>
                    <Grid container item xs={11}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        flexDirection="row"
                        width={1}
                      >
                        <Box>
                          <Typography variant="h5">{name}</Typography>
                          <Typography>
                            {qualification}, {course}
                          </Typography>
                          <Typography> {year_of_admission} - {year_of_graduation} </Typography>
                        </Box>
                        <Box display="flex">
                          <div className={classes.editButton}>
                            <EditIcon onClick={() => handleEdit(index)} />
                          </div>
                          <div className={classes.editButton}>
                            <DeleteIcon onClick={() => handleDelete(educationId)} />
                          </div>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          width: '100%',
                          paddingRight: '24px',
                          marginBottom: '24px',
                          marginTop: '8px',
                        }}
                      >
                        <Divider />
                      </Box>
                    </Grid>
                  </Grid>
                )
              )}
            </>
          ) : (
            <EmptyState
              title="Empty"
              message="This user currently has no institution registered."
              buttonTitle="Click here to add"
              onClick={() => setOpen(true)}
            />
          )}
        </>

        <Box display="flex" justifyContent="flex-end" m={4} mr={1}>
          <Button
            onClick={() => history.goBack()}
            variant="outlined"
            style={{ marginRight: '16px' }}
          >
            Cancel
          </Button>
        </Box>
        {open && (
          <AddEducationModal
            open={open}
            closeModal={() => setOpen(false)}
            education={
              currentIndex !== null
                ? data?.education[currentIndex]
                : {
                    name: '',
                    course: '',
                    qualification: '',
                    year_of_resumption: '',
                    year_of_graduation: '',
                  }
            }
          />
        )}
      </Box>
    </div>
  );
}
