import * as React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

// components
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'components/common/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core';
import CustomizedRadios from 'components/common/Radio';
import { roles } from 'utils/constants';
import { localRoles } from 'utils/constants';
import { useAuth } from 'context/auth-context';

const FormDialog = ({
  open,
  handleClose,
  inviteUser,
  isLoading,
  error,
  isError,
  institutions,
}) => {
  const { register, handleSubmit, watch, setError, control, errors } = useForm({
    defaultValues: { role: 'LOCAL USER', institution: '' },
    reValidateMode: 'onBlur',
  });
  const { role: selectedRole, email, institution } = watch([
    'role',
    'email',
    'institution',
  ]);
  const { user } = useAuth();

  React.useEffect(() => {
    Object.keys(error?.errors || {}).forEach((key) => {
      // console.log(error?.errors?.[key][0]);
      setError(key, {
        type: 'manual',
        message: error?.errors?.[key][0],
      });
    });
  }, [error, setError]);

  React.useEffect(() => {}, [email]);

  const getErrorMessage = (name) => {
    let errorMessage = '';
    // eslint-disable-next-line no-prototype-builtins
    if (errors && errors.hasOwnProperty(name)) {
      errorMessage = errors[name]?.message;
    }
    return errorMessage;
  };

  const isFieldError = (name) => {
    return errors?.[name]?.message;
  };

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <Typography variant="h5">
          {selectedRole === 'LOCAL ADMIN' || selectedRole === 'GLOBAL ADMIN'
            ? 'Add Admin'
            : 'Add User'}
        </Typography>
        <Typography style={{ color: '#A6ABB8' }}>
          Fill the forms and invite
        </Typography>
      </DialogTitle>
      <DialogContent>
        {isError && (
          <Box m={2}>
            <Alert severity="error" variant="standard">
              Problem inviting user
            </Alert>
          </Box>
        )}
        <form
          onSubmit={handleSubmit((values) =>
            inviteUser({
              ...values,
              institution:
                values.role === 'GLOBAL ADMIN' || values.role === 'GLOBAL USER'
                  ? undefined
                  : values.institution,
              ministry:
                values.role === 'GLOBAL ADMIN' || values.role === 'GLOBAL USER'
                  ? undefined
                  : values.institution,
            })
          )}
        >
          <Grid container spacing={2}>
            <TextField
              type="hidden"
              name="password"
              size="small"
              placeholder="First name"
              value="padcasladfagsdas7321532rdvasvca"
              autoFocus
              className={classes.textfield}
              inputRef={register({ required: true })}
            />

            <Grid item md={6}>
              <InputLabel>First name</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                size="small"
                id="firstname"
                error={isFieldError('firstname')}
                name="firstname"
                placeholder="First name"
                autoFocus
                className={classes.textfield}
                inputRef={register({ required: true })}
              />
            </Grid>
            <Grid item md={6}>
              <InputLabel>Last name</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                size="small"
                id="lastname"
                error={isFieldError('lastname')}
                name="lastname"
                placeholder="Last name"
                autoFocus
                className={classes.textfield}
                inputRef={register({ required: true })}
              />
            </Grid>
            <Grid item md={6}>
              <InputLabel>Email address</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                type="email"
                size="small"
                error={isFieldError('email')}
                autoComplete="email"
                helperText="Incorrect entry."
                placeholder="Email address"
                autoFocus
                className={classes.textfield}
                inputRef={register({ required: true })}
              />
              <FormHelperText error="error">Error</FormHelperText>
            </Grid>
            <Grid item md={6}>
              <InputLabel>Phone number (optional)</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="phone"
                placeholder="Phone number"
                id="phone-error"
                autoFocus
                size="small"
                inputRef={register}
                className={classes.textfield}
                aria-describedby="phone-error-text"
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel style={{ marginBottom: '10px' }}>Role</InputLabel>
              <CustomizedRadios
                options={user.role === 'GLOBAL ADMIN' ? roles : localRoles}
                name="role"
                inputRef={register({ required: true })}
                control={control}
              />
            </Grid>
            {/* <Grid item md={6} /> */}
            <Grid
              item
              md={6}
              style={{
                display: `${
                  selectedRole === 'GLOBAL ADMIN' || selectedRole === 'GLOBAL USER'
                    ? 'none'
                    : 'grid'
                }`,
              }}
            >
              <InputLabel style={{ marginBottom: '10px' }}>Institution</InputLabel>
              <Select
                style={{ height: 48 }}
                placeHolder="Institution"
                options={institutions}
                name="institution"
                control={control}
                value={institution}
              />
            </Grid>
          </Grid>
          <DialogActions style={{ marginTop: 16, paddingLeft: 0 }}>
            <Button
              variant="contained"
              color="secondary"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? <CircularProgress size={24} /> : 'Send Invite'}
            </Button>
            <Button onClick={handleClose} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  inviteUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.string),
  }),
};

FormDialog.defaultProps = {
  error: {},
};

const useStyles = makeStyles({
  textfield: {
    '& .MuiInputBase-input:focus': {
      outline: '2px solid rgba(201, 212, 219, 0.8)',
    },
  },
});

export default FormDialog;
