/* eslint-disable react/no-array-index-key */
import React from 'react';
import DefaultTabs from '@material-ui/core/Tabs';
import DefaultTab from '@material-ui/core/Tab';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

import DefaultTabPanel from './TabPanel';

interface StyledTabProps {
  label: string;
}

const Tabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: theme.palette.secondary.main,
    },
  })
)(DefaultTabs);

const Tab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"'].join(','),
      '&:hover': {
        color: theme.palette.secondary,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.secondary,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: theme.palette.secondary,
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <DefaultTab disableRipple {...props} />);

const TabContext = React.createContext({});

interface TabProviderProps {
  children?: any;
}

interface DefaultTabPanelProps {
  children?: React.ReactNode;
  className?: string;
}

const TabProvider = (props: TabProviderProps) => {
  const { children } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  let tabs = <div />;

  const tabPanels: Array<React.ReactElement> = [];

  React.Children.map(children, (child) => {
    if (child.type.tabRole === 'tabPanel') {
      tabPanels.push(child);
    } else {
      tabs = child;
    }
  });

  const renderTabPanels = tabPanels.map((panel: React.ReactElement, index: number) =>
    React.cloneElement(panel, {
      index,
      key: index + 1,
    })
  );

  const renderTab = React.cloneElement(tabs, {
    value,
    onChange: handleChange,
  });

  return (
    <TabContext.Provider
      value={{
        value,
        handleChange,
      }}
    >
      {renderTab}
      {renderTabPanels}
    </TabContext.Provider>
  );
};

const useTab = () => React.useContext(TabContext);

const TabPanel = (props: DefaultTabPanelProps) => {
  return <DefaultTabPanel {...props} useTab={useTab} />;
};

TabPanel.tabRole = 'tabPanel';

export { Tabs, Tab, TabPanel, TabProvider };
